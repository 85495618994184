/* eslint-disable @typescript-eslint/no-use-before-define */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, AppDispatch } from '../store';
import { IWeatherData } from 'vision9-solar-shared';
import Axios from 'axios';
import { IWeatherState } from '../state/IWeatherState';
import HttpStatusCodes from 'http-status-codes';
import { config } from '../../config';

const initialState: IWeatherState = {
    fetching: false,
    initialized: false,
    data: [],
};

const getWeatherData = (): AppThunk => async (dispatch: AppDispatch): Promise<void> => {
    dispatch(getWeatherDataStart());

    const response = await Axios({
        baseURL: config.apiUrl,
        url: '/weather-data',
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
    });

    if (response.status !== HttpStatusCodes.OK) {
        dispatch(
            setError(
                response.data.message || 'An unkown error occured retrieving the Weather data.',
            ),
        );
    } else {
        dispatch(getWeatherDataEnd(response.data));
    }
};

const slice = createSlice({
    name: 'WEATHER-DATA',
    initialState,
    reducers: {
        getWeatherDataStart: (state: IWeatherState): IWeatherState => {
            return {
                ...state,
                fetching: true,
                initialized: false,
                error: undefined,
                data: [],
            };
        },
        setError: (state: IWeatherState, action: PayloadAction<string>): IWeatherState => {
            return {
                ...state,
                fetching: false,
                initialized: false,
                error: action.payload,
            };
        },
        getWeatherDataEnd: (
            state: IWeatherState,
            action: PayloadAction<IWeatherData[]>,
        ): IWeatherState => {
            return {
                ...state,
                fetching: false,
                initialized: true,
                error: undefined,
                data: action.payload,
            };
        },
        postWeatherData: (
            state: IWeatherState,
            action: PayloadAction<IWeatherData>,
        ): IWeatherState => {
            return {
                ...state,
                data: [
                    ...state.data.filter(d => d.locationId !== action.payload.locationId),
                    action.payload,
                ],
            };
        },
    },
});

export { getWeatherData };

export const { setError, getWeatherDataStart, getWeatherDataEnd, postWeatherData } = slice.actions;

const weatherDataReducer = slice.reducer;
export { weatherDataReducer };
