import React, { Fragment, useState } from 'react';
import { IonChip, IonLabel, IonIcon, IonToast } from '@ionic/react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../store';
import { warning } from 'ionicons/icons';
import { AppDispatch } from '../store/store';
import { clearNotification, dismissNotification } from '../store/reducers/notificationReducer';
import { setAlert } from '../store/reducers/alertReducer';

export const Notifications: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const notifications = useSelector((state: RootState) => {
        return state.notification.notifications;
    });
    const [pos, setPos] = useState(-1);
    const [newNotification, setNewNotification] = useState(false);
    const [isRemoved, setIsRemoved] = useState(false);

    const color = useSelector((state: RootState) => {
        return state.notification.notifications.reduce((previous, notification) => {
            return notification.event === 'ALERT' || notification.event === 'UPDATE'
                ? 'danger'
                : 'warning';
        }, 'warning');
    });

    // useEffect(() => {
    //     if (notifications.length > pos && notifications.length > 0) {
    //         if (pos === -1) {
    //             setPos(0);
    //             setNewNotification(true);
    //             return;
    //         }

    //         let p = Promise.resolve();
    //         if (newNotification) {
    //             p = new Promise(resolve => setTimeout(() => resolve, 5000));
    //         }
    //         p.then(() => {
    //             setPos(notifications.length - 1);
    //             setNewNotification(true);
    //         });
    //     }
    // }, [notifications]);

    return (
        <Fragment>
            <IonChip
                outline={true}
                color={color}
                hidden={notifications.length === 0}
                onClick={(): void => {
                    setPos(0);
                    setNewNotification(true);
                }}
            >
                <IonLabel>{notifications.length}</IonLabel>
                <IonIcon icon={warning} size="small" />
            </IonChip>
            <IonToast
                color={
                    !notifications || pos >= notifications.length || !notifications[pos]
                        ? ''
                        : ['WARNING', 'RESOLVED'].includes(notifications[pos].event)
                        ? 'warning'
                        : 'danger'
                }
                isOpen={newNotification}
                onDidDismiss={(): void => {
                    setNewNotification(false);
                    let setNew = isRemoved;
                    if (pos + 1 < notifications.length) {
                        setPos(pos + (isRemoved ? 0 : 1));
                        setNew = true;
                    }
                    if (setNew) {
                        setNewNotification(true);
                    }
                    if (isRemoved) {
                        setIsRemoved(false);
                    }
                }}
                duration={5000}
                header={
                    !notifications || pos >= notifications.length || !notifications[pos]
                        ? ''
                        : notifications[pos].subject
                }
                message={
                    !notifications || pos >= notifications.length || !notifications[pos]
                        ? ''
                        : notifications[pos].message
                }
                buttons={[
                    {
                        text: 'Dismiss',
                        side: 'end',
                        handler: (): void => {
                            const { monitorId, type, event } = notifications[pos];
                            if (event === 'RESOLVED') {
                                setIsRemoved(true);
                                dispatch(dismissNotification({ monitorId, type }));
                            }
                        },
                    },
                    {
                        text: 'Clear',
                        side: 'end',
                        handler: (): void => {
                            const { monitorId, type } = notifications[pos];
                            setIsRemoved(true);
                            dispatch(clearNotification(monitorId, type)).catch(e => {
                                setAlert({
                                    header: 'Error clearing notification',
                                    message: `An error occured clearing the notification: ${e.message ||
                                        e}`,
                                });
                            });
                        },
                    },
                ]}
            />
        </Fragment>
    );
};
