/* eslint-disable @typescript-eslint/no-use-before-define */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { INotificationState } from '../state/INotificationState';
import { INotification } from 'vision9-solar-shared';
import HttpStatusCodes from 'http-status-codes';
import { config } from '../../config';
import Axios from 'axios';
import { AppThunk, AppDispatch } from '../store';

const initialState: INotificationState = {
    fetching: false,
    initialized: false,
    notifications: [],
};

const getNotifications = (): AppThunk => async (dispatch: AppDispatch): Promise<void> => {
    dispatch(getNotificationsStart());

    try {
        const response = await Axios({
            baseURL: config.apiUrl,
            url: '/notification',
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (response.status !== HttpStatusCodes.OK) {
            throw new Error(
                response.data.message ||
                    'An unkown error occured retrieving the list of Locations.',
            );
        }
        dispatch(getNotificationsEnd(response.data));
    } catch (e) {
        dispatch(setError(e.message));
    }
};

const clearNotification = (monitorId: number, type: string): AppThunk => async (
    dispatch: AppDispatch,
): Promise<void> => {
    dispatch(clearNotificationStart());

    try {
        const response = await Axios({
            baseURL: config.apiUrl,
            url: '/notification/clear',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            data: {
                monitorId,
                type,
            },
        });

        if (response.status !== HttpStatusCodes.OK) {
            throw new Error(
                response.data.message || 'An unkown error occured clearing the notification.',
            );
        }
        dispatch(clearNotificationEnd({ monitorId, type }));
    } catch (e) {
        dispatch(setError(e.message));
    }
};

const slice = createSlice({
    name: 'NOTIFICATION',
    initialState,
    reducers: {
        postNotification: (
            state: INotificationState,
            action: PayloadAction<INotification>,
        ): INotificationState => {
            if (action.payload.event === 'RESOLVED') {
                return {
                    ...state,
                    notifications: [
                        ...state.notifications.filter(
                            n =>
                                n.monitorId !== action.payload.monitorId ||
                                n.type !== action.payload.type,
                        ),
                    ],
                };
            }

            return {
                ...state,
                notifications: [
                    ...state.notifications.filter(
                        n =>
                            n.monitorId !== action.payload.monitorId ||
                            n.type !== action.payload.type,
                    ),
                    action.payload,
                ],
            };
        },
        dismissNotification: (
            state: INotificationState,
            action: PayloadAction<{ monitorId: number; type: string }>,
        ): INotificationState => {
            return {
                ...state,
                notifications: [
                    ...state.notifications.filter(
                        n =>
                            n.monitorId !== action.payload.monitorId ||
                            n.type !== action.payload.type,
                    ),
                ],
            };
        },
        getNotificationsStart: (state: INotificationState): INotificationState => {
            return {
                ...state,
                fetching: true,
                initialized: false,
                error: undefined,
                notifications: [],
            };
        },
        setError: (
            state: INotificationState,
            action: PayloadAction<string>,
        ): INotificationState => {
            return {
                ...state,
                fetching: false,
                initialized: false,
                error: action.payload,
            };
        },
        getNotificationsEnd: (
            state: INotificationState,
            action: PayloadAction<INotification[]>,
        ): INotificationState => {
            return {
                ...state,
                fetching: false,
                initialized: true,
                error: undefined,
                notifications: action.payload,
            };
        },
        clearNotificationStart: (state: INotificationState): INotificationState => {
            return {
                ...state,
                fetching: true,
                error: undefined,
            };
        },
        clearNotificationEnd: (
            state: INotificationState,
            action: PayloadAction<{ monitorId: number; type: string }>,
        ): INotificationState => {
            return {
                ...state,
                fetching: false,
                error: undefined,
                notifications: [
                    ...state.notifications.filter(
                        n =>
                            n.monitorId !== action.payload.monitorId ||
                            n.type !== action.payload.type,
                    ),
                ],
            };
        },
    },
});

export { getNotifications, clearNotification };

export const {
    postNotification,
    dismissNotification,
    getNotificationsStart,
    getNotificationsEnd,
    setError,
    clearNotificationStart,
    clearNotificationEnd,
} = slice.actions;

const notificationReducer = slice.reducer;
export { notificationReducer };
