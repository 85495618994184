import { IConfig } from './IConfig';
import { IConfigCollection } from './IConfigCollection';
import { SAVED_STATE } from '../models/Constants';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const configs: IConfigCollection = require('./config.json');

const env = localStorage.getItem(SAVED_STATE.ENVIRONMENT) || configs.default;

const config: IConfig = configs.configurations[env];

export { config };
