import React, { FormEvent, useState } from 'react';
import {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonInput,
    IonList,
    IonItem,
    IonLabel,
    IonSlides,
    IonSlide,
    IonButton,
    IonGrid,
    IonRow,
    IonCol,
    IonSelect,
    IonSelectOption,
} from '@ionic/react';
import { withRouter, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../store';

import './Register.scss';
import { InputChangeEventDetail } from '@ionic/core';
import { IUser, AuthRoles } from 'vision9-solar-shared';
import { register } from '../../store/reducers/authReducer';
import { AppDispatch } from '../../store/store';
import { LogoutButton } from '../../components/LogoutButton';
import { ButtonSpacer } from '../../components/ButtonSpacer';

const LAST_PAGE = 3;

const RegisterPage: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const history = useHistory();

    const user = useSelector((state: RootState) => state.auth.user);
    const companies = useSelector((state: RootState) => state.company.companies);
    const savedPath = useSelector((state: RootState) => state.clientSettings.savedPath);

    const [formState, setFormState] = useState({ country: 'CA' } as { [key: string]: string });
    const [page, setPage] = useState(0);
    let slider: HTMLIonSlidesElement | null;

    const submitRegistration = (event: FormEvent<HTMLFormElement>): void => {
        event.preventDefault();

        const email = user && user.email ? user.email : '';

        const registration: IUser = {
            userId: 0,
            email,
            fullName: formState.fullName,
            role: AuthRoles.Read,
            companies: [
                {
                    companyId: 0,
                    companyName: formState.companyName,
                    address1: formState.address1,
                    address2: formState.address2,
                    city: formState.city,
                    province: formState.province,
                    postal: formState.postal,
                    country: formState.country,
                    phone: formState.phone,
                    contactEmail: email,
                    monitors: [],
                    users: [],
                },
            ],
            userCompanies: [],
        };

        dispatch(register(registration))
            .then((): void => {
                history.push(savedPath);
            })
            .catch(reason => {
                alert(reason);
            });
    };

    const fieldOnChange = (event: CustomEvent<InputChangeEventDetail>): void => {
        console.log(event.detail.value);
        if (event.detail.value && event.currentTarget) {
            setFormState({
                ...formState,
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                [(event.currentTarget as any).name]: event.detail.value,
            });
        }
    };

    const previousPage = (): void => {
        if (slider && page > 0) {
            setPage(page - 1);
            slider.slidePrev();
        }
    };

    const nextPage = (): void => {
        if (slider && page < LAST_PAGE) {
            setPage(page + 1);
            slider.slideNext();
        }
    };

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <ButtonSpacer slot="start" />
                    <IonTitle>Register</IonTitle>
                    <LogoutButton />
                </IonToolbar>
            </IonHeader>
            <IonContent scrollX={false} scrollY={false}>
                <IonSlides
                    className="register-content"
                    ref={(ref): void => {
                        slider = ref;
                    }}
                >
                    <IonSlide className="register-content-main">
                        <div className="register-content-main-text">
                            Welcome to the Vision9 Solar Monitoring Solution.
                            <br />
                            <br />
                            Please continue with the registration process in order to gain access
                            to:
                            <ul>
                                <li>Dashboards for companies that you have been invited to</li>
                                <li>Create new companies of your own</li>
                                <li>
                                    Register your monitors to begin reporting of your own solar
                                    sites!
                                </li>
                            </ul>
                            <br />
                            <br />
                            <div className="button-bar">
                                <IonButton onClick={(): void => nextPage()}>Continue</IonButton>
                            </div>
                        </div>
                    </IonSlide>
                    <IonSlide className="register-content-main">
                        <div className="register-content-main-body">
                            <form
                                onSubmit={(e): void => {
                                    e.preventDefault();
                                    nextPage();
                                }}
                            >
                                <IonList>
                                    <IonItem>
                                        <IonLabel position="stacked" className="header">
                                            Account Information
                                        </IonLabel>
                                        <IonInput
                                            className="read-only-input"
                                            readonly={true}
                                            type="text"
                                        >
                                            {user ? user.email || '' : ''}
                                        </IonInput>
                                        <IonInput
                                            type="text"
                                            name="fullName"
                                            placeholder="Full Name"
                                            required={true}
                                            spellCheck={false}
                                            onIonChange={fieldOnChange}
                                        />
                                    </IonItem>
                                </IonList>
                                <br />
                                <div className="button-bar">
                                    <IonButton onClick={(): void => previousPage()}>Back</IonButton>
                                    <IonButton type="submit">Next</IonButton>
                                </div>
                            </form>
                        </div>
                    </IonSlide>
                    <IonSlide className="register-content-main">
                        <div className="register-content-main-body">
                            <form
                                onSubmit={(e): void => {
                                    e.preventDefault();
                                    nextPage();
                                }}
                            >
                                {!user || Object.keys(user.companies).length === 0 ? (
                                    <IonList>
                                        <IonItem>
                                            <IonLabel position="stacked" className="header">
                                                Company Information
                                            </IonLabel>
                                            <IonInput
                                                type="text"
                                                name="companyName"
                                                placeholder="Company Name"
                                                required={true}
                                                spellCheck={false}
                                                onIonChange={fieldOnChange}
                                            />
                                            <IonInput
                                                type="text"
                                                name="address1"
                                                placeholder="Address (Line 1)"
                                                required={true}
                                                spellCheck={false}
                                                onIonChange={fieldOnChange}
                                            />
                                            <IonInput
                                                type="text"
                                                name="address2"
                                                placeholder="Address (Line 2)"
                                                spellCheck={false}
                                                onIonChange={fieldOnChange}
                                            />
                                            <IonInput
                                                type="text"
                                                name="city"
                                                placeholder="City"
                                                required={true}
                                                spellCheck={false}
                                                onIonChange={fieldOnChange}
                                            />
                                            <IonSelect
                                                interface="popover"
                                                name="country"
                                                placeholder="Country"
                                                onIonChange={fieldOnChange}
                                            >
                                                <IonSelectOption value="CA" defaultChecked={true}>
                                                    Canada
                                                </IonSelectOption>
                                                <IonSelectOption value="US">
                                                    United States
                                                </IonSelectOption>
                                            </IonSelect>
                                            <IonInput
                                                type="text"
                                                name="province"
                                                placeholder="Province"
                                                required={true}
                                                spellCheck={false}
                                                onIonChange={fieldOnChange}
                                            />
                                            <IonInput
                                                type="text"
                                                name="postal"
                                                placeholder="Postal/Zip Code"
                                                required={true}
                                                spellCheck={false}
                                                onIonChange={fieldOnChange}
                                            />
                                            <IonInput
                                                type="text"
                                                name="phone"
                                                placeholder="Phone #"
                                                required={true}
                                                pattern="^([\+][0-9]{1,3}([ \.\-])?)?([\(]{1}[0-9]{3}[\)])?([0-9A-Z \.\-]{1,32})((x|ext|extension)?[0-9]{1,4}?)$"
                                                spellCheck={false}
                                                onIonChange={fieldOnChange}
                                            />
                                        </IonItem>
                                    </IonList>
                                ) : (
                                    <IonList>
                                        <IonItem>
                                            <IonLabel position="stacked" className="header">
                                                Company Invitations
                                            </IonLabel>
                                            {companies.map(company => {
                                                return (
                                                    <IonRow key={company.companyId}>
                                                        <IonCol>{company.companyName}</IonCol>
                                                    </IonRow>
                                                );
                                            })}
                                        </IonItem>
                                    </IonList>
                                )}
                                <br />
                                <div className="button-bar">
                                    <IonButton onClick={(): void => previousPage()}>Back</IonButton>
                                    <IonButton type="submit">Next</IonButton>
                                </div>
                            </form>
                        </div>
                    </IonSlide>
                    <IonSlide className="register-content-main">
                        <div className="register-content-main-body">
                            <form onSubmit={submitRegistration}>
                                <IonList>
                                    <IonItem>
                                        <IonLabel position="stacked" className="header">
                                            Summary
                                        </IonLabel>
                                        <IonLabel position="stacked" className="subheader">
                                            Account Information
                                        </IonLabel>
                                        <IonItem>
                                            <IonGrid>
                                                <IonRow>
                                                    <IonCol>E-Mail:</IonCol>
                                                    <IonCol>{user ? user.email : ''}</IonCol>
                                                </IonRow>
                                                <IonRow>
                                                    <IonCol>Full Name:</IonCol>
                                                    <IonCol>{formState.fullName}</IonCol>
                                                </IonRow>
                                            </IonGrid>
                                        </IonItem>
                                        <IonLabel position="stacked" className="subheader">
                                            {companies.length === 0
                                                ? 'Company Information'
                                                : 'Company Invitations'}
                                        </IonLabel>
                                        {companies.length === 0 ? (
                                            <IonItem>
                                                <IonGrid>
                                                    <IonRow>
                                                        <IonCol>Company Name:</IonCol>
                                                        <IonCol>{formState.companyName}</IonCol>
                                                    </IonRow>
                                                    <IonRow>
                                                        <IonCol>Address:</IonCol>
                                                        <IonCol>
                                                            {formState.address1}
                                                            <br />
                                                            {formState.address2
                                                                ? `${formState.address2}\n`
                                                                : ''}
                                                            {formState.city}, {formState.province}
                                                            <br />
                                                            {formState.country}
                                                            <br />
                                                            {formState.postal}
                                                        </IonCol>
                                                    </IonRow>
                                                    <IonRow>
                                                        <IonCol>Phone #:</IonCol>
                                                        <IonCol>{formState.phone}</IonCol>
                                                    </IonRow>
                                                </IonGrid>
                                            </IonItem>
                                        ) : (
                                            <IonItem>
                                                <IonGrid>
                                                    {companies.map(company => {
                                                        return (
                                                            <IonRow key={company.companyId}>
                                                                <IonCol>
                                                                    {company.companyName}
                                                                </IonCol>
                                                            </IonRow>
                                                        );
                                                    })}
                                                </IonGrid>
                                            </IonItem>
                                        )}
                                    </IonItem>
                                </IonList>
                                <br />
                                <div className="button-bar">
                                    <IonButton onClick={(): void => previousPage()}>Back</IonButton>
                                    <IonButton type="submit">Complete Registration</IonButton>
                                </div>
                            </form>
                        </div>
                    </IonSlide>
                </IonSlides>
            </IonContent>
        </IonPage>
    );
};

const registerPage = withRouter(RegisterPage);

export { registerPage as RegisterPage };
