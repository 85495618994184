/* eslint-disable no-constant-condition */
/* eslint-disable @typescript-eslint/no-use-before-define */
import {
    IonButtons,
    IonContent,
    IonHeader,
    IonMenuButton,
    IonPage,
    IonTitle,
    IonToolbar,
    IonRouterLink,
    IonIcon,
    IonLoading,
    IonSpinner,
    useIonViewDidEnter,
} from '@ionic/react';
import React, { useState, useEffect } from 'react';
import './HomeList.scss';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../store';
import { withRouter, useHistory } from 'react-router-dom';
import { LogoutButton } from '../../components/LogoutButton';
import { arrowForward, warning } from 'ionicons/icons';
import { List, AutoSizer, ListRowProps } from 'react-virtualized';
import { Sparklines, SparklinesLine, SparklinesReferenceLine } from 'react-sparklines';
import WeatherIcon from 'react-icons-weather';
import { AppDispatch } from '../../store/store';
import { storeSavedPath } from '../../store/reducers/clientSettingsReducer';

const UNIT_DIVIDER = 1000;
const TEMPERATURE_UNITS = 'C';

const HomeListPage: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const history = useHistory();

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 15000);
    }, []);

    useIonViewDidEnter(() => {
        dispatch(storeSavedPath('/home-list'));
    });

    const companies = useSelector((state: RootState) => state.company.companies);
    const weatherData = useSelector((state: RootState) => state.weatherData.data);
    const notifications = useSelector((state: RootState) => state.notification.notifications);
    const initialized = useSelector(
        (state: RootState) =>
            state.monitorData.initialized &&
            state.company.initialized &&
            state.location.initialized &&
            state.monitor.initialized,
    );

    const getNotificationLevel = (monitorId: number): string => {
        const notices = notifications.filter(n => n.monitorId === monitorId);
        return notices.reduce((p, c) => {
            if (p === 'ALERT') {
                return p;
            } else if (c.event === 'WARNING') {
                return 'WARNING';
            } else {
                return 'ALERT';
            }
        }, '');
    };

    const data = useSelector((state: RootState) => {
        return state.company.companies
            .slice()
            .sort((a, b) => a.companyName.localeCompare(b.companyName))
            .map(c => {
                const item = {
                    monitorId: c.companyId,
                    ...state.monitorData.data
                        .filter(d => {
                            const m = state.monitor.monitors.find(m => m.monitorId === d.monitorId);
                            return m ? m.companyId === c.companyId : false;
                        })
                        .reduce(
                            (previous, data) => {
                                const predictions = state.monitorData.predictions[data.monitorId];

                                return {
                                    label: previous.label,
                                    dateRecorded:
                                        (previous.dateRecorded.getFullYear &&
                                            previous.dateRecorded.getFullYear() === 1970) ||
                                        previous.dateRecorded.valueOf() <
                                            data.dateRecorded.valueOf()
                                            ? data.dateRecorded
                                            : previous.dateRecorded,
                                    current: previous.current + data.current,
                                    average: previous.average + data.average,
                                    today: previous.today + data.today,
                                    monthToDate: previous.monthToDate + data.monthToDate,
                                    projected: previous.projected + data.projected,
                                    voltage: previous.voltage + data.voltage,
                                    history:
                                        previous.history.length === 0
                                            ? state.monitorData.history[data.monitorId].slice()
                                            : previous.history
                                                  .slice()
                                                  .reverse()
                                                  .map((h, i) => {
                                                      return (
                                                          h +
                                                          (state.monitorData.history[
                                                              data.monitorId
                                                          ][i] || 0)
                                                      );
                                                  })
                                                  .reverse(),
                                    prediction:
                                        predictions && predictions.length > 0
                                            ? (previous.prediction !== undefined
                                                  ? previous.prediction
                                                  : 0) +
                                              predictions.reduce((sum, value) => sum + value, 0) /
                                                  predictions.length
                                            : previous.prediction,
                                };
                            },
                            {
                                label: c.companyName,
                                dateRecorded: new Date(1970, 1, 1),
                                current: 0,
                                average: 0,
                                today: 0,
                                monthToDate: 0,
                                projected: 0,
                                voltage: 0,
                                history: [] as number[],
                                prediction: undefined as number | undefined,
                            },
                        ),
                    ...state.monitor.monitors
                        .filter(m => m.companyId === c.companyId)
                        .reduce(
                            (previous, monitor, index) => ({
                                red: previous.red + monitor.red,
                                green: previous.green + monitor.green,
                                max: previous.max + monitor.max,
                                locationId:
                                    previous.locationId < 0
                                        ? monitor.locationId
                                        : previous.locationId,
                                count: index + 1,
                                notification:
                                    previous.notification === 'ALERT'
                                        ? 'ALERT'
                                        : getNotificationLevel(monitor.monitorId),
                            }),
                            {
                                red: 0,
                                green: 0,
                                max: 0,
                                locationId: -1,
                                count: 0,
                                notification: '',
                            },
                        ),
                };
                return item;
            })
            .filter(item => item.count > 0);
    });

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonMenuButton />
                    </IonButtons>
                    <IonTitle>
                        {((): string => {
                            const total = data.reduce((previous, value) => {
                                return previous + (value.current || 0);
                            }, 0);
                            return `${
                                total >= 100000
                                    ? Math.round(total / 1000).toLocaleString('en-US')
                                    : total >= 10000
                                    ? (Math.round(total / 100) / 10).toFixed(1)
                                    : (Math.round(total / 10) / 100).toFixed(2)
                            } kWh`;
                        })()}
                    </IonTitle>
                    <LogoutButton />
                </IonToolbar>
            </IonHeader>
            <IonContent scrollX={false} scrollY={false}>
                {data.length === 0 ? (
                    <div className="home-list-content">
                        <div className="home-list-content-main">
                            {initialized ? (
                                <div className="home-list-content-main-text">
                                    Welcome to the Vision9 Solar Monitoring Solution. You currently
                                    have no active monitors that have recorded data.
                                    <br />
                                    <br />
                                    You can add companies and monitors from the &nbsp;
                                    <IonRouterLink routerLink="/profile">Profile</IonRouterLink>
                                    &nbsp; page.
                                    <br />
                                    <br />
                                    <IonRouterLink routerLink="/profile">
                                        Go to the profile page &nbsp;
                                        <IonIcon icon={arrowForward} />
                                    </IonRouterLink>
                                </div>
                            ) : (
                                <div className="home-list-content-main-text">
                                    <IonLoading isOpen={true} message="Loading ..." />
                                </div>
                            )}
                        </div>
                    </div>
                ) : (
                    <React.Fragment>
                        <AutoSizer>
                            {({ width, height }): JSX.Element => (
                                <List
                                    height={height}
                                    width={width}
                                    rowHeight={60}
                                    autoContainerWidth={true}
                                    rowCount={data.length}
                                    noRowsRenderer={(): JSX.Element => <IonLoading isOpen={true} />}
                                    rowRenderer={(props: ListRowProps): JSX.Element => {
                                        const item = data[props.index];
                                        const company = companies.find(
                                            c => c.companyId === item.monitorId,
                                        );
                                        const hasData = item.dateRecorded.getFullYear() > 1970;
                                        const max = Math.max(item.max, ...item.history);
                                        const weather = weatherData.find(
                                            w => item.locationId === w.locationId,
                                        );
                                        const divider = item.history
                                            ? Math.max(1, Math.floor(item.history.length / 5))
                                            : 1;
                                        const stateColor = item.prediction
                                            ? item.current > item.prediction * 0.8
                                                ? '#0ec254'
                                                : item.current > item.prediction * 0.6
                                                ? '#e0b500'
                                                : '#d33939'
                                            : '#989aa2';

                                        return (
                                            <div
                                                key={props.key}
                                                className="home-list-row"
                                                style={{
                                                    ...props.style,
                                                }}
                                                onClick={(): void => {
                                                    history.push({
                                                        pathname: `/sites-list/${item.monitorId}`,
                                                    });
                                                }}
                                            >
                                                <div className="home-list-row-spark">
                                                    <Sparklines
                                                        data={
                                                            item.history && item.history.length > 0
                                                                ? item.history.filter(
                                                                      (value, index) =>
                                                                          index % divider === 0,
                                                                  )
                                                                : [0]
                                                        }
                                                        limit={
                                                            item.history && item.history.length > 0
                                                                ? Math.ceil(
                                                                      item.history.length / divider,
                                                                  )
                                                                : 1
                                                        }
                                                        width={100}
                                                        height={50}
                                                        max={max}
                                                        min={0}
                                                        svgWidth={100}
                                                        svgHeight={50}
                                                        margin={5}
                                                    >
                                                        <SparklinesReferenceLine
                                                            type="custom"
                                                            key="green"
                                                            value={
                                                                50 -
                                                                Math.round(
                                                                    (item.green / max) * 50 * 100,
                                                                ) /
                                                                    100
                                                            }
                                                            style={{ stroke: 'green' }}
                                                        />
                                                        <SparklinesReferenceLine
                                                            type="custom"
                                                            key="red"
                                                            value={
                                                                50 -
                                                                Math.round(
                                                                    (item.red / max) * 50 * 100,
                                                                ) /
                                                                    100
                                                            }
                                                            style={{ stroke: 'red' }}
                                                        />
                                                        <SparklinesLine color="white" />
                                                    </Sparklines>
                                                </div>
                                                <div className="home-list-row-col1">
                                                    <div className="home-list-row-col1-row1">
                                                        {company ? company.companyName : ''}
                                                    </div>
                                                    <div
                                                        className="home-list-row-col1-row2"
                                                        hidden={!hasData}
                                                    >
                                                        {`${item.dateRecorded.toLocaleDateString()}, ${item.dateRecorded.toLocaleTimeString()}`}
                                                    </div>
                                                    <div
                                                        className="home-list-row-col1-row3"
                                                        hidden={!hasData}
                                                    >
                                                        TDY:{' '}
                                                        {item.today
                                                            ? Math.round(
                                                                  item.today / UNIT_DIVIDER,
                                                              ).toLocaleString()
                                                            : '-'}
                                                        , AVG:{' '}
                                                        {item.average
                                                            ? Math.round(
                                                                  item.average / UNIT_DIVIDER,
                                                              ).toLocaleString()
                                                            : '-'}
                                                        , MTD:{' '}
                                                        {item.monthToDate
                                                            ? Math.round(
                                                                  item.monthToDate / UNIT_DIVIDER,
                                                              ).toLocaleString()
                                                            : '-'}
                                                    </div>
                                                </div>
                                                <div className="home-list-row-col4">
                                                    <div>
                                                        {weather && weather.icon ? (
                                                            <WeatherIcon
                                                                name="darksky"
                                                                iconId={
                                                                    weather && weather.icon
                                                                        ? weather.icon
                                                                        : ''
                                                                }
                                                            />
                                                        ) : (
                                                            ''
                                                        )}
                                                    </div>
                                                    <div>
                                                        {weather
                                                            ? `${Math.round(
                                                                  weather.temperature,
                                                              )}\xB0${TEMPERATURE_UNITS}`
                                                            : ''}
                                                    </div>
                                                </div>
                                                <div
                                                    className="home-list-row-value"
                                                    style={{
                                                        color: stateColor,
                                                    }}
                                                >
                                                    {!hasData ? (
                                                        loading ? (
                                                            <IonSpinner
                                                                className="home-list-row-spark-spinner"
                                                                key={props.key}
                                                                style={{
                                                                    ...props.style,
                                                                    height: '24px',
                                                                }}
                                                            >
                                                                {item.label}
                                                            </IonSpinner>
                                                        ) : (
                                                            <IonIcon icon={warning} />
                                                        )
                                                    ) : item.current >= 100000 ? (
                                                        Math.round(
                                                            item.current / 1000,
                                                        ).toLocaleString('en-US')
                                                    ) : item.current >= 10000 ? (
                                                        (
                                                            Math.round(item.current / 100) / 10
                                                        ).toFixed(1)
                                                    ) : (
                                                        (
                                                            Math.round(item.current / 10) / 100
                                                        ).toFixed(2)
                                                    )}
                                                </div>
                                            </div>
                                        );
                                    }}
                                />
                            )}
                        </AutoSizer>
                    </React.Fragment>
                )}
            </IonContent>
        </IonPage>
    );
};

const homeListPage = withRouter(HomeListPage);

export { homeListPage as HomeListPage };
