/* eslint-disable @typescript-eslint/no-use-before-define */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IAuthState } from '../state/IAuthState';
import { AppThunk, AppDispatch } from '../store';
import { SAVED_STATE } from '../../models/Constants';
import { IAuthUser, IUser, AuthRoles } from 'vision9-solar-shared';
import Axios from 'axios';
import { config } from '../../config';
import HttpStatusCodes from 'http-status-codes';

const getInitialState = (): IAuthState => {
    const token = localStorage.getItem(SAVED_STATE.TOKEN);

    if (token) {
        const parts = token.split('.');
        const user: IAuthUser = {
            ...JSON.parse(Buffer.from(parts[1], 'base64').toString('utf8')),
            token,
        };

        return {
            authenticated: true,
            authenticating: false,
            refreshing: false,
            reinitialize: false,
            lastUser: false,
            user,
        };
    } else {
        return {
            authenticated: false,
            authenticating: false,
            reinitialize: false,
            lastUser: false,
            refreshing: false,
        };
    }
};

const initialState: IAuthState = getInitialState();

const processToken = (token: string, refreshToken: string): AppThunk => async (
    dispatch: AppDispatch,
): Promise<void> => {
    return new Promise(resolve => {
        localStorage.setItem(SAVED_STATE.TOKEN, `${token}|${refreshToken}`);
        const parts = token.split('.');
        const user: IAuthUser = {
            ...JSON.parse(Buffer.from(parts[1], 'base64').toString('utf8')),
            token,
            refreshToken,
        };
        dispatch(setToken(user));
        resolve();
    });
};

const logout = (): AppThunk => async (dispatch: AppDispatch): Promise<void> => {
    return new Promise(resolve => {
        localStorage.removeItem(SAVED_STATE.TOKEN);
        dispatch(completeLogout());
        resolve();
    });
};

const loginWithFirebaseToken = (token: string): AppThunk => async (
    dispatch: AppDispatch,
): Promise<void> => {
    dispatch(firebaseLoginStart());

    try {
        const response = await Axios({
            baseURL: config.apiUrl,
            url: '/auth/firebase',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            data: {
                username: 'n/a',
                password: token,
            },
            timeout: config.requestTimeout,
        });

        if (response.status !== HttpStatusCodes.OK) {
            throw new Error(
                response.data.message || `An unkown error occured authenticating firebase token.`,
            );
        }

        if (response.data.user && response.data.user.token) {
            dispatch(processToken(response.data.user.token, response.data.user.refreshToken));
        } else {
            throw new Error('Unable to login with firebase token.');
        }
    } catch (e) {
        dispatch(setError(e.message));
    }
};

const register = (user: IUser): AppThunk => async (dispatch: AppDispatch): Promise<void> => {
    dispatch(registrationStart());

    try {
        const response = await Axios({
            baseURL: config.apiUrl,
            url: '/registration',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            data: user,
            timeout: config.requestTimeout,
        });

        if (response.status !== HttpStatusCodes.OK) {
            throw new Error(
                response.data.message ||
                    'An unkown error occured retrieving the list of companies.',
            );
        }

        const token = response.data as { token: string };
        if (token.token) {
            const tokenParts = token.token.split('|');
            dispatch(processToken(tokenParts[0], tokenParts[1]));
        } else {
            dispatch(setError('Invalid token returned after registration.'));
        }
    } catch (e) {
        const errorMsg =
            e.response && e.response.data && e.response.data.message
                ? e.response.data.message
                : e.message;
        dispatch(setError(errorMsg));
        throw errorMsg;
    }
};

const addUserCompany = (companyId: number): AppThunk => async (
    dispatch: AppDispatch,
): Promise<void> => {
    dispatch(addUserCompanyStart());

    try {
        const response = await Axios({
            baseURL: config.apiUrl,
            url: '/company/addUserCompany',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            data: {
                companyId,
                role: AuthRoles.Admin,
            },
        });

        if (response.status !== HttpStatusCodes.OK) {
            throw new Error(
                response.data.message || `An unkown error occured adding the company: ${companyId}`,
            );
        }

        const token = response.data as { token: string };
        if (token.token) {
            const tokenParts = token.token.split('|');
            dispatch(processToken(tokenParts[0], tokenParts[1]));
        } else {
            dispatch(setError('Invalid token returned after registration.'));
        }
    } catch (e) {
        dispatch(setError(e.message));
    }
};

const removeUserCompany = (companyId: number, force: boolean): AppThunk => async (
    dispatch: AppDispatch,
): Promise<void> => {
    dispatch(removeUserCompanyStart());

    try {
        const response = await Axios({
            baseURL: config.apiUrl,
            url: '/company/removeMyself',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            data: {
                companyId,
                force,
            },
            timeout: config.requestTimeout,
        });

        if (response.status !== HttpStatusCodes.OK) {
            throw new Error(
                response.data.message || `An unkown error occured adding the company: ${companyId}`,
            );
        }

        if (response.data.lastUser) {
            if (response.data.monitorCount > 0) {
                throw new Error('HASMONITORS');
            } else {
                throw new Error('LASTUSER');
            }
            //dispatch(lastUserError());
        }

        const token = response.data as { token: string };
        if (token.token) {
            const tokenParts = token.token.split('|');
            dispatch(processToken(tokenParts[0], tokenParts[1]));
        } else {
            dispatch(setError('Invalid token returned after registration.'));
        }
    } catch (e) {
        dispatch(setError(e.message));
        if (e.message === 'LASTUSER' || e.message === 'HASMONITORS') {
            throw e;
        }
    }
};

const slice = createSlice({
    name: 'AUTH',
    initialState,
    reducers: {
        firebaseLoginStart: (state: IAuthState): IAuthState => {
            return {
                ...state,
                authenticating: true,
                authenticated: false,
            };
        },
        startTokenRefresh: (state: IAuthState): IAuthState => {
            return {
                ...state,
                refreshing: true,
            };
        },
        setToken: (state: IAuthState, action: PayloadAction<IAuthUser>): IAuthState => {
            return {
                ...state,
                refreshing: false,
                lastUser: false,
                user: action.payload,
            };
        },
        authenticated: (state: IAuthState): IAuthState => {
            return {
                ...state,
                authenticating: false,
                authenticated: true,
                reinitialize: false,
                lastUser: false,
            };
        },
        completeLogout: (state: IAuthState): IAuthState => {
            return {
                ...state,
                authenticated: false,
                authenticating: false,
                refreshing: false,
                user: undefined,
            };
        },
        registrationStart: (state: IAuthState): IAuthState => {
            return {
                ...state,
                authenticating: true,
                error: undefined,
            };
        },
        setError: (state: IAuthState, action: PayloadAction<string>): IAuthState => {
            return {
                ...state,
                authenticating: false,
                error: action.payload,
            };
        },
        registrationEnd: (state: IAuthState, action: PayloadAction<IAuthUser>): IAuthState => {
            return {
                ...state,
                refreshing: false,
                lastUser: false,
                user: action.payload,
            };
        },
        addUserCompanyStart: (state: IAuthState): IAuthState => {
            return {
                ...state,
                reinitialize: true,
            };
        },
        removeUserCompanyStart: (state: IAuthState): IAuthState => {
            return {
                ...state,
                reinitialize: true,
            };
        },
        lastUserError: (state: IAuthState): IAuthState => {
            return {
                ...state,
                lastUser: true,
            };
        },
        setReturnUrl: (state: IAuthState, action: PayloadAction<string>): IAuthState => {
            return {
                ...state,
                returnUrl: Buffer.from(action.payload, 'base64').toString('utf8'),
            };
        },
    },
});

export {
    processToken,
    logout,
    register,
    addUserCompany,
    removeUserCompany,
    loginWithFirebaseToken,
};

export const {
    setToken,
    authenticated,
    startTokenRefresh,
    completeLogout,
    registrationStart,
    registrationEnd,
    setError,
    addUserCompanyStart,
    removeUserCompanyStart,
    lastUserError,
    firebaseLoginStart,
    setReturnUrl,
} = slice.actions;

const authReducer = slice.reducer;
export { authReducer };
