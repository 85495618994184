/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { MiddlewareAPI, Middleware } from 'redux';
import { AppDispatch } from '../store';
import { RootState } from '..';
import { getCompanies } from '../reducers/companyReducer';
import { PayloadAction } from '@reduxjs/toolkit';
import { getMonitors } from '../reducers/monitorReducer';
import { createSocket } from '../socket';
import { getLocations } from '../reducers/locationReducer';
import { getWeatherData } from '../reducers/weatherDataReducer';
import { getMonitorData } from '../reducers/monitorDataReducer';
import { getNotifications } from '../reducers/notificationReducer';

export const crossReducerMiddleware: Middleware = (api: MiddlewareAPI<AppDispatch, RootState>) => (
    next: AppDispatch,
) => <A extends PayloadAction>(action: A): void => {
    switch (action.type) {
        case 'AUTH/authenticated': {
            const user = api.getState().auth.user;
            if (!user || user.id === 0) {
                if (!api.getState().company.initialized || api.getState().auth.reinitialize) {
                    api.dispatch(getCompanies()).catch(e => {
                        console.log(`An error occred retrieving the list of companies: ${e}`);
                    });
                }

                next(action);
                return;
            }

            if (!api.getState().company.initialized || api.getState().auth.reinitialize) {
                api.dispatch(getCompanies()).catch(e => {
                    console.log(`An error occred retrieving the list of companies: ${e}`);
                });
            }

            if (!api.getState().monitor.initialized || api.getState().auth.reinitialize) {
                api.dispatch(getMonitors()).catch(e => {
                    console.log(`An error occured retrieving the list of monitors: ${e}`);
                });
            }

            if (!api.getState().location.initialized || api.getState().auth.reinitialize) {
                api.dispatch(getLocations()).catch(e => {
                    console.log(`An error occured retrieving the list of locations: ${e}`);
                });
            }

            if (!api.getState().monitorData.initialized || api.getState().auth.reinitialize) {
                api.dispatch(getMonitorData()).catch(e => {
                    console.group(`An error occred retrieving the initial monitor data: ${e}`);
                });
            }

            if (!api.getState().weatherData.initialized) {
                api.dispatch(getWeatherData()).catch(e => {
                    console.log(`An error occured retrieving the initial weather data: ${e}`);
                });
            }

            if (!api.getState().company.initialized || api.getState().auth.reinitialize) {
                api.dispatch(getNotifications()).catch(e => {
                    console.log(
                        `An error occred retrieving the list of current notifications: ${e}`,
                    );
                });
            }

            if (user && user.token) {
                createSocket(user.token, api.dispatch);
            }

            next(action);
            return;
        }
        default: {
            next(action);
            return;
        }
    }
};
