import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { IonLoading } from '@ionic/react';
import firebase from 'firebase/app';
import { AppDispatch } from '../store/store';
import { loginWithFirebaseToken } from '../store/reducers/authReducer';
import { setError } from '../store/reducers/companyReducer';
import { RootState } from '../store';

interface IProcessLoginProps {
    onInitialized: () => void;
}

export const ProcessLogin: React.FC<IProcessLoginProps> = (props: IProcessLoginProps) => {
    const dispatch = useDispatch<AppDispatch>();
    const history = useHistory();
    const [initialized, setInitialized] = useState(false);
    const savedPath = useSelector((state: RootState) => state.clientSettings.savedPath);

    const loginCallback = useCallback(
        (token: string) => {
            dispatch(loginWithFirebaseToken(token)).then((): void => {
                setInitialized(true);
                props.onInitialized();
                history.push(savedPath);
            });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    //const provider = new firebase.auth.OAuthProvider('microsoft.com');
    useEffect(
        () => {
            firebase
                .auth()
                .getRedirectResult()
                .then(result => {
                    if (result && result.user) {
                        result.user
                            .getIdToken(false)
                            .then(token => {
                                loginCallback(token);
                            })
                            .catch(e => {
                                dispatch(
                                    setError(
                                        `An error occured retrieving your login token after authentication: ${e.message ||
                                            e}`,
                                    ),
                                );
                            });
                    } else {
                        setInitialized(true);
                        props.onInitialized();
                    }
                })
                .catch(e => {
                    setInitialized(true);
                    props.onInitialized();
                    dispatch(
                        setError(
                            `An unknown error occured during authentication: ${e.message || e}`,
                        ),
                    );
                });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    return <IonLoading isOpen={!initialized} message="Loading ..." />;
};
