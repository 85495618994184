/* eslint-disable @typescript-eslint/no-use-before-define */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IClientSettingsState } from '../state/IClientSettingsState';
import { SAVED_STATE } from '../../models/Constants';
import { AppDispatch, AppThunk } from '../store';

const getInitialState = (): IClientSettingsState => {
    const listViewStr = localStorage.getItem(SAVED_STATE.LIST_VIEW);
    const listView = listViewStr ? listViewStr.toLowerCase() === 'true' : false;
    const savedPath = localStorage.getItem(SAVED_STATE.SAVED_PATH);

    return {
        listView,
        savedPath: savedPath || `/home${listView ? '-list' : ''}`,
    };
};

const initialState = getInitialState();

const storeListView = (listView: boolean): AppThunk => async (
    dispatch: AppDispatch,
): Promise<boolean> => {
    return new Promise(resolve => {
        localStorage.setItem(SAVED_STATE.LIST_VIEW, `${listView}`);
        dispatch(setListView(listView));
        resolve(listView);
    });
};

const storeSavedPath = (savedPath: string): AppThunk => async (
    dispatch: AppDispatch,
): Promise<string> => {
    return new Promise(resolve => {
        localStorage.setItem(SAVED_STATE.SAVED_PATH, savedPath);
        dispatch(setSavedPath(savedPath));
        resolve(savedPath);
    });
};

const slice = createSlice({
    name: 'CLIENTSETTINGS',
    initialState,
    reducers: {
        setListView: (
            state: IClientSettingsState,
            action: PayloadAction<boolean>,
        ): IClientSettingsState => {
            return {
                ...state,
                listView: action.payload,
            };
        },
        setSavedPath: (
            state: IClientSettingsState,
            action: PayloadAction<string>,
        ): IClientSettingsState => {
            return {
                ...state,
                savedPath: action.payload,
            };
        },
    },
});

export { storeListView, storeSavedPath };

export const { setListView, setSavedPath } = slice.actions;

const clientSettingsReducer = slice.reducer;
export { clientSettingsReducer };
