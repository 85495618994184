/* eslint-disable @typescript-eslint/no-use-before-define */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, AppDispatch } from '../store';
import { ILocation } from 'vision9-solar-shared';
import Axios from 'axios';
import { ILocationState } from '../state/ILocationState';
import HttpStatusCodes from 'http-status-codes';
import { config } from '../../config';

const initialState: ILocationState = {
    fetching: false,
    initialized: false,
    locations: [],
};

const getLocations = (): AppThunk => async (dispatch: AppDispatch): Promise<void> => {
    dispatch(getLocationsStart());

    try {
        const response = await Axios({
            baseURL: config.apiUrl,
            url: '/location',
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (response.status !== HttpStatusCodes.OK) {
            throw new Error(
                response.data.message ||
                    'An unkown error occured retrieving the list of Locations.',
            );
        }
        dispatch(getLocationsEnd(response.data));
    } catch (e) {
        dispatch(setError(e.message));
    }
};

const slice = createSlice({
    name: 'LOCATION',
    initialState,
    reducers: {
        getLocationsStart: (state: ILocationState): ILocationState => {
            return {
                ...state,
                fetching: true,
                initialized: false,
                error: undefined,
                locations: [],
            };
        },
        setError: (state: ILocationState, action: PayloadAction<string>): ILocationState => {
            return {
                ...state,
                fetching: false,
                initialized: false,
                error: action.payload,
            };
        },
        getLocationsEnd: (
            state: ILocationState,
            action: PayloadAction<ILocation[]>,
        ): ILocationState => {
            return {
                ...state,
                fetching: false,
                initialized: true,
                error: undefined,
                locations: action.payload,
            };
        },
    },
});

export { getLocations };

export const { setError, getLocationsStart, getLocationsEnd } = slice.actions;

const locationReducer = slice.reducer;
export { locationReducer };
