import io from 'socket.io-client';
import { config } from '../config';
import { AppDispatch } from './store';
import { IMonitorData, IWeatherData, INotification } from 'vision9-solar-shared';
import { postMonitorData, postMonitorDataBatch } from './reducers/monitorDataReducer';
import { postWeatherData } from './reducers/weatherDataReducer';
import { postNotification } from './reducers/notificationReducer';
import { trainMonitorProgress } from './reducers/monitorReducer';
import { setAlert } from './reducers/alertReducer';

let socket: SocketIOClient.Socket | undefined;

export const createSocket = async (
    token: string,
    dispatch: AppDispatch,
): Promise<SocketIOClient.Socket> => {
    if (socket && socket.connected) {
        socket.off('connect');
        socket.off('disconnect');
        socket.off('POST_MONITOR_DATA');
        socket.off('POST_WEATHER_DATA');
        socket.off('POST_NOTIFICATION');
        socket.off('TRAIN_MONITOR_PROGRESS');
        socket.disconnect();
    }

    const connectOpts = {
        transports: ['websocket', 'polling'],
        query: {
            token,
        },
    };

    socket = io(config.socketIoUrl, {
        ...connectOpts,
    });

    socket.connect();

    socket.on('error', (error: string) => {
        console.log(`Socket error: ${error}`);
    });

    socket.on('connect', () => {
        console.log('connected');
        //        dispatch(socketConnected())
    });

    socket.on('disconnect', () => {
        console.log('disconnected');
        //        store.dispatch(socketDisconnected())
    });

    socket.on('POST_MONITOR_DATA', (data: IMonitorData) => {
        dispatch(postMonitorData(data));
    });

    socket.on('POST_MONITOR_DATA_BATCH', (data: IMonitorData[]) => {
        dispatch(postMonitorDataBatch(data));
    });

    socket.on('POST_NOTIFICATION', (data: INotification) => {
        dispatch(postNotification(data));
    });

    socket.on('POST_WEATHER_DATA', (data: IWeatherData) => {
        dispatch(postWeatherData(data));
    });

    socket.on('TRAIN_MONITOR_PROGRESS', (data: { monitorId: number; progress: number }) => {
        dispatch(trainMonitorProgress(data));
        if (data.progress === 1) {
            dispatch(
                setAlert({
                    header: 'Training complete.',
                    message: `Traning of monitor ${data.monitorId} completed.`,
                }),
            );
        }
    });

    return socket;
};
