import React from 'react';

import { IonAlert } from '@ionic/react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../store/store';
import { RootState } from '../store';
import { dismissAlert } from '../store/reducers/alertReducer';

export const Alerts: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const { open, header, message } = useSelector((state: RootState) => {
        return state.alert;
    });

    return (
        <IonAlert
            isOpen={open}
            onDidDismiss={(): void => {
                dispatch(dismissAlert());
            }}
            header={header}
            message={message}
            buttons={[
                {
                    text: 'Ok',
                    handler: (): void => {
                        dispatch(dismissAlert());
                    },
                },
            ]}
        />
    );
};
