import {
    IonContent,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonMenu,
    IonMenuToggle,
    IonTitle,
    IonToolbar,
    IonImg,
} from '@ionic/react';
import React, { useState, useEffect, ReactElement, useCallback } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import { IAppPage } from '../declarations';
import { useSelector, useDispatch } from 'react-redux';
import {
    home,
    globe,
    sunny,
    arrowDownCircleOutline,
    arrowForwardCircleOutline,
    person,
    business,
    list,
    pieChart,
    build,
    closeCircle,
} from 'ionicons/icons';
import { RootState } from '../store';
import { ICompany, AuthRoles } from 'vision9-solar-shared';

import './Menu.scss';
import solarIcon from '../assets/solarIcon.svg';
import { AppDispatch } from '../store/store';
import { storeListView } from '../store/reducers/clientSettingsReducer';
import { TrainingDialog } from './TrainingDialog';
import { cancelTraining } from '../store/reducers/monitorReducer';

import poweredByDarkSky from '../assets/poweredby-darksky.png';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const version = require('../../package.json').version;

const Menu: React.FC = () => {
    const history = useHistory();
    const dispatch = useDispatch<AppDispatch>();

    const companies = useSelector((state: RootState) => state.company.companies);
    const monitors = useSelector((state: RootState) => state.monitor.monitors);
    const listView = useSelector((state: RootState) => state.clientSettings.listView);
    const user = useSelector((state: RootState) => state.auth.user);
    const { training, progress } = useSelector((state: RootState) => {
        const trainingState = state.monitor.trainingState[0];
        return trainingState || { training: false, progress: 0 };
    });

    const [appPages, setAppPages] = useState<IAppPage[]>();
    const [menuState, setMenuState] = useState([] as boolean[]);
    const [trainingDialogOpen, setTrainingDialogOpen] = useState(false);

    const setAppPagesCallback = useCallback(() => {
        if (!companies || !monitors) {
            return;
        }

        const filteredCompanies = companies.filter(
            c => monitors.filter(m => m.companyId === c.companyId).length > 0,
        );

        setAppPages([
            {
                title: 'Home',
                url: listView ? '/home-list' : '/home',
                icon: home,
                count: 0,
            },
            {
                title: 'All Sites',
                url: listView ? '/sites-list' : '/sites',
                icon: business,
                count: 0,
            },
            {
                title: 'Companies',
                icon: globe,
                count: filteredCompanies.length,
                pages: [
                    ...filteredCompanies.map((company: ICompany) => ({
                        title: `   ${company.companyName}`,
                        url: `/sites${listView ? '-list' : ''}/${company.companyId}`,
                        icon: sunny,
                        count: 0,
                    })),
                ],
            },
            {
                title: 'Profile',
                url: '/profile',
                icon: person,
                count: 0,
            },
            {
                title: listView ? 'Gauge View' : 'List View',
                icon: listView ? pieChart : list,
                callback: (): Promise<void> => {
                    return new Promise(resolve => {
                        dispatch(storeListView(!listView)).then(result => {
                            if (result) {
                                history.push('/home-list');
                            } else {
                                history.push('/home');
                            }
                        });
                        resolve();
                    });
                },
                count: 0,
            },
            ...(user && user.role === AuthRoles.Admin
                ? [
                      {
                          title: training
                              ? `Cancel (${Math.round(progress * 100)}%)`
                              : 'Train Base Model',
                          icon: training ? closeCircle : build,
                          callback: (): Promise<void> => {
                              if (!training) {
                                  setTrainingDialogOpen(true);
                              } else {
                                  dispatch(cancelTraining(0));
                              }
                              return Promise.resolve();
                          },
                          count: 0,
                      },
                  ]
                : []),
        ]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [companies, monitors, listView, training, progress]);

    useEffect(
        () => {
            setMenuState(companies.map(() => false));
            setAppPagesCallback();
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [companies, monitors, listView, training, progress],
    );

    const renderAppPage = (appPage: IAppPage, index: string): ReactElement | string => {
        return appPage.url ? (
            <IonMenuToggle key={index} autoHide={false}>
                <IonItem routerLink={appPage.url} routerDirection="none">
                    <IonIcon slot="start" icon={appPage.icon} />
                    <IonLabel>{appPage.title}</IonLabel>
                </IonItem>
            </IonMenuToggle>
        ) : appPage.callback ? (
            <IonMenuToggle key={index} autoHide={false}>
                <IonItem
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion,@typescript-eslint/explicit-function-return-type
                    onClick={() => appPage.callback!()}
                    routerDirection="none"
                >
                    <IonIcon slot="start" icon={appPage.icon} />
                    <IonLabel>{appPage.title}</IonLabel>
                </IonItem>
            </IonMenuToggle>
        ) : (
            ''
        );
    };

    return (
        <IonMenu contentId="main" type="overlay">
            <IonHeader>
                <IonToolbar>
                    <IonIcon slot="start" icon={solarIcon} className="solar-icon" />
                    <IonTitle>Vision9 Solar</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonList>
                    {appPages
                        ? appPages.map((appPage, index) => {
                              return appPage.count > 0 ? (
                                  <div key={index}>
                                      <IonItem
                                          className="menu-expandable"
                                          onClick={(): void => {
                                              setMenuState([
                                                  ...menuState.slice(0, index),
                                                  !menuState[index],
                                                  ...menuState.slice(index + 1),
                                              ]);
                                          }}
                                          routerDirection="none"
                                      >
                                          <IonIcon
                                              slot="start"
                                              icon={
                                                  menuState[index]
                                                      ? arrowDownCircleOutline
                                                      : arrowForwardCircleOutline
                                              }
                                          />
                                          <IonLabel>{appPage.title}</IonLabel>
                                      </IonItem>
                                      {menuState[index] ? (
                                          <IonList className="menu-submenu">
                                              {appPage.pages
                                                  ? appPage.pages.map((subPage, subIndex) =>
                                                        renderAppPage(
                                                            subPage,
                                                            `${index}.${subIndex}`,
                                                        ),
                                                    )
                                                  : ''}
                                          </IonList>
                                      ) : (
                                          ''
                                      )}
                                  </div>
                              ) : appPage.url || appPage.callback ? (
                                  <div key={index}>{renderAppPage(appPage, index.toString())}</div>
                              ) : (
                                  ''
                              );
                          })
                        : ''}
                </IonList>
                <IonItem className="version-label">
                    <div>Version: {version}</div>
                    <IonImg src={poweredByDarkSky} className="poweredby-darksky" />
                </IonItem>
                <TrainingDialog
                    monitorId={0}
                    isOpen={trainingDialogOpen}
                    close={(): void => setTrainingDialogOpen(false)}
                />
            </IonContent>
        </IonMenu>
    );
};
export default withRouter(Menu);
