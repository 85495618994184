import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter, useHistory, useLocation } from 'react-router-dom';
import { createDecipheriv } from 'crypto';
import firebase from 'firebase/app';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import { AppDispatch } from '../../store/store';
import { setAlert } from '../../store/reducers/alertReducer';
import { RootState } from '../../store';
import { config } from '../../config';
import { GoogleSigninButton } from '../../components/GoogleSigninButton';
import { MicrosoftSigninButton } from '../../components/MicrosoftSigninButton';
import { FacebookSigninButton } from '../../components/FacebookSigninButton';
import { ProcessLogin } from '../../components/ProcessLogin';
import 'firebase/auth';

import './Login.scss';
import { loginWithFirebaseToken } from '../../store/reducers/authReducer';
import { storeSavedPath } from '../../store/reducers/clientSettingsReducer';

const decrypt = (encrypted: string): string => {
    const decipher = createDecipheriv(
        'aes-256-cbc',
        'uZK1iYYTH6csINGQcIheC9Hi24NB2POs',
        'iQA/2kE2vaeuNyWE',
    );
    let dec = decipher.update(encrypted, 'hex', 'utf8');
    dec += decipher.final('utf8');
    return dec;
};

firebase.initializeApp(JSON.parse(decrypt(config.firebase)));
//const provider = new firebase.auth.OAuthProvider('microsoft.com');

const LoginPage: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const authenticating = useSelector((state: RootState) => state.auth.authenticating);
    const [initialized, setInitialized] = useState(false);
    const history = useHistory();
    const location = useLocation<{ from: string }>();
    const savedPath = useSelector((state: RootState) => state.clientSettings.savedPath);

    if (location.state?.from) {
        dispatch(storeSavedPath(location.state.from));
    }

    const loginFirebase = async (providerName: string): Promise<void> => {
        const provider =
            providerName === 'microsoft'
                ? new firebase.auth.OAuthProvider('microsoft.com')
                : providerName === 'google'
                ? new firebase.auth.GoogleAuthProvider()
                : providerName === 'facebook'
                ? new firebase.auth.FacebookAuthProvider()
                : undefined;

        if (!provider) {
            return;
        }

        try {
            // firebase.auth().signInWithRedirect(provider);
            const result = await firebase.auth().signInWithPopup(provider);
            if (result && result.user) {
                const token = await result.user.getIdToken(false);
                await dispatch(loginWithFirebaseToken(token));
                history.push(savedPath);
            }
        } catch (e) {
            dispatch(
                setAlert({
                    header: 'Login Error',
                    message: `An error occured during authentication.  Please try again.<br/><br/>${e.message ||
                        e}`,
                }),
            );
        }
    };

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Login</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent scrollX={false} scrollY={false}>
                <ProcessLogin onInitialized={(): void => setInitialized(true)} />
                <div className="login-content" hidden={!initialized && !authenticating}>
                    <div className="login-content-main">
                        <div className="login-content-main-text">
                            Welcome to the Vision9 Solar Monitoring Solution. Please login to access
                            your sites or begin the registration process.
                        </div>
                        <div className="login-content-main-button">
                            <GoogleSigninButton
                                onClick={(): Promise<void> => loginFirebase('google')}
                            />
                            <MicrosoftSigninButton
                                onClick={(): Promise<void> => loginFirebase('microsoft')}
                            />
                            <FacebookSigninButton
                                onClick={(): Promise<void> => loginFirebase('facebook')}
                            />
                        </div>
                    </div>
                </div>
            </IonContent>
        </IonPage>
    );
};

const loginPage = withRouter(LoginPage);

export { loginPage as LoginPage };
