/* eslint-disable react/prop-types */
/* eslint-disable no-constant-condition */
/* eslint-disable @typescript-eslint/no-use-before-define */
import {
    IonButtons,
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonIcon,
    IonRouterLink,
    IonLoading,
    IonSpinner,
    useIonViewDidEnter,
    IonBackButton,
} from '@ionic/react';
import React, { useEffect, useState } from 'react';
import './SitesList.scss';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../store';
import { withRouter, RouteComponentProps, useHistory, useRouteMatch } from 'react-router-dom';
import WeatherIcon from 'react-icons-weather';

import { IMonitorData } from 'vision9-solar-shared';
import { arrowForward, warning } from 'ionicons/icons';
import { LogoutButton } from '../../components/LogoutButton';
import { AutoSizer, List, ListRowProps } from 'react-virtualized';
import { Sparklines, SparklinesReferenceLine, SparklinesLine } from 'react-sparklines';
import { AppDispatch } from '../../store/store';
import { storeSavedPath } from '../../store/reducers/clientSettingsReducer';

const UNIT_DIVIDER = 1000;
const TEMPERATURE_UNITS = 'C';

const SitesListPage: React.FC<RouteComponentProps> = () => {
    const match = useRouteMatch<{ companyId?: string }>();
    const history = useHistory();
    const dispatch = useDispatch<AppDispatch>();

    const weatherData = useSelector((state: RootState) => state.weatherData.data);
    const notifications = useSelector((state: RootState) => state.notification.notifications);
    const initialized = useSelector(
        (state: RootState) =>
            state.monitorData.initialized &&
            state.company.initialized &&
            state.location.initialized &&
            state.monitor.initialized,
    );

    const companyName = useSelector((state: RootState) => {
        const companyId = match.params.companyId ? parseInt(match.params.companyId) : NaN;

        if (isNaN(companyId)) {
            return '';
        } else {
            const company = state.company.companies.find(c => c.companyId === companyId);
            if (company) {
                return company.companyName;
            } else {
                return '';
            }
        }
    });

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 15000);
    }, []);

    useIonViewDidEnter(() => {
        dispatch(
            storeSavedPath(
                `/sites-list${match.params.companyId ? `/${match.params.companyId}` : ''}`,
            ),
        );
    });

    const getNotificationLevel = (monitorId: number): string => {
        const notices = notifications.filter(n => n.monitorId === monitorId);
        return notices.reduce((p, c) => {
            if (p === 'ALERT') {
                return p;
            } else if (c.event === 'WARNING') {
                return 'WARNING';
            } else {
                return 'ALERT';
            }
        }, '');
    };

    const data = useSelector((state: RootState) => {
        return state.monitor.monitors
            .filter(monitor =>
                match.params.companyId && !isNaN(parseInt(match.params.companyId))
                    ? parseInt(match.params.companyId) === monitor.companyId
                    : true,
            )
            .sort((a, b) => a.monitorName.localeCompare(b.monitorName))
            .map(monitor => {
                const predictions = state.monitorData.predictions[monitor.monitorId];
                const item = {
                    ...monitor,
                    ...(state.monitorData.data.find(
                        data => data.monitorId === monitor.monitorId,
                    ) as IMonitorData),
                    history: state.monitorData.history[monitor.monitorId],
                    notification: getNotificationLevel(monitor.monitorId),
                    prediction:
                        predictions && predictions.length > 0
                            ? predictions.reduce((sum, value) => sum + value, 0) /
                              predictions.length
                            : undefined,
                };
                return item;
            });
    });

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton
                            defaultHref={
                                match.params.companyId && !isNaN(parseInt(match.params.companyId))
                                    ? '/sites-list'
                                    : '/home-list'
                            }
                            text=""
                        />
                    </IonButtons>
                    <IonTitle>
                        {((): string => {
                            const total = data.reduce((previous, value) => {
                                return previous + (value.current || 0);
                            }, 0);

                            return `${
                                total >= 100000
                                    ? Math.round(total / 1000).toLocaleString('en-US')
                                    : total >= 10000
                                    ? (Math.round(total / 100) / 10).toFixed(1)
                                    : (Math.round(total / 10) / 100).toFixed(2)
                            } kWh${companyName.length > 0 ? ` (${companyName})` : ''}`;
                        })()}
                    </IonTitle>
                    <LogoutButton />
                </IonToolbar>
            </IonHeader>
            <IonContent scrollX={false} scrollY={false}>
                {data.length === 0 ? (
                    <div className="home-list-content">
                        <div className="home-list-content-main">
                            {initialized ? (
                                <div className="home-list-content-main-text">
                                    Welcome to the Vision9 Solar Monitoring Solution. You currently
                                    have no active monitors that have recorded data.
                                    <br />
                                    <br />
                                    You can add companies and monitors from the &nbsp;
                                    <IonRouterLink routerLink="/profile">Profile</IonRouterLink>
                                    &nbsp; page.
                                    <br />
                                    <br />
                                    <IonRouterLink routerLink="/profile">
                                        Go to the profile page &nbsp;
                                        <IonIcon icon={arrowForward} />
                                    </IonRouterLink>
                                </div>
                            ) : (
                                <div className="home-list-content-main-text">
                                    <IonLoading isOpen={true} message="Loading ..." />
                                </div>
                            )}
                        </div>
                    </div>
                ) : (
                    <React.Fragment>
                        <AutoSizer>
                            {({ width, height }): JSX.Element => (
                                <List
                                    height={height}
                                    width={width}
                                    rowHeight={60}
                                    autoContainerWidth={true}
                                    rowCount={data.length}
                                    noRowsRenderer={(): JSX.Element => <IonLoading isOpen={true} />}
                                    rowRenderer={(props: ListRowProps): JSX.Element => {
                                        const item = data[props.index];
                                        const hasData = item.dateRecorded !== undefined;
                                        const max = Math.max(item.max, ...(item.history || []));
                                        const weather = weatherData.find(
                                            w => item.locationId === w.locationId,
                                        );
                                        const divider = item.history
                                            ? Math.max(1, Math.floor(item.history.length / 10))
                                            : 1;
                                        const stateColor = item.prediction
                                            ? item.current > item.prediction * 0.8
                                                ? '#0ec254'
                                                : item.current > item.prediction * 0.6
                                                ? '#e0b500'
                                                : '#d33939'
                                            : '#989aa2';

                                        return (
                                            <div
                                                key={props.key}
                                                className="home-list-row"
                                                style={{
                                                    ...props.style,
                                                }}
                                                onClick={(): void => {
                                                    history.push({
                                                        pathname: `/site-details/${item.monitorId}`,
                                                    });
                                                }}
                                            >
                                                <div className="home-list-row-spark">
                                                    <Sparklines
                                                        data={
                                                            item.history && item.history.length > 0
                                                                ? item.history.filter(
                                                                      (value, index) =>
                                                                          index % divider === 0,
                                                                  )
                                                                : [0]
                                                        }
                                                        limit={
                                                            item.history && item.history.length > 0
                                                                ? Math.ceil(
                                                                      item.history.length / divider,
                                                                  )
                                                                : 1
                                                        }
                                                        width={100}
                                                        height={50}
                                                        max={max}
                                                        min={0}
                                                        svgWidth={100}
                                                        svgHeight={50}
                                                        margin={5}
                                                    >
                                                        <SparklinesReferenceLine
                                                            type="custom"
                                                            key="green"
                                                            value={
                                                                50 -
                                                                Math.round(
                                                                    (item.green / max) * 50 * 100,
                                                                ) /
                                                                    100
                                                            }
                                                            style={{ stroke: 'green' }}
                                                        />
                                                        <SparklinesReferenceLine
                                                            type="custom"
                                                            key="red"
                                                            value={
                                                                50 -
                                                                Math.round(
                                                                    (item.red / max) * 50 * 100,
                                                                ) /
                                                                    100
                                                            }
                                                            style={{ stroke: 'red' }}
                                                        />
                                                        <SparklinesLine color="white" />
                                                    </Sparklines>
                                                </div>
                                                <div className="home-list-row-col1">
                                                    <div className="home-list-row-col1-row1">
                                                        {item.monitorName}
                                                    </div>
                                                    <div
                                                        className="home-list-row-col1-row2"
                                                        hidden={!hasData}
                                                    >
                                                        {item.dateRecorded
                                                            ? `${item.dateRecorded.toLocaleDateString()}, ${item.dateRecorded.toLocaleTimeString()}`
                                                            : ''}
                                                    </div>
                                                    <div
                                                        className="home-list-row-col1-row3"
                                                        hidden={!hasData}
                                                    >
                                                        TDY:{' '}
                                                        {item.today
                                                            ? Math.round(
                                                                  item.today / UNIT_DIVIDER,
                                                              ).toLocaleString()
                                                            : '-'}
                                                        , AVG:{' '}
                                                        {item.average
                                                            ? Math.round(
                                                                  item.average / UNIT_DIVIDER,
                                                              ).toLocaleString()
                                                            : '-'}
                                                        , MTD:{' '}
                                                        {item.monthToDate
                                                            ? Math.round(
                                                                  item.monthToDate / UNIT_DIVIDER,
                                                              ).toLocaleString()
                                                            : '-'}
                                                    </div>
                                                </div>
                                                <div className="home-list-row-col4">
                                                    <div>
                                                        {weather && weather.icon ? (
                                                            <WeatherIcon
                                                                name="darksky"
                                                                iconId={
                                                                    weather && weather.icon
                                                                        ? weather.icon
                                                                        : ''
                                                                }
                                                            />
                                                        ) : (
                                                            ''
                                                        )}
                                                    </div>
                                                    <div>
                                                        {weather
                                                            ? `${Math.round(
                                                                  weather.temperature,
                                                              )}\xB0${TEMPERATURE_UNITS}`
                                                            : ''}
                                                    </div>
                                                </div>
                                                <div
                                                    className="home-list-row-value"
                                                    style={{
                                                        color: stateColor,
                                                    }}
                                                >
                                                    {!hasData ? (
                                                        loading ? (
                                                            <IonSpinner
                                                                className="home-list-row-spark-spinner"
                                                                key={props.key}
                                                                style={{
                                                                    ...props.style,
                                                                    height: '24px',
                                                                }}
                                                            >
                                                                {item.monitorName}
                                                            </IonSpinner>
                                                        ) : (
                                                            <IonIcon icon={warning} />
                                                        )
                                                    ) : item.current >= 100000 ? (
                                                        Math.round(
                                                            item.current / 1000,
                                                        ).toLocaleString('en-US')
                                                    ) : item.current >= 10000 ? (
                                                        (
                                                            Math.round(item.current / 100) / 10
                                                        ).toFixed(1)
                                                    ) : (
                                                        (
                                                            Math.round(item.current / 10) / 100
                                                        ).toFixed(2)
                                                    )}
                                                </div>
                                            </div>
                                        );
                                    }}
                                />
                            )}
                        </AutoSizer>
                    </React.Fragment>
                )}
            </IonContent>
        </IonPage>
    );
};

const sitesListPage = withRouter(SitesListPage);

export { sitesListPage as SitesListPage };
