/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { MiddlewareAPI, Middleware } from 'redux';
import { AppDispatch } from '../store';
import { RootState } from '..';
import Axios, { AxiosPromise } from 'axios';
import { config } from '../../config';
import HttpStatusCodes from 'http-status-codes';
import AsyncLock from 'async-lock';
import {
    startTokenRefresh,
    logout,
    processToken,
    authenticated,
    setError,
} from '../reducers/authReducer';
import { PayloadAction } from '@reduxjs/toolkit';
import { IAuthUser } from 'vision9-solar-shared';

const lock = new AsyncLock();
let refreshPromise: AxiosPromise | undefined;

export const getRefreshPromise = (): AxiosPromise => {
    return new Promise(resolve => {
        lock.acquire('refresh-promise', () => {
            resolve(refreshPromise);
        });
    });
};

const setRefreshPromise = (promise: AxiosPromise): AxiosPromise => {
    return new Promise(resolve => {
        lock.acquire('refresh-promise', () => {
            refreshPromise = promise;
            resolve(refreshPromise);
        });
    });
};

export const refreshTokenMiddleware: Middleware = (
    api: MiddlewareAPI<AppDispatch, RootState>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
) => (next: AppDispatch) => <A extends PayloadAction<any>>(action: A): void => {
    if (action.type.startsWith('AUTH/') && action.type !== 'AUTH/setToken') {
        next(action);
        return;
    }

    let user = api.getState().auth.user;
    if (action.type === 'AUTH/setToken') {
        user = action.payload as IAuthUser;
    }
    if (!user || !user.exp) {
        next(action);
        return;
    }

    if ((user.exp - 60) * 1000 <= Date.now()) {
        if (refreshPromise && api.getState().auth.refreshing) {
            refreshPromise.then(() => next(action));
            return;
        }

        if (!user.refreshToken) {
            api.dispatch(logout());
            return;
        }

        api.dispatch(startTokenRefresh());

        const p = setRefreshPromise(
            Axios({
                baseURL: config.apiUrl,
                url: '/auth/refresh',
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                data: {
                    username: `User.${user.id}`,
                    password: user.refreshToken,
                },
                timeout: config.requestTimeout,
            }),
        );

        p.then(response => {
            if (response.status !== HttpStatusCodes.OK) {
                api.dispatch(setError(response.data.message || 'Unknown error refreshing token.'));
                api.dispatch(logout());
            } else {
                if (response.data.user && response.data.user.token) {
                    api.dispatch(
                        processToken(response.data.user.token, response.data.user.refreshToken),
                    ).then(() => {
                        if (action.type !== 'AUTH/setToken') {
                            next(action);
                        }
                    });
                } else {
                    api.dispatch(logout());
                }
            }
        }).catch(refreshError => {
            console.log(`Error refreshing token: ${refreshError}`);
            api.dispatch(logout());
        });
    } else if (action.type === 'AUTH/setToken') {
        api.dispatch(authenticated());
        next(action);
    } else {
        next(action);
    }
};
