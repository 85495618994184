/* eslint-disable react/prop-types */
import React, { ReactElement, useEffect } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { IonApp, IonRouterOutlet, IonSplitPane } from '@ionic/react';
import { IonReactHashRouter } from '@ionic/react-router';
import AddToHomeScreen from 'a2hs.js';
import Menu from './components/Menu';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme.scss';
import './theme/variables.scss';

/* pages */
import { LoginPage } from './pages/login/Login';
import { TokenPage } from './pages/token/Token';
import { HomePage } from './pages/home/Home';
import { useSelector } from 'react-redux';
import { RootState } from './store';
import { ProfilePage } from './pages/profile/Profile';
import { SitesPage } from './pages/sites/Sites';
import { RegisterPage } from './pages/register/Register';
import { CompanyPage } from './pages/profile/Company';
import { MonitorPage } from './pages/profile/Monitor';
import { HomeListPage } from './pages/homeList/HomeList';
import { SitesListPage } from './pages/sites-list/SitesList';
import { SiteDetailsPage } from './pages/site-details/SiteDetails';
import { Alerts } from './components/Alerts';

const LoginRedirect = (): ReactElement => {
    if (document.location.hash.endsWith('/login')) {
        return <React.Fragment />;
    }

    return (
        <Redirect
            to={{
                pathname: '/login',
                state: { from: document.location.hash.substr(1) },
            }}
        />
    );
};

const RegisterRedirect = (): ReactElement => {
    return <Redirect to="/register" />;
};

const getComponent = (
    authenticated: boolean,
    registered: boolean,
    component: React.ComponentClass,
): (() => ReactElement) | React.ComponentClass => {
    if (!authenticated) {
        return LoginRedirect;
    }

    if (!registered) {
        return RegisterRedirect;
    }

    return component;
};

const App: React.FC = () => {
    const { authenticated, authenticating } = useSelector((state: RootState) => state.auth);
    const registered = useSelector((state: RootState) =>
        state.auth.user ? state.auth.user.id > 0 : false,
    );
    const savedPath = useSelector((state: RootState) => state.clientSettings.savedPath);

    useEffect(() => {
        AddToHomeScreen({
            brandName: 'Vision9 Solar',
            backgroundColor: 'var(--ion-color-dark-tint)',
            htmlContent: `Install <strong>Vision9 Solar Monitor</strong> on your device; tap share <img src='data:image/svg+xml;utf8,<svg width="14px" height="14px" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1000 1000" enable-background="new 0 0 1000 1000" xml:space="preserve">
                    <g>
                        <g transform="translate(0.000000,511.000000) scale(0.100000,-0.100000)">
                            <path d="M4884.1,4997.4c-68.9-18.2-188.6-127.7-813.1-754.3c-707.7-707.7-734-736.1-758.4-835.4c-73-316.3,223-553.6,517.1-415.7c48.7,24.3,217,174.4,425.8,385.3c188.6,190.6,350.8,346.7,358.9,346.7c8.1,0,18.2-1082.8,20.3-2406.9l6.1-2408.9l46.6-81.1c71-123.7,168.3-180.4,306.2-182.5c148,0,261.6,68.9,324.4,196.7l42.6,89.2v2408.9v2408.9l381.2-385.3c358.9-365,385.3-387.3,482.6-409.6c186.6-42.6,358.9,40.5,436,212.9c42.6,93.3,46.7,150.1,16.2,263.6c-18.3,69-135.9,194.7-723.9,786.7c-387.3,387.3-730,721.9-764.4,744.2C5109.2,5007.5,4985.5,5023.7,4884.1,4997.4z"/>
                            <path d="M1791.9,2519.5c-219-60.8-375.1-186.6-464.3-377.2l-52.7-111.5v-3122.7v-3122.7l46.6-115.6c58.8-146,221-326.5,356.9-397.4l103.4-54.8l3177.4-6.1c3167.3-4.1,3177.4-4.1,3288.9,36.5c129.8,48.7,292,186.5,362.9,310.2c123.7,208.8,117.6,16.2,111.5,3400.4l-6.1,3092.2l-48.7,91.3c-75,141.9-123.7,198.7-233.2,273.7c-182.5,123.7-267.6,133.8-1050.3,127.7l-695.5-6.1l-95.3-54.8c-156.1-93.3-217-271.7-148-436c42.6-101.4,85.2-146,182.5-190.6c73-34.5,141.9-38.5,673.2-38.5h592.1l56.8-48.6l56.8-48.7l6.1-2790.1l4.1-2792.1l-48.7-48.7l-50.7-50.7H4999.7c-2844.9,0-2919.9,0-2966.5,38.5l-48.7,38.5v2802.3v2802.3l56.8,48.7l56.8,48.6h584h582l111.5,56.8c294,148,257.5,541.4-60.8,648.9C3215.3,2558.1,1915.6,2554,1791.9,2519.5z"/>
                        </g>
                    </g>
                </svg>' />, and <img src='data:image/svg+xml;utf8,<svg width="14px" height="14px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><g transform="translate(0, 40) scale(1)">
                <path d="M352 240v32c0 6.6-5.4 12-12 12h-88v88c0 6.6-5.4 12-12 12h-32c-6.6 0-12-5.4-12-12v-88h-88c-6.6 0-12-5.4-12-12v-32c0-6.6 5.4-12 12-12h88v-88c0-6.6 5.4-12 12-12h32c6.6 0 12 5.4 12 12v88h88c6.6 0 12 5.4 12 12zm96-160v352c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V80c0-26.5 21.5-48 48-48h352c26.5 0 48 21.5 48 48zm-48 346V86c0-3.3-2.7-6-6-6H54c-3.3 0-6 2.7-6 6v340c0 3.3 2.7 6 6 6h340c3.3 0 6-2.7 6-6z"/></g></svg>' />&nbsp;<strong>Add to Home Screen</strong> ↓`,
            logoImage: `<svg version="1.0" 
                xmlns="http://www.w3.org/2000/svg" width="48px" height="48px" viewBox="0 0 150.000000 150.000000" preserveAspectRatio="xMidYMid meet">
                    <g transform="translate(0.000000,150.000000) scale(0.100000,-0.100000)" stroke="none">
                        <path d="M195 1425 c-51 -18 -110 -81 -124 -131 -15 -55 -15 -1035 0 -1089 15 -52 82 -119 134 -134 55 -15 1035 -15 1090 0 52 15 119 82 134 134 15 55 15 1035 0 1090 -15 52 -82 119 -134 134 -27 7 -206 11 -550 11 -413 -1 -518 -4 -550 -15z m1131 -51 c15 -11 37 -33 48 -48 21 -27 21 -38 21 -576 0 -538 0 -549 -21 -576 -11 -15 -33 -37 -48 -48 -27 -21 -38 -21 -576 -21 -538 0 -549 0 -576 21 -15 11 -37 33 -48 48 -20 27 -21 40 -24 549 -2 287 0 536 3 554 7 40 49 91 90 109 24 11 136 13 567 11 525 -2 537 -2 564 -23z"/>
                        <path d="M427 983 c-14 -54 -25 -98 -24 -99 1 -1 42 -4 91 -5 l90 -4 22 80 c13 44 25 90 29 103 5 22 4 22 -88 22 l-94 0 -26 -97z"/>
                        <path d="M665 980 c-15 -56 -25 -103 -22 -107 3 -3 50 -6 103 -7 l96 -1 29 95 c16 52 29 101 29 108 0 9 -27 12 -103 12 l-104 0 -28 -100z"/>
                        <path d="M932 969 c-18 -61 -32 -113 -30 -114 2 -2 54 -4 117 -6 l114 -4 33 105 c19 58 34 111 34 118 0 9 -30 12 -117 12 l-118 0 -33 -111z"/>
                        <path d="M387 823 c-10 -16 -49 -174 -45 -178 8 -8 161 -25 167 -19 3 3 18 47 32 97 l26 92 -21 7 c-30 9 -154 9 -159 1z"/>
                        <path d="M600 748 c-12 -40 -25 -86 -30 -101 -11 -34 -13 -33 100 -45 52 -5 87 -5 91 0 10 15 62 198 58 202 -2 2 -48 6 -101 10 l-97 6 -21 -72z"/>
                        <path d="M857 719 c-14 -46 -29 -95 -32 -109 l-6 -26 108 -11 c59 -6 111 -9 114 -5 9 9 71 213 67 217 -2 2 -53 7 -115 11 l-111 7 -25 -84z"/>
                        <path d="M610 480 l0 -90 -145 0 -145 0 0 -30 0 -30 386 0 385 0 -3 28 -3 27 -197 3 -198 2 0 84 c0 90 -4 96 -56 96 -24 0 -24 -1 -24 -90z"/>
                    </g>
            </svg>`,
        });
    }, []);

    return (
        <IonApp>
            <IonReactHashRouter>
                <IonSplitPane
                    contentId="main"
                    when={false}
                    hidden={!authenticated && authenticating}
                >
                    <Menu />
                    <IonRouterOutlet id="main">
                        <Route path="/login" component={LoginPage} exact={true} />
                        <Route
                            path="/register"
                            component={getComponent(authenticated, true, RegisterPage)}
                            exact={true}
                        />
                        <Route path="/token/:token" component={TokenPage} />
                        <Route
                            path="/monitor/:monitorId"
                            component={getComponent(authenticated, registered, MonitorPage)}
                        />
                        <Route
                            path="/newmonitor/:companyId"
                            component={getComponent(authenticated, registered, MonitorPage)}
                            exact={true}
                        />
                        <Route
                            path="/company/:companyId"
                            component={getComponent(authenticated, registered, CompanyPage)}
                        />
                        <Route
                            path="/company"
                            component={getComponent(authenticated, registered, CompanyPage)}
                            exact={true}
                        />
                        <Route
                            path="/profile/:url?"
                            component={getComponent(authenticated, registered, ProfilePage)}
                            exact={true}
                        />
                        <Route
                            path="/home"
                            component={getComponent(authenticated, registered, HomePage)}
                            exact={true}
                        />
                        <Route
                            path="/home-list"
                            component={getComponent(authenticated, registered, HomeListPage)}
                            exact={true}
                        />
                        <Route
                            path="/sites"
                            component={getComponent(authenticated, registered, SitesPage)}
                            exact={true}
                        />
                        <Route
                            path="/sites/:companyId"
                            component={getComponent(authenticated, registered, SitesPage)}
                            exact={false}
                        />
                        <Route
                            path="/sites-list"
                            component={getComponent(authenticated, registered, SitesListPage)}
                            exact={true}
                        />
                        <Route
                            path="/sites-list/:companyId"
                            component={getComponent(authenticated, registered, SitesListPage)}
                            exact={false}
                        />
                        <Route
                            path="/site-details/:monitorId"
                            component={getComponent(authenticated, registered, SiteDetailsPage)}
                            exact={false}
                        />
                        <Route
                            path="/"
                            component={(): ReactElement => <Redirect to={savedPath} />}
                            exact={true}
                        />
                    </IonRouterOutlet>
                    <Alerts />
                </IonSplitPane>
            </IonReactHashRouter>
        </IonApp>
    );
};

export default App;
