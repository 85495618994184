/* eslint-disable @typescript-eslint/no-use-before-define */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IAlertState } from '../state/IAlertState';

const initialState: IAlertState = {
    open: false,
    header: '',
    message: '',
};

const slice = createSlice({
    name: 'ALERT',
    initialState,
    reducers: {
        setAlert: (
            state: IAlertState,
            action: PayloadAction<{ header: string; message: string }>,
        ): IAlertState => {
            return {
                ...action.payload,
                open: true,
            };
        },
        dismissAlert: (state: IAlertState): IAlertState => {
            return {
                ...state,
                open: false,
                header: '',
                message: '',
            };
        },
    },
});

export const { setAlert, dismissAlert } = slice.actions;

const alertReducer = slice.reducer;
export { alertReducer };
