/* eslint-disable react/prop-types */
import {
    IonButtons,
    IonContent,
    IonHeader,
    IonMenuButton,
    IonPage,
    IonTitle,
    IonToolbar,
    IonLoading,
} from '@ionic/react';
import React, { useEffect } from 'react';
import './Token.scss';
import { RouteComponentProps, withRouter } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { processToken } from '../../store/reducers/authReducer';
import { AppDispatch } from '../../store/store';
import { RootState } from '../../store';

const TokenPage: React.FC<RouteComponentProps<{ token: string }>> = ({ match, history }) => {
    const dispatch = useDispatch<AppDispatch>();
    const savedPath = useSelector((state: RootState) => state.clientSettings.savedPath);

    useEffect(() => {
        const tokenParts = match.params.token.split('|');
        dispatch(processToken(tokenParts[0], tokenParts[1]))
            .then(() => {
                history.push(savedPath);
            })
            .catch(e => {
                alert(`Error processing token: ${e.message || e}`);
            });
    }, [dispatch, history, match.params.token, savedPath]);

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonMenuButton />
                    </IonButtons>
                    <IonTitle>Login</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonLoading message="Signing in ..." isOpen={true} />
            </IonContent>
        </IonPage>
    );
};

const tokenPage = withRouter(TokenPage);

export { tokenPage as TokenPage };
