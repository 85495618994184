/* eslint-disable react/prop-types */
/* eslint-disable no-constant-condition */
/* eslint-disable @typescript-eslint/no-use-before-define */
import {
    IonButtons,
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonLoading,
    IonSelect,
    IonSelectOption,
    IonBackButton,
    IonButton,
    IonIcon,
} from '@ionic/react';
import React, { useEffect, useState } from 'react';
import './SiteDetails.scss';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../store';
import { withRouter, RouteComponentProps, useRouteMatch } from 'react-router-dom';

import { LogoutButton } from '../../components/LogoutButton';
import { AppDispatch } from '../../store/store';
import { getMonitorDetails, getMonitorLastMonth } from '../../store/reducers/monitorDataReducer';
import { Chart } from 'react-google-charts';
import moment from 'moment';
import { SelectChangeEventDetail } from '@ionic/core';
import { build, closeCircle } from 'ionicons/icons';
import { TrainingDialog } from '../../components/TrainingDialog';
import { cancelTraining } from '../../store/reducers/monitorReducer';

const BG_COLOR = '#222222';
const FG_COLOR = '#d7d8da';

const SiteDetailsPage: React.FC<RouteComponentProps> = () => {
    const match = useRouteMatch<{ monitorId?: string }>();
    const dispatch = useDispatch<AppDispatch>();

    const listView = useSelector((state: RootState) => state.clientSettings.listView);
    const monitor = useSelector((state: RootState) => {
        const monitorId = parseInt(match.params.monitorId || '0');
        if (monitorId === 0 || isNaN(monitorId)) {
            return undefined;
        }
        return state.monitor.monitors.find(m => m.monitorId === monitorId);
    });
    const detailsData = useSelector((state: RootState) => state.monitorData.details);
    const { training, progress } = useSelector((state: RootState) => {
        const monitorId = parseInt(match.params.monitorId || '0');
        if (monitorId === 0) {
            return {
                training: false,
                progress: 0,
            };
        } else {
            const trainingState = state.monitor.trainingState[monitorId];
            return trainingState || { training: false, progress: 0 };
        }
    });

    const [loading, setLoading] = useState(true);
    const [view, setView] = useState('day');
    const [trainingDialogOpen, setTrainingDialogOpen] = useState(false);

    useEffect(() => {
        if (monitor) {
            dispatch(
                getMonitorDetails(
                    monitor.monitorId,
                    moment()
                        .subtract(1, 'day')
                        .toDate(),
                ),
            ).then(() => {
                setLoading(false);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [monitor]);

    const toggleTraining = (): void => {
        if (!training) {
            setTrainingDialogOpen(true);
        } else if (monitor) {
            dispatch(cancelTraining(monitor.monitorId));
        }
    };

    const onChartSelect = (event: CustomEvent<SelectChangeEventDetail>): void => {
        setView(event.detail.value);
        setLoading(true);
        switch (event.detail.value) {
            case 'day': {
                if (monitor) {
                    dispatch(
                        getMonitorDetails(
                            monitor.monitorId,
                            moment()
                                .subtract(1, 'day')
                                .toDate(),
                        ),
                    ).then(() => {
                        setLoading(false);
                    });
                }
                break;
            }
            case '3days': {
                if (monitor) {
                    dispatch(
                        getMonitorDetails(
                            monitor.monitorId,
                            moment()
                                .subtract(3, 'days')
                                .toDate(),
                        ),
                    ).then(() => {
                        setLoading(false);
                    });
                }
                break;
            }
            case 'week': {
                if (monitor) {
                    dispatch(
                        getMonitorDetails(
                            monitor.monitorId,
                            moment()
                                .subtract(1, 'week')
                                .toDate(),
                        ),
                    ).then(() => {
                        setLoading(false);
                    });
                }
                break;
            }
            case '2week': {
                if (monitor) {
                    dispatch(
                        getMonitorDetails(
                            monitor.monitorId,
                            moment()
                                .subtract(2, 'week')
                                .toDate(),
                        ),
                    ).then(() => {
                        setLoading(false);
                    });
                }
                break;
            }
            case 'month': {
                if (monitor) {
                    dispatch(getMonitorLastMonth(monitor.monitorId)).then(() => {
                        setLoading(false);
                    });
                }
                break;
            }
        }
    };

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref={`/sites${listView ? '-list' : ''}`} text="" />
                    </IonButtons>
                    <IonTitle>{monitor ? monitor.monitorName : ''}</IonTitle>
                    <LogoutButton />
                </IonToolbar>
            </IonHeader>
            <IonContent scrollX={false} scrollY={false}>
                {loading ? (
                    <IonLoading isOpen={loading} />
                ) : (
                    <div className="chart-container">
                        <div className="chart-container-selector">
                            <IonSelect
                                interface="popover"
                                value={view}
                                onIonChange={onChartSelect}
                                disabled={loading}
                            >
                                <IonSelectOption
                                    value="day"
                                    defaultChecked={view === 'day'}
                                    key="day"
                                >
                                    Last 24 Hours
                                </IonSelectOption>
                                <IonSelectOption
                                    value="3days"
                                    defaultChecked={view === '3days'}
                                    key="3days"
                                >
                                    Last 3 days
                                </IonSelectOption>
                                <IonSelectOption
                                    value="week"
                                    defaultChecked={view === 'week'}
                                    key="week"
                                >
                                    Last Week
                                </IonSelectOption>
                                <IonSelectOption
                                    value="2week"
                                    defaultChecked={view === '2week'}
                                    key="2week"
                                >
                                    Last 2 Weeks
                                </IonSelectOption>
                                <IonSelectOption
                                    value="month"
                                    defaultChecked={view === 'month'}
                                    key="month"
                                >
                                    Last 30 Days
                                </IonSelectOption>
                            </IonSelect>
                            <IonButton
                                title={training ? 'Cancel' : 'Train model'}
                                onClick={(): void => toggleTraining()}
                                size="small"
                                fill="clear"
                            >
                                {training ? (
                                    <React.Fragment>
                                        <IonIcon slot="start" icon={closeCircle} />
                                        Cancel ({Math.round(progress * 100)}%)
                                    </React.Fragment>
                                ) : (
                                    <React.Fragment>
                                        <IonIcon slot="start" icon={build} />
                                    </React.Fragment>
                                )}
                            </IonButton>
                        </div>
                        {detailsData.length > 0 ? (
                            <Chart
                                width="105%"
                                height="100%"
                                className="chart-container-chart"
                                chartType="LineChart"
                                loader={<IonLoading isOpen={true} />}
                                data={[
                                    [
                                        'x',
                                        'kWh',
                                        ...(view !== 'month' ? ['prediction', 'red', 'green'] : []),
                                    ],
                                    ...detailsData.map(value => [
                                        `${moment(value.date).format('M/D h:mm A')}`,
                                        value.value / 1000,
                                        ...(view !== 'month'
                                            ? [
                                                  value.estimate / 1000,
                                                  monitor ? monitor.red / 1000 : 0,
                                                  monitor ? monitor.green / 1000 : 0,
                                              ]
                                            : []),
                                    ]),
                                ]}
                                options={{
                                    hAxis: {
                                        title: 'Time',
                                        textStyle: {
                                            color: FG_COLOR,
                                        },
                                        titleTextStyle: {
                                            color: FG_COLOR,
                                        },
                                    },
                                    vAxis: {
                                        title: 'kWh',
                                        baselineColor: FG_COLOR,
                                        textStyle: {
                                            color: FG_COLOR,
                                        },
                                        gridlines: {
                                            color: FG_COLOR,
                                        },
                                        titleTextStyle: {
                                            color: FG_COLOR,
                                        },
                                    },
                                    series: {
                                        0: {
                                            curveType: 'function',
                                            color: 'blue',
                                        },
                                        ...(view !== 'month'
                                            ? {
                                                  1: {
                                                      curveType: 'function',
                                                      color: 'orange',
                                                  },
                                                  2: {
                                                      color: 'darkred',
                                                  },
                                                  3: {
                                                      color: 'green',
                                                  },
                                              }
                                            : {}),
                                    },
                                    legend: {
                                        position: 'none',
                                    },
                                    backgroundColor: BG_COLOR,
                                    is3D: true,
                                    // colors:
                                }}
                                rootProps={{ 'data-testid': '1' }}
                            />
                        ) : (
                            <div className="no-data-label">
                                No data found for the specified time period.
                            </div>
                        )}
                    </div>
                )}
                <TrainingDialog
                    monitorId={match.params.monitorId ? parseInt(match.params.monitorId) : 0}
                    isOpen={trainingDialogOpen}
                    close={(): void => setTrainingDialogOpen(false)}
                />
            </IonContent>
        </IonPage>
    );
};

const siteDetailsPage = withRouter(SiteDetailsPage);

export { siteDetailsPage as SiteDetailsPage };
