import React from 'react';

import { IonButton, IonIcon, IonButtons } from '@ionic/react';

export const ButtonSpacer: React.FC<{ slot: string }> = (props: { slot: string }) => {
    return (
        <React.Fragment>
            <IonButtons slot={props.slot}>
                <IonButton>
                    <IonIcon />
                </IonButton>
            </IonButtons>
        </React.Fragment>
    );
};
