import { combineReducers, CombinedState } from 'redux';
import { authReducer } from './reducers/authReducer';
import { companyReducer } from './reducers/companyReducer';
import { locationReducer } from './reducers/locationReducer';
import { monitorReducer } from './reducers/monitorReducer';
import { monitorDataReducer } from './reducers/monitorDataReducer';
import { weatherDataReducer } from './reducers/weatherDataReducer';
import { alertReducer } from './reducers/alertReducer';
import { notificationReducer } from './reducers/notificationReducer';
import { clientSettingsReducer } from './reducers/clientSettingsReducer';
import { IAuthState } from './state/IAuthState';
import { ICompanyState } from './state/ICompanyState';
import { ILocationState } from './state/ILocationState';
import { IMonitorState } from './state/IMonitorState';
import { IMonitorDataState } from './state/IMonitorDataState';
import { IWeatherState } from './state/IWeatherState';
import { IAlertState } from './state/IAlertState';
import { INotificationState } from './state/INotificationState';
import { IClientSettingsState } from './state/IClientSettingsState';

export const rootReducer = combineReducers<RootState>({
    auth: authReducer,
    company: companyReducer,
    location: locationReducer,
    monitor: monitorReducer,
    monitorData: monitorDataReducer,
    weatherData: weatherDataReducer,
    alert: alertReducer,
    notification: notificationReducer,
    clientSettings: clientSettingsReducer,
});

export type RootState = CombinedState<{
    auth: IAuthState;
    company: ICompanyState;
    location: ILocationState;
    monitor: IMonitorState;
    monitorData: IMonitorDataState;
    weatherData: IWeatherState;
    alert: IAlertState;
    notification: INotificationState;
    clientSettings: IClientSettingsState;
}>;
