import React from 'react';
import { ISigninButtonProps } from '../models/ISigninButtonProps';

import './GoogleSigninButton.scss';

export const GoogleSigninButton: React.FC<ISigninButtonProps> = (props: ISigninButtonProps) => {
    return (
        <span onClick={props.onClick}>
            <div className="google-signin">
                <span className="google-signin-icon" />
                <span className="google-signin-text">Sign in with Google</span>
            </div>
        </span>
    );
};
