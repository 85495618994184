/* eslint-disable no-constant-condition */
/* eslint-disable @typescript-eslint/no-use-before-define */
import {
    IonButtons,
    IonContent,
    IonHeader,
    IonMenuButton,
    IonPage,
    IonTitle,
    IonToolbar,
    IonRow,
    IonGrid,
    IonCol,
    IonCard,
    IonCardSubtitle,
    IonCardHeader,
    IonCardContent,
    IonRouterLink,
    IonIcon,
    IonPopover,
    IonLoading,
    IonSpinner,
    useIonViewDidEnter,
} from '@ionic/react';
import React, { useEffect, useState, useCallback } from 'react';
import './Home.scss';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../store';
import { IGridProps } from '../../models/IGridProps';
import { withRouter, useHistory } from 'react-router-dom';
import SwipeableViews from 'react-swipeable-views';
import { virtualize } from 'react-swipeable-views-utils';
import WeatherIcon from 'react-icons-weather';
import { RadialGauge } from '../../components/RadialGauge';
import { LogoutButton } from '../../components/LogoutButton';
import { arrowForward, warning } from 'ionicons/icons';
import { storeSavedPath } from '../../store/reducers/clientSettingsReducer';
import { AppDispatch } from '../../store/store';

const VirtualizeSwipeableViews = virtualize(SwipeableViews);

const STEP_CONST = 25;
const UNIT_DIVIDER = 1000;
const ION_COLS = 12;
const HEADER_SIZE = 102;
const TEMPERATURE_UNITS = 'C';

const HomePage: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const history = useHistory();

    const companies = useSelector((state: RootState) => state.company.companies);
    const weatherData = useSelector((state: RootState) => state.weatherData.data);
    const notifications = useSelector((state: RootState) => state.notification.notifications);
    const initialized = useSelector(
        (state: RootState) =>
            state.monitorData.initialized &&
            state.company.initialized &&
            state.location.initialized &&
            state.monitor.initialized,
    );

    const [loading, setLoading] = useState(true);

    useIonViewDidEnter(() => {
        dispatch(storeSavedPath('/home'));
    });

    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 15000);
    }, []);

    const [tooltipState, setTooltipState] = useState({
        open: false,
    } as { open: boolean; message: string; event?: Event; timeout?: NodeJS.Timeout });

    const getNotificationLevel = (monitorId: number): string => {
        const notices = notifications.filter(n => n.monitorId === monitorId);
        return notices.reduce((p, c) => {
            if (p === 'ALERT') {
                return p;
            } else if (c.event === 'WARNING') {
                return 'WARNING';
            } else {
                return 'ALERT';
            }
        }, '');
    };

    const data = useSelector((state: RootState) => {
        return state.company.companies
            .slice()
            .sort((a, b) => a.companyName.localeCompare(b.companyName))
            .map(c => {
                const item = {
                    monitorId: c.companyId,
                    ...state.monitorData.data
                        .filter(d => {
                            const m = state.monitor.monitors.find(m => m.monitorId === d.monitorId);
                            return m ? m.companyId === c.companyId : false;
                        })
                        .reduce(
                            (previous, data) => ({
                                label: previous.label,
                                dateRecorded:
                                    (previous.dateRecorded.getFullYear &&
                                        previous.dateRecorded.getFullYear() === 1970) ||
                                    previous.dateRecorded.valueOf() < data.dateRecorded.valueOf()
                                        ? data.dateRecorded
                                        : previous.dateRecorded,
                                current: previous.current + data.current,
                                average: previous.average + data.average,
                                today: previous.today + data.today,
                                monthToDate: previous.monthToDate + data.monthToDate,
                                projected: previous.projected + data.projected,
                                voltage: previous.voltage + data.voltage,
                            }),
                            {
                                label: c.companyName,
                                dateRecorded: new Date(1970, 1, 1),
                                current: 0,
                                average: 0,
                                today: 0,
                                monthToDate: 0,
                                projected: 0,
                                voltage: 0,
                            },
                        ),
                    ...state.monitor.monitors
                        .filter(m => m.companyId === c.companyId)
                        .reduce(
                            (previous, monitor, index) => {
                                const predictions =
                                    state.monitorData.predictions[monitor.monitorId];
                                return {
                                    red: previous.red + monitor.red,
                                    green: previous.green + monitor.green,
                                    max: previous.max + monitor.max,
                                    locationId:
                                        previous.locationId < 0
                                            ? monitor.locationId
                                            : previous.locationId,
                                    count: index + 1,
                                    notification:
                                        previous.notification === 'ALERT'
                                            ? 'ALERT'
                                            : getNotificationLevel(monitor.monitorId),
                                    prediction:
                                        predictions && predictions.length > 0
                                            ? (previous.prediction !== undefined
                                                  ? previous.prediction
                                                  : 0) +
                                              predictions.reduce((sum, value) => sum + value, 0) /
                                                  predictions.length
                                            : previous.prediction,
                                };
                            },
                            {
                                red: 0,
                                green: 0,
                                max: 0,
                                locationId: -1,
                                count: 0,
                                notification: '',
                                prediction: undefined as number | undefined,
                            },
                        ),
                };
                return item;
            })
            .filter(item => item.count > 0);
    });

    const [gridProps, setGridProps] = useState({
        rows: 2,
        columns: 2,
        itemCount: 0,
        slideCount: 0,
        currentPage: 0,
        itemSize: 200,
    } as IGridProps);

    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });

    const getGridProps = useCallback(
        () => {
            if (!data) {
                return gridProps;
            }

            const itemCount: number = data.length;

            let rows = 2;
            if (window.innerHeight < 500) {
                rows = 1;
            } else if (window.innerHeight < 800) {
                rows = 2;
            } else {
                rows = 3;
            }

            const columns: number = Math.min(
                Math.floor(window.innerWidth / 200),
                Math.ceil(itemCount / rows),
            );

            let itemSize: number = Math.floor(window.innerWidth / columns);
            if (itemSize > (window.innerHeight - HEADER_SIZE) / rows) {
                itemSize = (window.innerHeight - HEADER_SIZE) / rows;
            }

            const slideCount =
                columns > 0 && rows > 0 ? Math.ceil(itemCount / (columns * rows)) : 0;

            const newGridProps = {
                rows,
                columns,
                itemCount,
                slideCount,
                currentPage:
                    gridProps.currentPage < slideCount
                        ? gridProps.currentPage
                        : slideCount > 0
                        ? slideCount - 1
                        : 0,
                itemSize,
            };

            setGridProps(newGridProps);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [companies, data, windowSize.height, windowSize.width, initialized],
    );

    const resize = useCallback(
        (): void => {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
            getGridProps();
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [gridProps, windowSize],
    );

    useEffect(
        () => {
            window.addEventListener('resize', resize);
            resize();

            return (): void => {
                window.removeEventListener('resize', resize);
            };
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    useEffect(
        () => {
            getGridProps();
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
            companies,
            gridProps.columns,
            gridProps.rows,
            gridProps.itemSize,
            windowSize.height,
            windowSize.width,
            initialized,
        ],
    );

    const renderSlide = ({ key, index }: { key: string; index: number }): JSX.Element | void => {
        if (gridProps.slideCount === 0) {
            return <div key={key}>&nbsp;</div>;
        }

        if (index >= 0 && index < gridProps.slideCount) {
            if (index === gridProps.currentPage) {
                return <IonGrid key={key}>{getRows(index)}</IonGrid>;
            } else {
                return <div key={key}>&nbsp;</div>;
            }
        }

        return <div key={key}>&nbsp;</div>;
    };

    const getRows = (index: number): JSX.Element[] => {
        const start = index * gridProps.rows;

        const rows: JSX.Element[] = [];
        for (let i = start; i < start + gridProps.rows; i++) {
            rows.push(<IonRow key={`row${i}`}>{getCols(i, gridProps.columns)}</IonRow>);
        }
        return rows;
    };

    const getCols = (row: number, colCount: number): JSX.Element[] => {
        const actualColCount =
            (row + 1) * colCount < data.length
                ? colCount
                : Math.max(data.length - row * colCount, 0);

        const cols: JSX.Element[] = [];
        const offset: number =
            (ION_COLS - (actualColCount * Math.floor((ION_COLS / colCount) * 10)) / 10) / 2;

        for (let i = 0; i < colCount; i++) {
            if (row * colCount + i < data.length) {
                cols.push(
                    <IonCol
                        key={`col${row * colCount + i}`}
                        offset={(i === 0 ? offset : 0).toString()}
                        size={(Math.floor((ION_COLS / colCount) * 10) / 10).toString()}
                    >
                        {renderItem(row * colCount + i)}
                    </IonCol>,
                );
            } else {
                cols.push(
                    <IonCol
                        key={`col${row * colCount + i}`}
                        offset={(i === 0 ? offset : 0).toString()}
                        size={(Math.floor((ION_COLS / colCount) * 10) / 10).toString()}
                    >
                        &nbsp;
                    </IonCol>,
                );
            }
        }
        return cols;
    };

    const renderItem = (index: number): JSX.Element => {
        const item = data[index];
        const maxValue = Math.round(item.max / UNIT_DIVIDER);
        const step: number = Math.max(Math.round(maxValue / STEP_CONST) * 5, 5);
        const steps: number[] = [];
        for (let i = 0; i < maxValue; i += step) {
            steps.push(i);
        }
        if (maxValue > steps[steps.length - 1]) {
            steps.push(steps[steps.length - 1] + step);
        }

        const weather = weatherData.find(w => item.locationId === w.locationId);
        const hasData = item.dateRecorded.getFullYear() > 1970;

        const stateColor = item.prediction
            ? item.current > item.prediction * 0.8
                ? '#0ec254'
                : item.current > item.prediction * 0.6
                ? '#e0b500'
                : '#d33939'
            : '#989aa2';

        return (
            <IonCard data-tip className="gauge-container">
                <IonCardHeader>
                    <IonCardSubtitle
                    // onClick={(event): void => {
                    //     setTooltipState({
                    //         ...tooltipState,
                    //         open: true,
                    //         message: `Monitor ${item.label}`,
                    //         event: event.nativeEvent,
                    //     });
                    //     event.preventDefault();
                    // }}
                    >
                        {data[index].label}
                    </IonCardSubtitle>
                    {item.notification === 'ALERT' ? (
                        <IonIcon icon={warning} color="danger" size="small" />
                    ) : item.notification !== '' ? (
                        <IonIcon icon={warning} color="warning" size="small" />
                    ) : (
                        ''
                    )}
                </IonCardHeader>
                <IonCardContent
                    onClick={(): void => {
                        history.push({
                            pathname: `/sites/${item.monitorId}`,
                        });
                    }}
                >
                    {!hasData ? (
                        <div style={{ height: gridProps.itemSize - 42 }} className="item-loading">
                            {loading ? (
                                <IonSpinner
                                    className="item-loading-spinner"
                                    style={{
                                        height: '24px',
                                    }}
                                />
                            ) : (
                                <IonIcon icon={warning} />
                            )}
                        </div>
                    ) : (
                        <React.Fragment>
                            <RadialGauge
                                renderTo={document.body}
                                width={gridProps.itemSize - HEADER_SIZE}
                                height={gridProps.itemSize - HEADER_SIZE}
                                units="kW"
                                minValue={0}
                                maxValue={item.max}
                                value={item.current || 0}
                                valueText={
                                    isNaN(item.current)
                                        ? '-'
                                        : item.current >= UNIT_DIVIDER * 100
                                        ? Math.round(item.current / UNIT_DIVIDER).toLocaleString(
                                              'en-US',
                                          )
                                        : item.current >= UNIT_DIVIDER * 10
                                        ? (
                                              Math.round(item.current / (UNIT_DIVIDER / 10)) / 10
                                          ).toLocaleString('en-US')
                                        : (
                                              Math.round(item.current / (UNIT_DIVIDER / 100)) / 100
                                          ).toFixed(2)
                                }
                                majorTicks={steps}
                                minorTicks={2}
                                strokeTicks={true}
                                highlights={[
                                    {
                                        from: 0,
                                        to: item.red,
                                        color: 'rgba(255, 0, 0, .3)',
                                    },
                                    {
                                        from: item.red,
                                        to: item.green,
                                        color: 'rgba(255, 255, 0, .3)',
                                    },
                                    {
                                        from: item.green,
                                        to: item.max,
                                        color: 'rgba(0, 255, 0, .3)',
                                    },
                                ]}
                                ticksAngle={225}
                                startAngle={67.5}
                                colorMajorTicks="#ddd"
                                colorMinorTicks="#ddd"
                                colorTitle="#989aa2"
                                colorUnits={stateColor}
                                colorNumbers="#989aa2"
                                colorPlate="#222"
                                colorPlateEnd="#222"
                                borderShadowWidth={0}
                                borders={true}
                                needleType="arrow"
                                needleWidth={2}
                                needleCircleSize={7}
                                needleCircleOuter={true}
                                needleCircleInner={false}
                                animationDuration={1500}
                                animationRule="linear"
                                colorBorderOuter="#333"
                                colorBorderOuterEnd="#111"
                                colorBorderMiddle="#222"
                                colorBorderMiddle-end="#111"
                                colorBorderInner="#111"
                                colorBorderInnerEnd="#333"
                                colorNeedleShadowDown="#333"
                                colorNeedleCircleOuter="#333"
                                colorNeedleCircleOuterEnd="#111"
                                colorNeedleCircleInner="#111"
                                colorNeedleCircleInnerEnd="#222"
                                valueBoxBorderRadius={0}
                                fontValueWeight="bold"
                                fontValueSize={40}
                                colorValueBoxBackground="transparent"
                                colorValueBoxShadow="transparent"
                                colorValueText={stateColor}
                                colorValueBoxRect="transparent"
                                colorValueBoxRectEnd="transparent"
                            />
                            <div className="item-footer">
                                <div className="item-footer-row1">
                                    <div className="item-footer-col0" />
                                    <div className="item-footer-col1">
                                        <div>
                                            Today:{' '}
                                            {item.today
                                                ? Math.round(
                                                      item.today / UNIT_DIVIDER,
                                                  ).toLocaleString()
                                                : '-'}
                                        </div>
                                        <div>
                                            Avg:{' '}
                                            {item.average
                                                ? Math.round(
                                                      item.average / UNIT_DIVIDER,
                                                  ).toLocaleString()
                                                : '-'}
                                        </div>
                                    </div>
                                    <div className="item-footer-col2">
                                        <div>
                                            MTD:{' '}
                                            {item.monthToDate
                                                ? Math.round(
                                                      item.monthToDate / UNIT_DIVIDER,
                                                  ).toLocaleString()
                                                : '-'}
                                        </div>
                                        <div>
                                            Proj:{' '}
                                            {item.projected
                                                ? Math.round(
                                                      item.projected / UNIT_DIVIDER,
                                                  ).toLocaleString()
                                                : '-'}
                                        </div>
                                    </div>
                                    <div className="item-footer-col3">
                                        <div>
                                            {weather && weather.icon ? (
                                                <WeatherIcon
                                                    name="darksky"
                                                    iconId={
                                                        weather && weather.icon ? weather.icon : ''
                                                    }
                                                />
                                            ) : (
                                                ''
                                            )}
                                        </div>
                                        <div>
                                            {weather
                                                ? `${Math.round(
                                                      weather.temperature,
                                                  )}\xB0${TEMPERATURE_UNITS}`
                                                : ''}
                                        </div>
                                    </div>
                                    <div className="item-footer-col4" />
                                </div>
                                <div className="item-footer-row2">
                                    <div className="item-footer-date">{`${item.dateRecorded.toLocaleDateString()}, ${item.dateRecorded.toLocaleTimeString()}`}</div>
                                </div>
                            </div>
                        </React.Fragment>
                    )}
                </IonCardContent>
            </IonCard>
        );
    };

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonMenuButton />
                    </IonButtons>
                    <IonTitle>
                        {((): string => {
                            const total = data.reduce((previous, value) => {
                                return previous + (value.current || 0);
                            }, 0);
                            return `${
                                total >= 100000
                                    ? Math.round(total / 1000).toLocaleString('en-US')
                                    : total >= 10000
                                    ? (Math.round(total / 100) / 10).toFixed(1)
                                    : (Math.round(total / 10) / 100).toFixed(2)
                            } kWh`;
                        })()}
                    </IonTitle>
                    <LogoutButton />
                </IonToolbar>
            </IonHeader>
            <IonContent scrollX={false} scrollY={false}>
                {data.length === 0 ? (
                    <div className="home-content">
                        <div className="home-content-main">
                            {initialized ? (
                                <div className="home-content-main-text">
                                    Welcome to the Vision9 Solar Monitoring Solution. You currently
                                    have no active monitors that have recorded data.
                                    <br />
                                    <br />
                                    You can add companies and monitors from the &nbsp;
                                    <IonRouterLink routerLink="/profile">Profile</IonRouterLink>
                                    &nbsp; page.
                                    <br />
                                    <br />
                                    <IonRouterLink routerLink="/profile">
                                        Go to the profile page &nbsp;
                                        <IonIcon icon={arrowForward} />
                                    </IonRouterLink>
                                </div>
                            ) : (
                                <div className="home-content-main-text">
                                    <IonLoading isOpen={true} message="Loading ..." />
                                </div>
                            )}
                        </div>
                    </div>
                ) : (
                    <React.Fragment>
                        <VirtualizeSwipeableViews
                            //...itemSizeState,
                            enableMouseEvents={true}
                            slideRenderer={renderSlide}
                            slideCount={gridProps.slideCount}
                            overscanSlideAfter={1}
                            overscanSlideBefore={1}
                            onChangeIndex={(index: number): void => {
                                if (gridProps.currentPage !== index) {
                                    setGridProps({
                                        ...gridProps,
                                        currentPage: index,
                                    });
                                }
                            }}
                            index={gridProps.currentPage}
                        />
                        {gridProps.slideCount > 1 ? (
                            <div className="carousel-pager">
                                {[...Array(gridProps.slideCount)].map((value, index) => {
                                    return (
                                        <svg
                                            key={index}
                                            onClick={(): void => {
                                                if (gridProps.currentPage !== index) {
                                                    setGridProps({
                                                        ...gridProps,
                                                        currentPage: index,
                                                    });
                                                }
                                            }}
                                            height="12"
                                            width="12"
                                        >
                                            <circle
                                                cx="5"
                                                cy="5"
                                                r="4"
                                                stroke="#222428"
                                                strokeWidth="1"
                                                fill={
                                                    index === gridProps.currentPage
                                                        ? '#989aa2'
                                                        : '#f5f6f9'
                                                }
                                            />
                                        </svg>
                                    );
                                })}
                            </div>
                        ) : (
                            ''
                        )}
                    </React.Fragment>
                )}
            </IonContent>
            <IonPopover
                isOpen={tooltipState.open}
                event={tooltipState.event}
                showBackdrop={false}
                onDidDismiss={(): void => setTooltipState({ ...tooltipState, open: false })}
                cssClass="tooltip-popover"
            >
                {tooltipState.message}
            </IonPopover>
        </IonPage>
    );
};

const homePage = withRouter(HomePage);

export { homePage as HomePage };
