/* eslint-disable @typescript-eslint/no-use-before-define */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, AppDispatch } from '../store';
import { IMonitor } from 'vision9-solar-shared';
import Axios from 'axios';
import { IMonitorState } from '../state/IMonitorState';
import HttpStatusCodes from 'http-status-codes';
import { config } from '../../config';

const initialState: IMonitorState = {
    fetching: false,
    initialized: false,
    trainingState: {},
    monitors: [],
};

const generatePasscode = async (token: string, monitorId: number): Promise<string> => {
    const response = await Axios({
        baseURL: config.apiUrl,
        url: `/monitor/passcode/${monitorId}`,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
    });

    if (response.status !== HttpStatusCodes.OK) {
        throw new Error(
            response.data.message || 'An unkown error occured generating a new passcode.',
        );
    }

    return response.data.passcode;
};

const getMonitors = (): AppThunk => async (dispatch: AppDispatch): Promise<void> => {
    dispatch(getMonitorsStart());

    try {
        const response = await Axios({
            baseURL: config.apiUrl,
            url: '/monitor',
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (response.status !== HttpStatusCodes.OK) {
            throw new Error(
                response.data.message || 'An unkown error occured retrieving the list of Monitors.',
            );
        }

        dispatch(getMonitorsEnd(response.data));
    } catch (e) {
        dispatch(setError(e.message));
    }
};

const saveMonitor = (monitor: IMonitor): AppThunk => async (
    dispatch: AppDispatch,
): Promise<void> => {
    dispatch(saveMonitorStart());

    try {
        const response = await Axios({
            baseURL: config.apiUrl,
            url: '/monitor',
            method: monitor.monitorId === 0 ? 'POST' : 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            data: monitor,
        });

        if (response.status !== HttpStatusCodes.OK) {
            throw new Error(
                response.data.message ||
                    `An unkown error occured saving the monitor: ${monitor.monitorName}`,
            );
        }

        dispatch(saveMonitorEnd(response.data));
    } catch (e) {
        dispatch(setError(e.message));
    }
};

const deleteMonitor = (monitorId: number): AppThunk => async (
    dispatch: AppDispatch,
): Promise<void> => {
    dispatch(deleteMonitorStart());

    try {
        const response = await Axios({
            baseURL: config.apiUrl,
            url: `/monitor/${monitorId}`,
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (response.status !== HttpStatusCodes.OK) {
            throw new Error(
                response.data.message ||
                    `An unkown error occured deleting the monitor: ${monitorId}`,
            );
        }

        dispatch(deleteMonitorEnd(monitorId));
    } catch (e) {
        dispatch(setError(e.message));
        throw e;
    }
};

const trainMonitor = (
    monitorId: number,
    learningRate: number,
    batchSize: number,
    epochs: number,
    dateFrom: Date | undefined,
    dateTo: Date | undefined,
    reset: boolean,
): AppThunk => async (dispatch: AppDispatch): Promise<void> => {
    dispatch(trainMonitorStart(monitorId));

    try {
        const response = await Axios({
            baseURL: config.apiUrl,
            url: monitorId === 0 ? '/ml/trainAll' : `/ml/train/${monitorId}`,
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            data: {
                learningRate,
                batchSize,
                epochs,
                dateFrom,
                dateTo,
                reset,
            },
        });

        if (response.status !== HttpStatusCodes.OK) {
            throw new Error(
                response.data.message ||
                    `An unkown error occured training the monitor: ${monitorId}`,
            );
        }
    } catch (e) {
        dispatch(setError(e.message));
        throw e;
    }
};

const cancelTraining = (monitorId: number): AppThunk => async (
    dispatch: AppDispatch,
): Promise<void> => {
    dispatch(cancelTrainingStart(monitorId));

    try {
        const response = await Axios({
            baseURL: config.apiUrl,
            url: `/ml/cancel`,
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            data: {
                monitorId,
            },
        });

        if (response.status !== HttpStatusCodes.OK) {
            throw new Error(
                response.data.message ||
                    `An unkown error occured cancelling the training job for monitor: ${monitorId}`,
            );
        }

        dispatch(cancelTrainingEnd(monitorId));
    } catch (e) {
        dispatch(setError(e.message));
        throw e;
    }
};

const slice = createSlice({
    name: 'MONITOR',
    initialState,
    reducers: {
        getMonitorsStart: (state: IMonitorState): IMonitorState => {
            return {
                ...state,
                fetching: true,
                initialized: false,
                error: undefined,
                monitors: [],
            };
        },
        setError: (state: IMonitorState, action: PayloadAction<string>): IMonitorState => {
            return {
                ...state,
                fetching: false,
                initialized: false,
                error: action.payload,
            };
        },
        getMonitorsEnd: (
            state: IMonitorState,
            action: PayloadAction<IMonitor[]>,
        ): IMonitorState => {
            return {
                ...state,
                fetching: false,
                initialized: true,
                error: undefined,
                monitors: action.payload,
            };
        },
        saveMonitorStart: (state: IMonitorState): IMonitorState => {
            return {
                ...state,
                fetching: true,
                initialized: false,
                error: undefined,
            };
        },
        saveMonitorEnd: (state: IMonitorState, action: PayloadAction<IMonitor>): IMonitorState => {
            return {
                ...state,
                fetching: false,
                initialized: true,
                error: undefined,
                monitors:
                    state.monitors.length === 0
                        ? [action.payload]
                        : [
                              ...state.monitors.filter(
                                  m => m.monitorId !== action.payload.monitorId,
                              ),
                              action.payload,
                          ],
            };
        },
        deleteMonitorStart: (state: IMonitorState): IMonitorState => {
            return {
                ...state,
                fetching: true,
                error: undefined,
            };
        },
        deleteMonitorEnd: (state: IMonitorState, action: PayloadAction<number>): IMonitorState => {
            return {
                ...state,
                fetching: false,
                error: undefined,
                monitors: [...state.monitors.filter(m => m.monitorId !== action.payload)],
            };
        },
        trainMonitorStart: (state: IMonitorState, action: PayloadAction<number>): IMonitorState => {
            return {
                ...state,
                trainingState: {
                    ...state.trainingState,
                    [action.payload]: {
                        training: true,
                        progress: 0,
                    },
                },
            };
        },
        trainMonitorProgress: (
            state: IMonitorState,
            action: PayloadAction<{ monitorId: number; progress: number }>,
        ): IMonitorState => {
            return {
                ...state,
                trainingState: {
                    ...state.trainingState,
                    [action.payload.monitorId]: {
                        training: action.payload.progress !== 1,
                        progress: action.payload.progress,
                    },
                },
            };
        },
        cancelTrainingStart: (
            state: IMonitorState,
            action: PayloadAction<number>,
        ): IMonitorState => {
            return {
                ...state,
                trainingState: {
                    ...state.trainingState,
                    [action.payload]: {
                        ...state.trainingState[action.payload],
                        training: false,
                    },
                },
            };
        },
        cancelTrainingEnd: (state: IMonitorState, action: PayloadAction<number>): IMonitorState => {
            return {
                ...state,
                trainingState: {
                    ...state.trainingState,
                    [action.payload]: {
                        ...state.trainingState[action.payload],
                        training: false,
                    },
                },
            };
        },
    },
});

export { getMonitors, saveMonitor, deleteMonitor, generatePasscode, trainMonitor, cancelTraining };

export const {
    setError,
    getMonitorsStart,
    getMonitorsEnd,
    saveMonitorStart,
    saveMonitorEnd,
    deleteMonitorStart,
    deleteMonitorEnd,
    trainMonitorStart,
    trainMonitorProgress,
    cancelTrainingStart,
    cancelTrainingEnd,
} = slice.actions;

const monitorReducer = slice.reducer;
export { monitorReducer };
