/* eslint-disable react/prop-types */
/* eslint-disable no-constant-condition */
/* eslint-disable @typescript-eslint/no-use-before-define */
import {
    IonButtons,
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonRow,
    IonGrid,
    IonCol,
    IonCard,
    IonCardSubtitle,
    IonCardHeader,
    IonCardContent,
    IonIcon,
    IonRouterLink,
    IonLoading,
    IonSpinner,
    useIonViewDidEnter,
    IonBackButton,
} from '@ionic/react';
import React, { useEffect, useState, useCallback } from 'react';
import './Sites.scss';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../store';
import { IGridProps } from '../../models/IGridProps';
import { withRouter, RouteComponentProps, useRouteMatch, useHistory } from 'react-router-dom';
import SwipeableViews from 'react-swipeable-views';
import { virtualize } from 'react-swipeable-views-utils';
import ReactTooltip from 'react-tooltip';
import WeatherIcon from 'react-icons-weather';

import { RadialGauge } from '../../components/RadialGauge';
import { IMonitorData } from 'vision9-solar-shared';
import { warning, arrowForward } from 'ionicons/icons';
import { LogoutButton } from '../../components/LogoutButton';
import { AppDispatch } from '../../store/store';
import { storeSavedPath } from '../../store/reducers/clientSettingsReducer';

const VirtualizeSwipeableViews = virtualize(SwipeableViews);

const STEP_CONST = 25;
const UNIT_DIVIDER = 1000;
const ION_COLS = 12;
const HEADER_SIZE = 102;
const TEMPERATURE_UNITS = 'C';

const SitesPage: React.FC<RouteComponentProps> = () => {
    const match = useRouteMatch<{ companyId?: string }>();
    const history = useHistory();
    const dispatch = useDispatch<AppDispatch>();

    const weatherData = useSelector((state: RootState) => state.weatherData.data);
    const notifications = useSelector((state: RootState) => state.notification.notifications);
    const initialized = useSelector(
        (state: RootState) =>
            state.monitorData.initialized &&
            state.company.initialized &&
            state.location.initialized &&
            state.monitor.initialized,
    );

    const companyName = useSelector((state: RootState) => {
        const companyId = match.params.companyId ? parseInt(match.params.companyId) : NaN;

        if (isNaN(companyId)) {
            return '';
        } else {
            const company = state.company.companies.find(c => c.companyId === companyId);
            if (company) {
                return company.companyName;
            } else {
                return '';
            }
        }
    });

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 15000);
    }, []);

    useIonViewDidEnter(() => {
        dispatch(
            storeSavedPath(`/sites${match.params.companyId ? `/${match.params.companyId}` : ''}`),
        );
    });

    const getNotificationLevel = (monitorId: number): string => {
        const notices = notifications.filter(n => n.monitorId === monitorId);
        return notices.reduce((p, c) => {
            if (p === 'ALERT') {
                return p;
            } else if (c.event === 'WARNING') {
                return 'WARNING';
            } else {
                return 'ALERT';
            }
        }, '');
    };

    const data = useSelector((state: RootState) => {
        return state.monitor.monitors
            .filter(monitor =>
                match.params.companyId && !isNaN(parseInt(match.params.companyId))
                    ? parseInt(match.params.companyId) === monitor.companyId
                    : true,
            )
            .sort((a, b) => a.monitorName.localeCompare(b.monitorName))
            .map(monitor => {
                const predictions = state.monitorData.predictions[monitor.monitorId];
                const item = {
                    ...monitor,
                    ...(state.monitorData.data.find(
                        data => data.monitorId === monitor.monitorId,
                    ) as IMonitorData),
                    notification: getNotificationLevel(monitor.monitorId),
                    prediction:
                        predictions && predictions.length > 0
                            ? predictions.reduce((sum, value) => sum + value, 0) /
                              predictions.length
                            : undefined,
                };
                return item;
            });
    });

    const [gridProps, setGridProps] = useState({
        rows: 2,
        columns: 2,
        itemCount: 0,
        slideCount: 0,
        currentPage: 0,
        itemSize: 200,
    } as IGridProps);

    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });

    const getGridProps = useCallback(
        () => {
            if (!data) {
                return gridProps;
            }

            const itemCount: number = data.length;

            let rows = 2;
            if (window.innerHeight < 500) {
                rows = 1;
            } else if (window.innerHeight < 800) {
                rows = 2;
            } else {
                rows = 3;
            }

            const columns: number = Math.min(
                Math.floor(window.innerWidth / 200),
                Math.ceil(itemCount / rows),
            );

            let itemSize: number = Math.floor(window.innerWidth / columns);
            if (itemSize > (window.innerHeight - HEADER_SIZE) / rows) {
                itemSize = (window.innerHeight - HEADER_SIZE) / rows;
            }

            const slideCount =
                columns > 0 && rows > 0 ? Math.ceil(itemCount / (columns * rows)) : 0;

            const newGridProps = {
                rows,
                columns,
                itemCount,
                slideCount,
                currentPage:
                    gridProps.currentPage < slideCount
                        ? gridProps.currentPage
                        : slideCount > 0
                        ? slideCount - 1
                        : 0,
                itemSize,
            };

            setGridProps(newGridProps);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [data, windowSize.height, windowSize.width],
    );

    const resize = useCallback(
        (): void => {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
            getGridProps();
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [gridProps, windowSize],
    );

    useEffect(
        () => {
            window.addEventListener('resize', resize);
            return (): void => {
                window.removeEventListener('resize', resize);
            };
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    useEffect(
        (): void => {
            getGridProps();
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
            data.length,
            gridProps.columns,
            gridProps.rows,
            gridProps.itemSize,
            windowSize.height,
            windowSize.width,
        ],
    );

    const renderSlide = ({ key, index }: { key: string; index: number }): JSX.Element | void => {
        if (gridProps.slideCount === 0) {
            return <div key={key}>&nbsp;</div>;
        }

        if (index >= 0 && index < gridProps.slideCount) {
            if (index === gridProps.currentPage) {
                return <IonGrid key={key}>{getRows(index)}</IonGrid>;
            } else {
                return <div key={key}>&nbsp;</div>;
            }
        }

        return <div key={key}>&nbsp;</div>;
    };

    const getRows = (index: number): JSX.Element[] => {
        const start = index * gridProps.rows;

        const rows: JSX.Element[] = [];
        for (let i = start; i < start + gridProps.rows; i++) {
            rows.push(
                <IonRow key={`row${i}`} style={{ height: gridProps.itemSize }}>
                    {getCols(i, gridProps.columns)}
                </IonRow>,
            );
        }
        return rows;
    };

    const getCols = (row: number, colCount: number): JSX.Element[] => {
        const actualColCount =
            (row + 1) * colCount < data.length
                ? colCount
                : Math.max(data.length - row * colCount, 0);

        const cols: JSX.Element[] = [];
        const offset: number =
            (ION_COLS - (actualColCount * Math.floor((ION_COLS / colCount) * 10)) / 10) / 2;

        for (let i = 0; i < colCount; i++) {
            if (row * colCount + i < data.length) {
                cols.push(
                    <IonCol
                        key={`col${row * colCount + i}`}
                        offset={(i === 0 ? offset : 0).toString()}
                        size={(Math.floor((ION_COLS / colCount) * 10) / 10).toString()}
                    >
                        {renderItem(row * colCount + i)}
                    </IonCol>,
                );
            } else {
                cols.push(
                    <IonCol
                        key={`col${row * colCount + i}`}
                        offset={(i === 0 ? offset : 0).toString()}
                        size={(Math.floor((ION_COLS / colCount) * 10) / 10).toString()}
                    >
                        &nbsp;
                    </IonCol>,
                );
            }
        }
        return cols;
    };

    const renderItem = (index: number): JSX.Element => {
        const item = data[index];
        const maxValue = Math.round(item.max / UNIT_DIVIDER);
        const step: number = Math.max(Math.round(maxValue / STEP_CONST) * 5, 5);
        const steps: number[] = [];
        for (let i = 0; i < maxValue; i += step) {
            steps.push(i);
        }
        if (maxValue > steps[steps.length - 1]) {
            steps.push(steps[steps.length - 1] + step);
        }

        const weather = weatherData.find(w => item.locationId === w.locationId);
        const hasData = item.dateRecorded !== undefined;
        const stateColor = item.prediction
            ? item.current > item.prediction * 0.8
                ? '#0ec254'
                : item.current > item.prediction * 0.6
                ? '#e0b500'
                : '#d33939'
            : '#989aa2';

        return (
            <IonCard
                // onClick={(): void => {}}
                className="gauge-container"
            >
                <IonCardHeader>
                    <IonCardSubtitle>{item.monitorName}</IonCardSubtitle>
                    {item.notification === 'ALERT' ? (
                        <IonIcon icon={warning} color="danger" size="small" />
                    ) : item.notification !== '' ? (
                        <IonIcon icon={warning} color="warning" size="small" />
                    ) : (
                        ''
                    )}
                </IonCardHeader>
                <IonCardContent
                    onClick={(): void => {
                        history.push({
                            pathname: `/site-details/${item.monitorId}`,
                        });
                    }}
                >
                    {!hasData ? (
                        <div style={{ height: gridProps.itemSize - 42 }} className="item-loading">
                            {loading ? (
                                <IonSpinner
                                    className="item-loading-spinner"
                                    style={{
                                        height: '24px',
                                    }}
                                />
                            ) : (
                                <IonIcon icon={warning} />
                            )}
                        </div>
                    ) : (
                        <React.Fragment>
                            <RadialGauge
                                renderTo={document.body}
                                width={gridProps.itemSize - HEADER_SIZE}
                                height={gridProps.itemSize - HEADER_SIZE}
                                units="kW"
                                minValue={0}
                                maxValue={item.max}
                                value={item.current || 0}
                                valueText={
                                    isNaN(item.current)
                                        ? '-'
                                        : item.current >= UNIT_DIVIDER * 100
                                        ? Math.round(item.current / UNIT_DIVIDER).toLocaleString(
                                              'en-US',
                                          )
                                        : item.current >= UNIT_DIVIDER * 10
                                        ? (
                                              Math.round(item.current / (UNIT_DIVIDER / 10)) / 10
                                          ).toLocaleString('en-US')
                                        : (
                                              Math.round(item.current / (UNIT_DIVIDER / 100)) / 100
                                          ).toFixed(2)
                                }
                                majorTicks={steps}
                                minorTicks={2}
                                strokeTicks={true}
                                highlights={[
                                    {
                                        from: 0,
                                        to: item.red,
                                        color: 'rgba(255, 0, 0, .3)',
                                    },
                                    {
                                        from: item.red,
                                        to: item.green,
                                        color: 'rgba(255, 255, 0, .3)',
                                    },
                                    {
                                        from: item.green,
                                        to: item.max,
                                        color: 'rgba(0, 255, 0, .3)',
                                    },
                                ]}
                                ticksAngle={225}
                                startAngle={67.5}
                                colorMajorTicks="#ddd"
                                colorMinorTicks="#ddd"
                                colorTitle="#989aa2"
                                colorUnits={stateColor}
                                colorNumbers="#989aa2"
                                colorPlate="#222"
                                colorPlateEnd="#222"
                                // colorPlateEnd={
                                //     addMinutes(item.lastUpdated, 10) < now ||
                                //     !!!item.current
                                //         ? '#C20'
                                //         : addMinutes(item.lastUpdated, 5) < now
                                //         ? '#B50'
                                //         : addMinutes(item.lastUpdated, 2) < now
                                //         ? '#770'
                                //         : '#222'
                                // }
                                borderShadowWidth={0}
                                borders={true}
                                needleType="arrow"
                                needleWidth={2}
                                needleCircleSize={7}
                                needleCircleOuter={true}
                                needleCircleInner={false}
                                animationDuration={1500}
                                animationRule="linear"
                                colorBorderOuter="#333"
                                colorBorderOuterEnd="#111"
                                colorBorderMiddle="#222"
                                colorBorderMiddle-end="#111"
                                colorBorderInner="#111"
                                colorBorderInnerEnd="#333"
                                colorNeedleShadowDown="#333"
                                colorNeedleCircleOuter="#333"
                                colorNeedleCircleOuterEnd="#111"
                                colorNeedleCircleInner="#111"
                                colorNeedleCircleInnerEnd="#222"
                                valueBoxBorderRadius={0}
                                fontValueWeight="bold"
                                fontValueSize={40}
                                colorValueBoxBackground="transparent"
                                colorValueBoxShadow="transparent"
                                colorValueText={stateColor}
                                colorValueBoxRect="transparent"
                                colorValueBoxRectEnd="transparent"
                            />
                            <div className="item-footer">
                                <div className="item-footer-row1">
                                    <div className="item-footer-col0" />
                                    <div className="item-footer-col1">
                                        <div>
                                            Today:{' '}
                                            {item.today
                                                ? Math.round(
                                                      item.today / UNIT_DIVIDER,
                                                  ).toLocaleString()
                                                : '-'}
                                        </div>
                                        <div>
                                            Avg:{' '}
                                            {item.average
                                                ? Math.round(
                                                      item.average / UNIT_DIVIDER,
                                                  ).toLocaleString()
                                                : '-'}
                                        </div>
                                    </div>
                                    <div className="item-footer-col2">
                                        <div>
                                            MTD:{' '}
                                            {item.monthToDate
                                                ? Math.round(
                                                      item.monthToDate / UNIT_DIVIDER,
                                                  ).toLocaleString()
                                                : '-'}
                                        </div>
                                        <div>
                                            Proj:{' '}
                                            {item.projected
                                                ? Math.round(
                                                      item.projected / UNIT_DIVIDER,
                                                  ).toLocaleString()
                                                : '-'}
                                        </div>
                                    </div>
                                    <div className="item-footer-col3">
                                        <div>
                                            {weather && weather.icon ? (
                                                <WeatherIcon
                                                    name="darksky"
                                                    iconId={
                                                        weather && weather.icon ? weather.icon : ''
                                                    }
                                                />
                                            ) : (
                                                ''
                                            )}
                                        </div>
                                        <div>
                                            {weather
                                                ? `${Math.round(
                                                      weather.temperature,
                                                  )}\xB0${TEMPERATURE_UNITS}`
                                                : ''}
                                        </div>
                                    </div>
                                    <div className="item-footer-col4" />
                                </div>
                                <div className="item-footer-row2">
                                    <div className="item-footer-date">
                                        {item.dateRecorded ? (
                                            `${item.dateRecorded.toLocaleDateString()}, ${item.dateRecorded.toLocaleTimeString()}`
                                        ) : (
                                            <b className="no-data">NO DATA</b>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>
                    )}
                </IonCardContent>
                <ReactTooltip type="light">
                    <span>
                        {item.dateRecorded
                            ? `Last updated: ${item.dateRecorded.toLocaleTimeString()} (${item.dateRecorded.toLocaleDateString()})`
                            : ''}
                    </span>
                </ReactTooltip>
            </IonCard>
        );
    };

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton
                            defaultHref={
                                match.params.companyId && !isNaN(parseInt(match.params.companyId))
                                    ? '/sites-list'
                                    : '/home-list'
                            }
                            text=""
                        />
                    </IonButtons>
                    <IonTitle>
                        {((): string => {
                            const total = data.reduce((previous, value) => {
                                return previous + (value.current || 0);
                            }, 0);

                            return `${
                                total >= 100000
                                    ? Math.round(total / 1000).toLocaleString('en-US')
                                    : total >= 10000
                                    ? (Math.round(total / 100) / 10).toFixed(1)
                                    : (Math.round(total / 10) / 100).toFixed(2)
                            } kWh${companyName.length > 0 ? ` (${companyName})` : ''}`;
                        })()}
                    </IonTitle>
                    <LogoutButton />
                </IonToolbar>
            </IonHeader>
            <IonContent scrollX={false} scrollY={false}>
                {data.length === 0 ? (
                    <div className="home-content">
                        <div className="home-content-main">
                            {initialized ? (
                                <div className="home-content-main-text">
                                    Welcome to the Vision9 Solar Monitoring Solution. You currently
                                    have no active monitors that have recorded data.
                                    <br />
                                    <br />
                                    You can add companies and monitors from the &nbsp;
                                    <IonRouterLink routerLink="/profile">Profile</IonRouterLink>
                                    &nbsp; page.
                                    <br />
                                    <br />
                                    <IonRouterLink routerLink="/profile">
                                        Go to the profile page &nbsp;
                                        <IonIcon icon={arrowForward} />
                                    </IonRouterLink>
                                </div>
                            ) : (
                                <div className="home-content-main-text">
                                    <IonLoading isOpen={true} message="Loading ..." />
                                </div>
                            )}
                        </div>
                    </div>
                ) : (
                    <React.Fragment>
                        <VirtualizeSwipeableViews
                            //...itemSizeState,
                            enableMouseEvents={true}
                            slideRenderer={renderSlide}
                            slideCount={gridProps.slideCount}
                            overscanSlideAfter={1}
                            overscanSlideBefore={1}
                            onChangeIndex={(index: number): void => {
                                if (gridProps.currentPage !== index) {
                                    setGridProps({
                                        ...gridProps,
                                        currentPage: index,
                                    });
                                }
                            }}
                            index={gridProps.currentPage}
                        />
                        {gridProps.slideCount > 1 ? (
                            <div className="carousel-pager">
                                {[...Array(gridProps.slideCount)].map((value, index) => {
                                    return (
                                        <svg
                                            key={index}
                                            onClick={(): void => {
                                                if (gridProps.currentPage !== index) {
                                                    setGridProps({
                                                        ...gridProps,
                                                        currentPage: index,
                                                    });
                                                }
                                            }}
                                            height="12"
                                            width="12"
                                        >
                                            <circle
                                                cx="5"
                                                cy="5"
                                                r="4"
                                                stroke="#222428"
                                                strokeWidth="1"
                                                fill={
                                                    index === gridProps.currentPage
                                                        ? '#989aa2'
                                                        : '#f5f6f9'
                                                }
                                            />
                                        </svg>
                                    );
                                })}
                            </div>
                        ) : (
                            ''
                        )}
                    </React.Fragment>
                )}
            </IonContent>
        </IonPage>
    );
};

const sitesPage = withRouter(SitesPage);

export { sitesPage as SitesPage };
