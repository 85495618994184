import React, { useEffect, useCallback, useRef } from 'react';

import { RadialGauge as CanvasRadialGauge, RadialGaugeOptions } from 'canvas-gauges';

export const RadialGauge: React.FC<RadialGaugeOptions> = (props: RadialGaugeOptions) => {
    const gauge = useRef<CanvasRadialGauge>();
    let el: HTMLCanvasElement;

    useEffect(
        () => {
            const options: RadialGaugeOptions = Object.assign({}, props, {
                renderTo: el,
            });
            gauge.current = new CanvasRadialGauge(options).draw();
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    useEffect(
        () => {
            if (props.value && gauge.current) {
                gauge.current.value = props.value;
                gauge.current.update(props);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [props.value, gauge.current],
    );

    const updateGauge = useCallback(
        () => {
            if (gauge.current) {
                gauge.current.update(props);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [props.width, props.height, gauge.current],
    );

    useEffect(
        () => {
            updateGauge();
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [props.width, props.height, gauge],
    );

    return (
        <canvas
            ref={(canvas: HTMLCanvasElement): void => {
                el = canvas;
            }}
        />
    );
};
