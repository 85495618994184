import React from 'react';
import { ISigninButtonProps } from '../models/ISigninButtonProps';

import './MicrosoftSigninButton.scss';

export const MicrosoftSigninButton: React.FC<ISigninButtonProps> = (props: ISigninButtonProps) => {
    return (
        <span onClick={props.onClick}>
            <div className="microsoft-signin">
                <span className="microsoft-signin-icon" />
                <span className="microsoft-signin-text">Sign in with Microsoft</span>
            </div>
        </span>
    );
};
