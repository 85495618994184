/* eslint-disable @typescript-eslint/no-use-before-define */
import {
    IonButtons,
    IonContent,
    IonHeader,
    IonMenuButton,
    IonPage,
    IonTitle,
    IonToolbar,
    IonList,
    IonItemGroup,
    IonLabel,
    IonItem,
    IonInput,
    IonIcon,
    IonButton,
    IonAlert,
} from '@ionic/react';
import React, { useEffect, useState } from 'react';
import './Profile.scss';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../store';
import { withRouter, useHistory, useRouteMatch } from 'react-router';
import { LogoutButton } from '../../components/LogoutButton';
import { create, trash } from 'ionicons/icons';
import { AddCompanyDialog } from './AddCompany';
import { addUserCompany, removeUserCompany, setReturnUrl } from '../../store/reducers/authReducer';
import { AppDispatch } from '../../store/store';
import { ICompany, AuthRoles } from 'vision9-solar-shared';
import { setAlert } from '../../store/reducers/alertReducer';

const ProfilePage: React.FC = () => {
    const history = useHistory();
    const dispatch = useDispatch<AppDispatch>();

    const user = useSelector((state: RootState) => state.auth.user);
    const companies = useSelector((state: RootState) => state.company.companies);
    const monitors = useSelector((state: RootState) => state.monitor.monitors);

    const [addCompanyDialogOpen, setAddCompanyDialogOpen] = useState(false);
    const [company, setCompany] = useState<ICompany>();
    const [removeCompanyOpen, setRemoveCompanyOpen] = useState(false);
    const [removeCompanyForceOpen, setRemoveCompanyForceOpen] = useState(false);

    const { params } = useRouteMatch<{ url?: string }>();

    useEffect(() => {
        if ((params.url?.trim()?.length || 0) > 0) {
            dispatch(setReturnUrl(params.url || ''));
        }
    }, [params]);

    const editCompany = (companyId: number): void => {
        history.push(`/company/${companyId}`);
    };

    const addCompany = (companyId?: number): void => {
        setAddCompanyDialogOpen(false);
        if (companyId) {
            dispatch(addUserCompany(companyId));
        }
    };

    const removeCompanyDispatch = async (): Promise<void> => {
        if (!company) {
            return;
        }

        try {
            await dispatch(removeUserCompany(company.companyId, false));
        } catch (e) {
            if (e.message === 'LASTUSER') {
                setRemoveCompanyForceOpen(true);
            } else if (e.message === 'HASMONITORS') {
                dispatch(
                    setAlert({
                        header: 'Error',
                        message:
                            'You cannot remove the last user from a company with existing monitors.  Please remove all monitors before removing the company.',
                    }),
                );
            } else {
                dispatch(
                    setAlert({
                        header: 'Unknown Error',
                        message:
                            'An unknown error occured while trying to remove you from the company.',
                    }),
                );
            }
        }
    };

    const removeCompanyForceDispatch = async (): Promise<void> => {
        if (!company) {
            return;
        }

        try {
            await dispatch(removeUserCompany(company.companyId, true));
        } catch (e) {
            alert(e.message);
        }
    };

    const removeCompany = (companyId?: number): void => {
        setCompany(companies.find(c => c.companyId === companyId));
        setRemoveCompanyOpen(true);
    };

    if (!user) {
        return <IonPage />;
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonMenuButton />
                    </IonButtons>
                    <IonTitle>Profile</IonTitle>
                    <LogoutButton />
                </IonToolbar>
            </IonHeader>
            <IonContent className="profile-content">
                <form>
                    <IonList>
                        <IonItemGroup>
                            <IonHeader>Account Information</IonHeader>
                            <IonItem>
                                <IonLabel>E-Mail:</IonLabel>
                                <IonInput readonly={true} value={user.email} />
                            </IonItem>
                            <IonItem>
                                <IonLabel>Full Name:</IonLabel>
                                <IonInput type="text" value={user.fullName} />
                            </IonItem>
                        </IonItemGroup>
                        <IonItemGroup>
                            <IonHeader>
                                <IonItem>
                                    <IonHeader slot="start">Companies</IonHeader>
                                    <IonButtons
                                        slot="start"
                                        hidden={
                                            user.role !== AuthRoles.Admin &&
                                            user.role !== AuthRoles.Installer &&
                                            Object.keys(user.companies).length !== 0
                                        }
                                    >
                                        <IonButton
                                            buttonType="solid"
                                            slot="start"
                                            onClick={(): void => history.push('/company')}
                                        >
                                            <IonLabel slot="end">New</IonLabel>
                                        </IonButton>
                                        <IonButton
                                            buttonType="solid"
                                            hidden={user.role !== AuthRoles.Admin}
                                            slot="start"
                                            onClick={(): void => setAddCompanyDialogOpen(true)}
                                        >
                                            <IonLabel slot="end">Add</IonLabel>
                                        </IonButton>
                                    </IonButtons>
                                </IonItem>
                            </IonHeader>
                            {companies
                                .slice()
                                .sort((a, b) => a.companyName.localeCompare(b.companyName))
                                .map(company => (
                                    <IonItem key={company.companyName} className="company-item">
                                        <IonIcon
                                            onClick={(): void => removeCompany(company.companyId)}
                                            icon={trash}
                                        />
                                        &nbsp;
                                        <IonButton
                                            onClick={(): void => editCompany(company.companyId)}
                                            fill="clear"
                                        >
                                            <IonIcon slot="start" icon={create} />
                                            {`${company.companyName} (${
                                                monitors.filter(
                                                    m => m.companyId === company.companyId,
                                                ).length
                                            } Monitors)`}
                                        </IonButton>
                                    </IonItem>
                                ))}
                        </IonItemGroup>
                    </IonList>
                </form>
                {user.role === AuthRoles.Admin ? (
                    <AddCompanyDialog isOpen={addCompanyDialogOpen} onClose={addCompany} />
                ) : (
                    ''
                )}
                <IonAlert
                    isOpen={removeCompanyOpen}
                    onDidDismiss={(): void => setRemoveCompanyOpen(false)}
                    header={'Remove Company?'}
                    message={`Are you sure you would like to remove, ${
                        company ? company.companyName : ''
                    }, from your profile?  Once you have removed your access, only an administrator of the company will be able to add you back in again?`}
                    buttons={[
                        'Cancel',
                        {
                            text: 'Ok',
                            handler: (): void => {
                                removeCompanyDispatch();
                            },
                        },
                    ]}
                />
                <IonAlert
                    isOpen={removeCompanyForceOpen}
                    onDidDismiss={(): void => setRemoveCompanyForceOpen(false)}
                    header={'WARNING: Delete Company?'}
                    message={`You are the last user in, ${
                        company ? company.companyName : ''
                    }.  Warning!  Proceeding with removing this company will also delete the company.`}
                    buttons={[
                        'Cancel',
                        {
                            text: 'Ok',
                            handler: (): void => {
                                removeCompanyForceDispatch();
                            },
                        },
                    ]}
                />
            </IonContent>
        </IonPage>
    );
};

const profilePage = withRouter(ProfilePage);

export { profilePage as ProfilePage };
