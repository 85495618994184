/* eslint-disable react/prop-types */
import {
    IonButtons,
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonIcon,
    IonButton,
    IonList,
    IonItem,
    IonLabel,
    IonInput,
    IonSelect,
    IonSelectOption,
    IonItemGroup,
    IonAlert,
} from '@ionic/react';
import React, { useEffect, useState, ReactElement } from 'react';
import './Company.scss';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../store';
import { withRouter, useHistory, useRouteMatch } from 'react-router';
import { LogoutButton } from '../../components/LogoutButton';
import { arrowBack, create, trash } from 'ionicons/icons';
import { ICompany, IMonitor, RoleType, IUserCompanyView, AuthRoles } from 'vision9-solar-shared';
import { InputChangeEventDetail } from '@ionic/core';
import { AppDispatch } from '../../store/store';
import {
    saveCompany,
    getCompanyUsers,
    addUser,
    deleteCompanyUser,
} from '../../store/reducers/companyReducer';
import { setAlert } from '../../store/reducers/alertReducer';

const CompanyPage: React.FC = () => {
    const match = useRouteMatch<{ companyId?: string }>();
    const history = useHistory();
    const dispatch = useDispatch<AppDispatch>();
    const user = useSelector((state: RootState) => state.auth.user);
    const companies = useSelector((state: RootState) => state.company.companies);
    const allMonitors = useSelector((state: RootState) => state.monitor.monitors);
    const companyUsers = useSelector((state: RootState) => state.company.companyUsers);
    const [formState, setFormState] = useState({} as ICompany);
    const [monitors, setMonitors] = useState([] as IMonitor[]);
    const [addUserDialogOpen, setAddUserDialogOpen] = useState(false);
    const [deleteUserDialogOpen, setDeleteUserDialogOpen] = useState(false);
    const [deleteUser, setDeleteUser] = useState({} as IUserCompanyView);

    useEffect(
        () => {
            if (!match.params.companyId) {
                return;
            }

            dispatch(getCompanyUsers(parseInt(match.params.companyId)));

            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            setMonitors(allMonitors.filter(m => m.companyId === parseInt(match.params.companyId!)));
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [allMonitors, match.params.companyId],
    );

    useEffect(
        () => {
            if (!match.params.companyId || !companies) {
                if (!match.params.companyId) {
                    setFormState({
                        companyId: 0,
                        companyName: '',
                        address1: '',
                        address2: '',
                        country: 'CA',
                        city: '',
                        province: '',
                        postal: '',
                        phone: '',
                        contactEmail: user ? user.email : '',
                        monitors: [],
                        users: [],
                    });
                }
                return;
            }

            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const c = companies.find(c => c.companyId === parseInt(match.params.companyId!));
            if (c) {
                setFormState(c);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [companies, match.params.companyId],
    );

    if (!user) {
        return <IonPage />;
    }

    const editMonitor = (monitorId: number): void => {
        history.push(`/monitor/${monitorId}`);
    };

    const submitCompany = (): void => {
        console.log(JSON.stringify(formState));
        dispatch(saveCompany(formState))
            .then(() => {
                history.goBack();
            })
            .catch((error): void => {
                setAlert({
                    header: 'Error',
                    message: `An error occured saving the company, ${
                        formState.companyName
                    }: ${error.message || error}`,
                });
            });
    };

    const addUserSubmit = async (email: string, role: RoleType): Promise<void> => {
        try {
            if (!match.params.companyId) {
                return;
            }

            await dispatch(addUser(parseInt(match.params.companyId), email, role));
        } catch (e) {
            dispatch(
                setAlert({
                    header: 'Error Adding User',
                    message: e.message || e,
                }),
            );
        }
    };

    const fieldOnChange = (event: CustomEvent<InputChangeEventDetail>): void => {
        if (event.currentTarget) {
            setFormState({
                ...formState,
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                [(event.currentTarget as any).name]: event.detail.value,
            });
        }
    };

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonButton onClick={(): void => history.goBack()}>
                            <IonIcon icon={arrowBack} />
                        </IonButton>
                    </IonButtons>
                    <IonTitle>{formState.companyName}</IonTitle>
                    <LogoutButton />
                </IonToolbar>
            </IonHeader>
            <IonContent className="company-content">
                <form
                    onSubmit={(e): void => {
                        e.preventDefault();
                        submitCompany();
                    }}
                >
                    <IonList>
                        <IonItemGroup>
                            <IonHeader>Company Information</IonHeader>
                            <IonItem>
                                <IonLabel>Company Name:</IonLabel>
                                <IonInput
                                    type="text"
                                    name="companyName"
                                    placeholder="Company Name"
                                    required={true}
                                    spellCheck={false}
                                    onIonChange={fieldOnChange}
                                    value={formState.companyName}
                                />
                            </IonItem>
                            <IonItem>
                                <IonLabel>Address (Line 1):</IonLabel>
                                <IonInput
                                    type="text"
                                    name="address1"
                                    placeholder="Address (Line 1)"
                                    spellCheck={false}
                                    onIonChange={fieldOnChange}
                                    value={formState.address1}
                                />
                            </IonItem>
                            <IonItem>
                                <IonLabel>Address (Line 2):</IonLabel>
                                <IonInput
                                    type="text"
                                    name="address2"
                                    placeholder="Address (Line 2)"
                                    spellCheck={false}
                                    onIonChange={fieldOnChange}
                                    value={formState.address2}
                                />
                            </IonItem>
                            <IonItem>
                                <IonLabel>City:</IonLabel>
                                <IonInput
                                    type="text"
                                    name="city"
                                    placeholder="City"
                                    spellCheck={false}
                                    onIonChange={fieldOnChange}
                                    value={formState.city}
                                />
                            </IonItem>
                            <IonItem>
                                <IonLabel>Country:</IonLabel>
                                <IonSelect
                                    name="country"
                                    interface="popover"
                                    placeholder="Country"
                                    onIonChange={fieldOnChange}
                                    value={formState.country}
                                >
                                    <IonSelectOption value="CA">Canada</IonSelectOption>
                                    <IonSelectOption value="US">United States</IonSelectOption>
                                </IonSelect>
                            </IonItem>
                            <IonItem>
                                <IonLabel>Province/State:</IonLabel>
                                <IonInput
                                    type="text"
                                    name="province"
                                    placeholder="Province"
                                    spellCheck={false}
                                    onIonChange={fieldOnChange}
                                    value={formState.province}
                                />
                            </IonItem>
                            <IonItem>
                                <IonLabel>Postal/Zip Code:</IonLabel>
                                <IonInput
                                    type="text"
                                    name="postal"
                                    placeholder="Postal/Zip Code"
                                    spellCheck={false}
                                    onIonChange={fieldOnChange}
                                    value={formState.postal}
                                />
                            </IonItem>
                            <IonItem>
                                <IonLabel>Phone #:</IonLabel>
                                <IonInput
                                    type="text"
                                    name="phone"
                                    placeholder="Phone #"
                                    // pattern="^([\+][0-9]{1,3}([ \.\-])?)?([\(]{1}[0-9]{3}[\)])?([0-9A-Z \.\-]{1,32})((x|ext|extension)?[0-9]{1,4}?)$"
                                    spellCheck={false}
                                    onIonChange={fieldOnChange}
                                    value={formState.phone}
                                />
                            </IonItem>
                            <IonItem>
                                <IonLabel>Contact E-Mail:</IonLabel>
                                <IonInput
                                    type="email"
                                    name="contactEmail"
                                    placeholder="Contact Email"
                                    spellCheck={false}
                                    onIonChange={fieldOnChange}
                                    value={formState.contactEmail}
                                />
                            </IonItem>
                        </IonItemGroup>
                        <br />
                        <IonItemGroup hidden={!match.params.companyId}>
                            <IonHeader className="item-group-header">
                                Monitors&nbsp;&nbsp;&nbsp;
                                <IonButtons
                                    slot="start"
                                    hidden={
                                        !match.params.companyId ||
                                        (user.role !== AuthRoles.Admin &&
                                            (user.companies[parseInt(match.params.companyId)] !==
                                                AuthRoles.Admin ||
                                                user.role !== AuthRoles.Installer))
                                    }
                                >
                                    <IonButton
                                        size="small"
                                        buttonType="solid"
                                        slot="start"
                                        onClick={(): void =>
                                            history.push(`/newmonitor/${match.params.companyId}`)
                                        }
                                    >
                                        <IonLabel slot="end">New</IonLabel>
                                    </IonButton>
                                </IonButtons>
                            </IonHeader>
                            {monitors
                                .slice()
                                .sort((a, b) => a.monitorName.localeCompare(b.monitorName))
                                .map(monitor => (
                                    <IonItem key={monitor.monitorName} className="monitor-item">
                                        <IonButton
                                            onClick={(): void => editMonitor(monitor.monitorId)}
                                            fill="clear"
                                        >
                                            <IonIcon icon={create} slot="start" />
                                            {monitor.monitorName}
                                        </IonButton>
                                    </IonItem>
                                ))}
                        </IonItemGroup>
                        <br />
                        <IonItemGroup
                            hidden={
                                !user ||
                                !match.params.companyId ||
                                user.companies[parseInt(match.params.companyId)] !== AuthRoles.Admin
                            }
                        >
                            <IonHeader className="item-group-header">
                                Users
                                <IonButtons slot="start" hidden={false}>
                                    <IonButton
                                        buttonType="solid"
                                        slot="start"
                                        onClick={(): void => {
                                            setAddUserDialogOpen(true);
                                        }}
                                    >
                                        <IonLabel slot="end">Add or Invite</IonLabel>
                                    </IonButton>
                                </IonButtons>
                            </IonHeader>
                            {((): ReactElement[] | string => {
                                const companyId = match.params.companyId
                                    ? parseInt(match.params.companyId)
                                    : undefined;
                                if (!companyId || isNaN(companyId)) {
                                    return '';
                                }
                                const users = companyUsers[companyId];
                                console.log(
                                    JSON.stringify(
                                        `Company: ${companyId}: ${JSON.stringify(users)}`,
                                    ),
                                );

                                if (!users) {
                                    return '';
                                }

                                return users
                                    .slice()
                                    .sort((a, b) =>
                                        `${a.fullName}${a.email}`.localeCompare(
                                            `${b.fullName}${b.email}`,
                                        ),
                                    )
                                    .map(u => (
                                        <IonItem key={u.userId}>
                                            <IonButton
                                                disabled={u.userId === user.id}
                                                className="button-clear"
                                                buttonType="clear"
                                                slot="start"
                                                onClick={(): void => {
                                                    setDeleteUser(u);
                                                    setDeleteUserDialogOpen(true);
                                                }}
                                            >
                                                <IonIcon icon={trash} />
                                            </IonButton>
                                            <IonLabel slot="start">{u.fullName}</IonLabel>
                                            <IonLabel slot="start">{u.email}</IonLabel>
                                            <IonLabel slot="start">{u.role}</IonLabel>
                                            <IonLabel slot="start">{u.status}</IonLabel>
                                        </IonItem>
                                    ));
                            })()}
                        </IonItemGroup>
                    </IonList>
                    <br />
                    <div className="button-bar">
                        <IonButton
                            hidden={
                                !match.params.companyId
                                    ? false
                                    : user.companies[parseInt(match.params.companyId)] !==
                                      AuthRoles.Admin
                            }
                            type="submit"
                        >
                            Save
                        </IonButton>
                    </div>
                </form>
                <IonAlert
                    isOpen={addUserDialogOpen}
                    header="Add User?"
                    message="Please enter the e-mail address of the user you would like to add.  If the user is found, they will be granted the request access and sent an e-mail; if they are not found, an invitation will be sent to them to join."
                    onDidDismiss={(): void => setAddUserDialogOpen(false)}
                    inputs={[
                        {
                            type: 'text',
                            label: 'E-Mail',
                            name: 'email',
                            placeholder: 'E-Mail',
                        },
                    ]}
                    buttons={[
                        {
                            text: 'Add',
                            handler: (data): void => {
                                addUserSubmit(data.email, AuthRoles.Read);
                            },
                        },
                        {
                            text: 'Add as Administrator',
                            handler: (data): void => {
                                addUserSubmit(data.email, AuthRoles.Admin);
                            },
                        },
                        {
                            text: 'Cancel',
                        },
                    ]}
                />
                <IonAlert
                    isOpen={deleteUserDialogOpen}
                    onDidDismiss={(): void => setDeleteUserDialogOpen(false)}
                    header="Remove User?"
                    message={`Would you like to remove, ${
                        deleteUser ? deleteUser.email : ''
                    }, from this company?`}
                    buttons={[
                        {
                            text: 'Ok',
                            handler: (): void => {
                                if (match.params.companyId) {
                                    dispatch(
                                        deleteCompanyUser(
                                            parseInt(match.params.companyId),
                                            deleteUser.userId,
                                            deleteUser.email,
                                        ),
                                    );
                                }
                            },
                        },
                        {
                            text: 'Cancel',
                        },
                    ]}
                />
            </IonContent>
        </IonPage>
    );
};

const companyPage = withRouter(CompanyPage);

export { companyPage as CompanyPage };
