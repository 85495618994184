/* eslint-disable react/prop-types */
import {
    IonButtons,
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonIcon,
    IonButton,
    IonList,
    IonItem,
    IonLabel,
    IonInput,
    IonSelect,
    IonSelectOption,
    IonItemGroup,
    IonCheckbox,
    IonAlert,
    IonGrid,
    IonRow,
    IonCol,
} from '@ionic/react';
import React, { useEffect, useState } from 'react';
import './Monitor.scss';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../store';
import { withRouter, useHistory, useRouteMatch } from 'react-router';
import { LogoutButton } from '../../components/LogoutButton';
import { arrowBack } from 'ionicons/icons';
import { IMonitor, AuthRoles, MonitorEventHandlerInfo } from 'vision9-solar-shared';
import { InputChangeEventDetail, AlertInput } from '@ionic/core';
import { AppDispatch } from '../../store/store';
import {
    saveMonitor,
    deleteMonitor,
    generatePasscode,
    cancelTraining,
} from '../../store/reducers/monitorReducer';
import { setAlert } from '../../store/reducers/alertReducer';
import { TrainingDialog } from '../../components/TrainingDialog';

const MonitorPage: React.FC = () => {
    const match = useRouteMatch<{ monitorId?: string; companyId?: string }>();
    const history = useHistory();
    const dispatch = useDispatch<AppDispatch>();

    const user = useSelector((state: RootState) => state.auth.user);
    const monitors = useSelector((state: RootState) => state.monitor.monitors);
    const locations = useSelector((state: RootState) => state.location.locations);
    const { training, progress } = useSelector((state: RootState) => {
        const monitorId = parseInt(match.params.monitorId || '0');
        if (monitorId === 0) {
            return {
                training: false,
                progress: 0,
            };
        } else {
            const trainingState = state.monitor.trainingState[monitorId];
            return trainingState || { training: false, progress: 0 };
        }
    });
    const returnUrl = useSelector((state: RootState) => state.auth.returnUrl);

    const [formState, setFormState] = useState({} as IMonitor);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [credentialsDialogOpen, setCredentialsDialogOpen] = useState(false);
    const [passcodeDialogOpen, setPasscodeDialogOpen] = useState(false);
    const [passcode, setPasscode] = useState('');
    const [agentPropsOpen, setAgentPropsOpen] = useState(false);
    const [currentAgent, setCurrentAgent] = useState('');
    const [trainingDialogOpen, setTrainingDialogOpen] = useState(false);

    useEffect(
        (): void => {
            if (!match.params.monitorId || !monitors) {
                if (match.params.companyId && locations && locations.length > 0) {
                    setFormState({
                        companyId: parseInt(match.params.companyId),
                        monitorId: 0,
                        monitorName: '',
                        red: 4000,
                        green: 8000,
                        max: 10000,
                        active: true,
                        locationId: locations[0].locationId,
                        monitorEventAgents: [],
                        monitorEventSubscriptions: [],
                    });
                }
                return;
            }

            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const m = monitors.find(m => m.monitorId === parseInt(match.params.monitorId!));
            if (m) {
                setFormState(m);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [monitors, match.params.monitorId],
    );

    if (!user) {
        return <IonPage />;
    }

    const submitMonitor = (): void => {
        dispatch(saveMonitor(formState))
            .then((): void => {
                history.goBack();
            })
            .catch(error => {
                setAlert({
                    header: 'Error',
                    message: `An error occured updating the monitor: ${error.message || error}`,
                });
            });
    };

    const deleteMonitorSubmit = async (): Promise<void> => {
        try {
            await dispatch(deleteMonitor(formState.monitorId));
            history.goBack();
        } catch (e) {
            dispatch(
                setAlert({
                    header: 'Error Deleting Monitor',
                    message: `An error occured deleting the monitor, ${
                        formState.monitorName
                    }: ${e.message || e}`,
                }),
            );
        }
    };

    const toggleTraining = (): void => {
        const monitorId = parseInt(match.params.monitorId || '0');
        if (monitorId === 0) {
            return;
        }
        if (!training) {
            setTrainingDialogOpen(true);
        } else {
            dispatch(cancelTraining(monitorId));
        }
    };

    const fieldOnChange = (event: CustomEvent<InputChangeEventDetail>): void => {
        if (event.currentTarget) {
            setFormState({
                ...formState,
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                [(event.currentTarget as any).name]: event.detail.value,
            });
        }
    };

    const subscriptionLevelChanged = (event: string, enabled: boolean): void => {
        let level = 0;
        let oldLevel = 0;
        if (
            formState &&
            formState.monitorEventSubscriptions &&
            formState.monitorEventSubscriptions.length > 0
        ) {
            level = formState.monitorEventSubscriptions[0].level;
            oldLevel = level;
        } else {
            oldLevel = -1;
        }
        switch (event) {
            case 'WARNING': {
                if (enabled) {
                    level |= 1;
                } else {
                    level &= 6;
                }
                break;
            }
            case 'ALERT': {
                if (enabled) {
                    level |= 2;
                } else {
                    level &= 5;
                }
                break;
            }
            case 'RESOLVED': {
                if (enabled) {
                    level |= 4;
                } else {
                    level &= 3;
                }
                break;
            }
        }

        if (level !== oldLevel) {
            setFormState({
                ...formState,
                monitorEventSubscriptions: [
                    {
                        monitorId: formState.monitorId,
                        userId: user.id,
                        level,
                    },
                ],
            });
        }
    };

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonButton onClick={(): void => history.goBack()}>
                            <IonIcon icon={arrowBack} />
                        </IonButton>
                    </IonButtons>
                    <IonTitle>{formState.monitorName}</IonTitle>
                    <LogoutButton />
                </IonToolbar>
            </IonHeader>
            <IonContent className="monitor-content">
                <form
                    onSubmit={(e): void => {
                        e.preventDefault();
                        submitMonitor();
                    }}
                >
                    <IonList>
                        <IonItemGroup>
                            <IonHeader>Monitor Information</IonHeader>
                            <IonItem>
                                <IonLabel>Monitor Name:</IonLabel>
                                <IonInput
                                    type="text"
                                    name="monitorName"
                                    placeholder="Monitor Name"
                                    required={true}
                                    spellCheck={false}
                                    onIonChange={fieldOnChange}
                                    value={formState.monitorName}
                                />
                            </IonItem>
                            <IonItem>
                                <IonLabel>Description:</IonLabel>
                                <IonInput
                                    type="text"
                                    name="monitorDescription"
                                    placeholder="Monitor Description"
                                    spellCheck={false}
                                    onIonChange={fieldOnChange}
                                    value={formState.monitorDescription}
                                />
                            </IonItem>
                            <IonItem>
                                <IonLabel>Red:</IonLabel>
                                <IonInput
                                    type="number"
                                    name="red"
                                    placeholder="Red Value"
                                    required={true}
                                    spellCheck={false}
                                    onIonChange={fieldOnChange}
                                    value={formState.red ? formState.red.toString() : '4000'}
                                />
                            </IonItem>
                            <IonItem>
                                <IonLabel>Green:</IonLabel>
                                <IonInput
                                    type="number"
                                    name="green"
                                    placeholder="Green Value"
                                    required={true}
                                    spellCheck={false}
                                    onIonChange={fieldOnChange}
                                    value={formState.green ? formState.green.toString() : '8000'}
                                />
                            </IonItem>
                            <IonItem>
                                <IonLabel>Max:</IonLabel>
                                <IonInput
                                    type="number"
                                    name="max"
                                    placeholder="Max Value"
                                    required={true}
                                    spellCheck={false}
                                    onIonChange={fieldOnChange}
                                    value={formState.max ? formState.max.toString() : '10000'}
                                />
                            </IonItem>
                            <IonItem>
                                <IonLabel>Active:</IonLabel>
                                <IonCheckbox name="active" checked={formState.active} />
                            </IonItem>
                            <IonItem>
                                <IonLabel>Location:</IonLabel>
                                <IonSelect
                                    interface="popover"
                                    value={formState.locationId}
                                    onIonChange={fieldOnChange}
                                    name="locationId"
                                >
                                    {locations.map(location => {
                                        return (
                                            <IonSelectOption
                                                key={location.locationId}
                                                value={location.locationId}
                                            >{`${location.city} (${location.location.x}, ${location.location.y})`}</IonSelectOption>
                                        );
                                    })}
                                </IonSelect>
                            </IonItem>
                        </IonItemGroup>
                        <IonItemGroup>
                            <IonHeader>Subscription Level</IonHeader>
                            <IonGrid>
                                <IonRow>
                                    <IonCol size="1">
                                        <IonCheckbox
                                            checked={
                                                formState &&
                                                formState.monitorEventSubscriptions &&
                                                formState.monitorEventSubscriptions.length > 0
                                                    ? (formState.monitorEventSubscriptions[0]
                                                          .level &
                                                          1) ===
                                                      1
                                                    : false
                                            }
                                            onIonChange={(event): void =>
                                                subscriptionLevelChanged(
                                                    'WARNING',
                                                    event.detail.checked,
                                                )
                                            }
                                        />
                                    </IonCol>
                                    <IonCol size="2">Warning</IonCol>
                                    <IonCol>
                                        E-Mail notification when a warning event is triggered.
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol size="1">
                                        <IonCheckbox
                                            checked={
                                                formState &&
                                                formState.monitorEventSubscriptions &&
                                                formState.monitorEventSubscriptions.length > 0
                                                    ? (formState.monitorEventSubscriptions[0]
                                                          .level &
                                                          2) ===
                                                      2
                                                    : false
                                            }
                                            onIonChange={(event): void =>
                                                subscriptionLevelChanged(
                                                    'ALERT',
                                                    event.detail.checked,
                                                )
                                            }
                                        />
                                    </IonCol>
                                    <IonCol size="2">Alert</IonCol>
                                    <IonCol>
                                        E-Mail notification when an alert event is triggered.
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol size="1">
                                        <IonCheckbox
                                            checked={
                                                formState &&
                                                formState.monitorEventSubscriptions &&
                                                formState.monitorEventSubscriptions.length > 0
                                                    ? (formState.monitorEventSubscriptions[0]
                                                          .level &
                                                          4) ===
                                                      4
                                                    : false
                                            }
                                            onIonChange={(event): void =>
                                                subscriptionLevelChanged(
                                                    'RESOLVED',
                                                    event.detail.checked,
                                                )
                                            }
                                        />
                                    </IonCol>
                                    <IonCol size="2">Resolved</IonCol>
                                    <IonCol>E-Mail notification when an event is resolved.</IonCol>
                                </IonRow>
                            </IonGrid>
                        </IonItemGroup>
                        <IonItemGroup>
                            <IonHeader>Agents</IonHeader>
                            <IonGrid>
                                {Object.keys(MonitorEventHandlerInfo).map(key => {
                                    const info = MonitorEventHandlerInfo[key];
                                    return (
                                        <IonRow key={key} className="agent-row">
                                            <IonCol size="1">
                                                <IonCheckbox
                                                    onIonChange={(event): void => {
                                                        if (event.detail.checked) {
                                                            let agent =
                                                                formState &&
                                                                formState.monitorEventAgents
                                                                    ? formState.monitorEventAgents.find(
                                                                          a => a.type === key,
                                                                      )
                                                                    : undefined;
                                                            if (!agent) {
                                                                agent = {
                                                                    agentId: 0,
                                                                    active: true,
                                                                    monitorId: match.params
                                                                        .monitorId
                                                                        ? parseInt(
                                                                              match.params
                                                                                  .monitorId,
                                                                          )
                                                                        : 0,
                                                                    type: key,
                                                                    properties: info.defaults,
                                                                };
                                                            }

                                                            const otherAgents =
                                                                formState &&
                                                                formState.monitorEventAgents
                                                                    ? formState.monitorEventAgents.filter(
                                                                          a => a.type !== key,
                                                                      )
                                                                    : [];

                                                            setFormState({
                                                                ...formState,
                                                                monitorEventAgents: [
                                                                    ...otherAgents,
                                                                    { ...agent, active: true },
                                                                ],
                                                            });
                                                        } else {
                                                            setFormState({
                                                                ...formState,
                                                                monitorEventAgents: [
                                                                    ...(formState &&
                                                                    formState.monitorEventAgents
                                                                        ? formState.monitorEventAgents.map(
                                                                              a =>
                                                                                  a.type !== key
                                                                                      ? a
                                                                                      : {
                                                                                            ...a,
                                                                                            active: false,
                                                                                        },
                                                                          )
                                                                        : []),
                                                                ],
                                                            });
                                                        }
                                                    }}
                                                    checked={((): boolean => {
                                                        if (
                                                            !formState ||
                                                            !formState.monitorEventAgents
                                                        ) {
                                                            return false;
                                                        }

                                                        const agent = formState.monitorEventAgents.find(
                                                            a => a.type === key,
                                                        );
                                                        return agent ? agent.active : false;
                                                    })()}
                                                />
                                            </IonCol>
                                            <IonCol
                                                className="link-col"
                                                size="4"
                                                onClick={(): void => {
                                                    setCurrentAgent(key);
                                                    setAgentPropsOpen(true);
                                                }}
                                            >
                                                {info.title}
                                            </IonCol>
                                            <IonCol>{info.description}</IonCol>
                                        </IonRow>
                                    );
                                })}
                            </IonGrid>
                        </IonItemGroup>
                    </IonList>
                    <br />
                    <div
                        hidden={
                            !formState.companyId ||
                            (user.role !== AuthRoles.Admin &&
                                (user.companies[formState.companyId] !== AuthRoles.Admin ||
                                    user.role !== AuthRoles.Installer))
                        }
                        className="button-bar"
                    >
                        <IonButton type="submit">Save</IonButton>
                        <IonButton
                            hidden={!match.params.monitorId}
                            type="button"
                            onClick={(): void => setDeleteDialogOpen(true)}
                        >
                            Delete
                        </IonButton>
                        <IonButton
                            hidden={!match.params.monitorId}
                            type="button"
                            onClick={(): void => setCredentialsDialogOpen(true)}
                        >
                            Generate Credentials
                        </IonButton>
                        <IonButton
                            hidden={!match.params.monitorId}
                            type="button"
                            onClick={(): void => toggleTraining()}
                        >
                            {training ? `Cancel (${Math.round(progress * 100)}%)` : 'Train'}
                        </IonButton>
                    </div>
                </form>
                <IonAlert
                    isOpen={deleteDialogOpen}
                    header="Delete Monitor?"
                    message={`Are you sure you want to delete the monitor, ${formState.monitorName}?  ALL DATA ASSOCATED WITH THIS MONITOR WILL BE DELETED!  Warning!  This action cannot be undone.`}
                    onDidDismiss={(): void => setDeleteDialogOpen(false)}
                    buttons={[
                        {
                            text: 'Ok',
                            handler: deleteMonitorSubmit,
                        },
                        {
                            text: 'Cancel',
                        },
                    ]}
                />
                <IonAlert
                    isOpen={credentialsDialogOpen}
                    header="Generate New Credentials?"
                    message={`Are you sure you want to generate new credentials?  Doing so will inavlidate your existing monitor credentials ...`}
                    onDidDismiss={(): void => setCredentialsDialogOpen(false)}
                    buttons={[
                        {
                            text: 'Ok',
                            handler: (): void => {
                                if (match.params.monitorId && user.token) {
                                    generatePasscode(user.token, parseInt(match.params.monitorId))
                                        .then(pwd => {
                                            if ((returnUrl?.length || 0) > 0) {
                                                document.location.href = `${returnUrl}/${Buffer.from(
                                                    `${match.params.monitorId}|${pwd}`,
                                                    'utf8',
                                                ).toString('base64')}`;
                                                return;
                                            }
                                            setPasscode(pwd);
                                            setPasscodeDialogOpen(true);
                                        })
                                        .catch(reason => {
                                            dispatch(
                                                setAlert({
                                                    header: 'Error Generating Passcode',
                                                    message: `An error occured generating a new monitor passcode: ${reason}`,
                                                }),
                                            );
                                        });
                                }
                            },
                        },
                        {
                            text: 'Cancel',
                        },
                    ]}
                />
                <IonAlert
                    isOpen={passcodeDialogOpen}
                    onDidDismiss={(): void => {
                        setPasscode('');
                        setPasscodeDialogOpen(false);
                    }}
                    header="New Passcode"
                    subHeader={`Monitor ID: ${match.params.monitorId}`}
                    message={`Please use the following passcode for your monitor (ID ${match.params.monitorId}):`}
                    inputs={[
                        {
                            type: 'text',
                            label: 'Passcode',
                            name: 'passcode',
                            value: passcode,
                        },
                    ]}
                    buttons={['Ok']}
                />
                <IonAlert
                    isOpen={agentPropsOpen}
                    onDidDismiss={(): void => {
                        setAgentPropsOpen(false);
                    }}
                    header="Agent Properties"
                    subHeader={
                        MonitorEventHandlerInfo[currentAgent]
                            ? MonitorEventHandlerInfo[currentAgent].title
                            : ''
                    }
                    inputs={
                        !currentAgent
                            ? []
                            : ((): AlertInput[] => {
                                  const info = MonitorEventHandlerInfo[currentAgent];
                                  return info.props.map(prop => {
                                      let agent =
                                          formState && formState.monitorEventAgents
                                              ? formState.monitorEventAgents.find(
                                                    a => a.type === currentAgent,
                                                )
                                              : undefined;
                                      if (!agent) {
                                          agent = {
                                              agentId: 0,
                                              active: true,
                                              monitorId: match.params.monitorId
                                                  ? parseInt(match.params.monitorId)
                                                  : 0,
                                              type: currentAgent,
                                              properties: info.defaults,
                                          };
                                      }

                                      return {
                                          name: prop.name,
                                          label: prop.title,
                                          type: 'number',
                                          value: agent.properties[prop.name],
                                          placeholder: prop.title,
                                      } as AlertInput;
                                  });
                              })()
                    }
                    buttons={[
                        {
                            text: 'Ok',
                            role: 'submit',
                            handler: (inputs): void => {
                                const info = MonitorEventHandlerInfo[currentAgent];
                                let agent =
                                    formState && formState.monitorEventAgents
                                        ? formState.monitorEventAgents.find(
                                              a => a.type === currentAgent,
                                          )
                                        : undefined;
                                if (!agent) {
                                    agent = {
                                        agentId: 0,
                                        active: true,
                                        monitorId: match.params.monitorId
                                            ? parseInt(match.params.monitorId)
                                            : 0,
                                        type: currentAgent,
                                        properties: info.defaults,
                                    };
                                }

                                const newValues = {} as { [key: string]: number };
                                for (const key of Object.keys(inputs)) {
                                    newValues[key] = parseInt(inputs[key]);
                                    if (isNaN(newValues[key])) {
                                        newValues[key] =
                                            agent && agent.properties
                                                ? (agent.properties[key] as number)
                                                : 0;
                                    }
                                }
                                const otherAgents =
                                    formState && formState.monitorEventAgents
                                        ? formState.monitorEventAgents.filter(
                                              a => a.type !== currentAgent,
                                          )
                                        : [];

                                setFormState({
                                    ...formState,
                                    monitorEventAgents: [
                                        ...otherAgents,
                                        { ...agent, properties: newValues },
                                    ],
                                });
                            },
                        },
                        {
                            text: 'Cancel',
                            role: 'Cancel',
                        },
                    ]}
                />
                <TrainingDialog
                    monitorId={match.params.monitorId ? parseInt(match.params.monitorId) : 0}
                    isOpen={trainingDialogOpen}
                    close={(): void => setTrainingDialogOpen(false)}
                />
            </IonContent>
        </IonPage>
    );
};

const monitorPage = withRouter(MonitorPage);

export { monitorPage as MonitorPage };
