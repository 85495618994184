import { IonAlert } from '@ionic/react';
import React from 'react';
import { trainMonitor } from '../store/reducers/monitorReducer';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../store/store';
import moment from 'moment';

export interface ITrainingDialogProps {
    readonly isOpen: boolean;
    readonly monitorId: number;
    readonly close: () => void;
}

export const TrainingDialog: React.FC<ITrainingDialogProps> = (props: ITrainingDialogProps) => {
    const dispatch = useDispatch<AppDispatch>();

    return (
        <IonAlert
            isOpen={props.isOpen}
            onDidDismiss={(): void => {
                props.close();
            }}
            header="Training Parameters"
            message="Enter the training parameters below or leave blank for defaults."
            inputs={[
                {
                    name: 'learningRate',
                    placeholder: 'Learning Rate (Default = 0.01)',
                    type: 'number',
                },
                {
                    name: 'batchSize',
                    placeholder: 'Batch Size (Default = 35)',
                    type: 'number',
                },
                {
                    name: 'epochs',
                    placeholder: '# of Epochs (Default = 50)',
                    type: 'number',
                },
                {
                    name: 'dateFrom',
                    placeholder: 'Date From (Default = unlimited, Format: YYYY-MM-DD)',
                    type: 'text',
                },
                {
                    name: 'dateTo',
                    placeholder: 'Date To (Default = current, Format: YYYY-MM-DD)',
                    type: 'text',
                },
                {
                    name: 'reset',
                    placeholder: `Reset? (Default = 'No')`,
                    type: 'text',
                },
            ]}
            buttons={[
                {
                    text: 'Ok',
                    role: 'submit',
                    handler: (inputs): void => {
                        dispatch(
                            trainMonitor(
                                props.monitorId,
                                Math.min(0.25, Math.max(0.0005, inputs.learningRate || 0.01)),
                                Math.min(1000, Math.max(5, inputs.batchSize || 35)),
                                Math.min(200, Math.max(5, inputs.epochs || 50)),
                                inputs.dateFrom ? moment(inputs.dateFrom).toDate() : undefined,
                                inputs.dateTo ? moment(inputs.dateTo).toDate() : undefined,
                                ['yes', 'true', '1', 'y', 't'].includes(
                                    (inputs.reset as string).toLowerCase(),
                                ),
                            ),
                        );
                        props.close();
                    },
                },
                {
                    text: 'Cancel',
                    role: 'Cancel',
                },
            ]}
        />
    );
};
