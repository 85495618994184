/* eslint-disable @typescript-eslint/no-use-before-define */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, AppDispatch } from '../store';
import { ICompany, RoleType, IUserCompanyView } from 'vision9-solar-shared';
import Axios from 'axios';
import { ICompanyState } from '../state/ICompanyState';
import HttpStatusCodes from 'http-status-codes';
import { config } from '../../config';
import { processToken } from './authReducer';

const initialState: ICompanyState = {
    fetching: false,
    initialized: false,
    companies: [],
    allCompanies: [],
    companyUsers: {},
};

const getCompanies = (): AppThunk => async (dispatch: AppDispatch): Promise<void> => {
    dispatch(getCompaniesStart());

    try {
        const response = await Axios({
            baseURL: config.apiUrl,
            url: '/company',
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (response.status !== HttpStatusCodes.OK) {
            throw new Error(
                response.data.message ||
                    'An unkown error occured retrieving the list of companies.',
            );
        }
        dispatch(getCompaniesEnd(response.data));
    } catch (e) {
        dispatch(setError(e.message));
    }
};

const getAllCompanies = (): AppThunk => async (dispatch: AppDispatch): Promise<void> => {
    dispatch(getAllCompaniesStart());
    try {
        const response = await Axios({
            baseURL: config.apiUrl,
            url: '/company/all',
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (response.status !== HttpStatusCodes.OK) {
            throw new Error(
                response.data.message ||
                    'An unkown error occured retrieving the list of all companies.',
            );
        }

        dispatch(getAllCompaniesEnd(response.data));
    } catch (e) {
        throw new Error(`An unkown error occured retrieving the list of all companies: ${e}`);
    }
};

const getCompanyUsers = (companyId: number): AppThunk => async (
    dispatch: AppDispatch,
): Promise<void> => {
    dispatch(getCompanyUsersStart());

    try {
        const response = await Axios({
            baseURL: config.apiUrl,
            url: `/company/${companyId}/users`,
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (response.status !== HttpStatusCodes.OK) {
            throw new Error(
                response.data.message ||
                    `An unkown error occured retrieving the company users: ${companyId}`,
            );
        }

        dispatch(getCompanyUsersEnd({ companyId, users: response.data }));
    } catch (e) {
        dispatch(setError(e.message));
    }
};

const saveCompany = (company: ICompany): AppThunk => async (
    dispatch: AppDispatch,
): Promise<void> => {
    dispatch(saveCompanyStart());

    try {
        const response = await Axios({
            baseURL: config.apiUrl,
            url: '/company',
            method: company.companyId > 0 ? 'PUT' : 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            data: company,
        });

        if (response.status !== HttpStatusCodes.OK) {
            throw new Error(
                response.data.message ||
                    `An unkown error occured saving the company: ${company.companyName}`,
            );
        }

        if (company.companyId > 0) {
            dispatch(saveCompanyEnd(response.data));
        } else {
            const tokenParts = response.data.token.split('|');
            dispatch(processToken(tokenParts[0], tokenParts[1]));
        }
    } catch (e) {
        dispatch(setError(e.message));
    }
};

const addUser = (companyId: number, email: string, role: RoleType): AppThunk => async (
    dispatch: AppDispatch,
): Promise<void> => {
    dispatch(addUserStart());

    try {
        const response = await Axios({
            baseURL: config.apiUrl,
            url: '/company/addUser',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            data: {
                companyId,
                email,
                role,
            },
        });

        if (response.status !== HttpStatusCodes.OK) {
            throw new Error(
                response.data.message ||
                    `An unkown error occured adding the user, ${email} the company: ${companyId}`,
            );
        }

        dispatch(addUserEnd({ companyId, users: response.data }));
    } catch (e) {
        dispatch(setError(e.response.data.message || e.message));
        throw e.response.data.message || e.message;
    }
};

const deleteCompanyUser = (companyId: number, userId: number, email: string): AppThunk => async (
    dispatch: AppDispatch,
): Promise<void> => {
    dispatch(deleteCompanyUserStart());

    try {
        const response = await Axios({
            baseURL: config.apiUrl,
            url: '/company/removeUser',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            data: {
                companyId,
                userId,
                email,
            },
        });

        if (response.status !== HttpStatusCodes.OK) {
            throw new Error(
                response.data.message ||
                    `An unkown error occured deleting the user, ${userId} the company: ${companyId}`,
            );
        }

        dispatch(deleteCompanyUserEnd({ companyId, users: response.data }));
    } catch (e) {
        dispatch(setError(e.message));
    }
};

const slice = createSlice({
    name: 'COMPANY',
    initialState,
    reducers: {
        getCompaniesStart: (state: ICompanyState): ICompanyState => {
            return {
                ...state,
                fetching: true,
                initialized: false,
                error: undefined,
                companies: [],
            };
        },
        setError: (state: ICompanyState, action: PayloadAction<string>): ICompanyState => {
            return {
                ...state,
                fetching: false,
                error: action.payload,
            };
        },
        getCompaniesEnd: (
            state: ICompanyState,
            action: PayloadAction<ICompany[]>,
        ): ICompanyState => {
            return {
                ...state,
                fetching: false,
                initialized: true,
                error: undefined,
                companies: action.payload,
            };
        },
        getAllCompaniesStart: (state: ICompanyState): ICompanyState => {
            return {
                ...state,
                fetching: true,
                error: undefined,
                allCompanies: [],
            };
        },
        getAllCompaniesEnd: (
            state: ICompanyState,
            action: PayloadAction<ICompany[]>,
        ): ICompanyState => {
            return {
                ...state,
                fetching: false,
                error: undefined,
                allCompanies: action.payload,
            };
        },
        saveCompanyStart: (state: ICompanyState): ICompanyState => {
            return {
                ...state,
                fetching: true,
                initialized: false,
                error: undefined,
            };
        },
        saveCompanyEnd: (state: ICompanyState, action: PayloadAction<ICompany>): ICompanyState => {
            const newState = {
                ...state,
                fetching: false,
                error: undefined,
                companies:
                    state.companies.length === 1
                        ? [action.payload]
                        : [
                              ...state.companies.filter(
                                  c => c.companyId !== action.payload.companyId,
                              ),
                              action.payload,
                          ],
            };

            return newState;
        },
        getCompanyUsersStart: (state: ICompanyState): ICompanyState => {
            return {
                ...state,
                fetching: true,
                error: undefined,
            };
        },
        getCompanyUsersEnd: (
            state: ICompanyState,
            action: PayloadAction<{
                companyId: number;
                users: IUserCompanyView[];
            }>,
        ): ICompanyState => {
            const users = { ...state.companyUsers };
            delete users[action.payload.companyId];

            return {
                ...state,
                fetching: false,
                initialized: true,
                error: undefined,
                companyUsers: {
                    ...users,
                    [action.payload.companyId]: action.payload.users,
                },
            };
        },
        addUserStart: (state: ICompanyState): ICompanyState => {
            return {
                ...state,
                fetching: true,
                error: undefined,
            };
        },
        addUserEnd: (
            state: ICompanyState,
            action: PayloadAction<{
                companyId: number;
                users: IUserCompanyView[];
            }>,
        ): ICompanyState => {
            const otherUsers = { ...state.companyUsers };
            delete otherUsers[action.payload.companyId];

            const newState = {
                ...state,
                fetching: false,
                error: undefined,
                companyUsers: {
                    ...otherUsers,
                    [action.payload.companyId]: action.payload.users,
                },
            };

            return newState;
        },
        deleteCompanyUserStart: (state: ICompanyState): ICompanyState => {
            return {
                ...state,
                fetching: true,
                error: undefined,
            };
        },
        deleteCompanyUserEnd: (
            state: ICompanyState,
            action: PayloadAction<{
                companyId: number;
                users: IUserCompanyView[];
            }>,
        ): ICompanyState => {
            const otherUsers = { ...state.companyUsers };
            delete otherUsers[action.payload.companyId];

            const newState = {
                ...state,
                fetching: false,
                error: undefined,
                companyUsers: {
                    ...otherUsers,
                    [action.payload.companyId]: action.payload.users,
                },
            };

            return newState;
        },
    },
});

export { getCompanies, saveCompany, getAllCompanies, getCompanyUsers, addUser, deleteCompanyUser };

export const {
    setError,
    getCompaniesStart,
    getCompaniesEnd,
    getAllCompaniesStart,
    getAllCompaniesEnd,
    saveCompanyStart,
    saveCompanyEnd,
    getCompanyUsersStart,
    getCompanyUsersEnd,
    addUserStart,
    addUserEnd,
    deleteCompanyUserStart,
    deleteCompanyUserEnd,
} = slice.actions;

const companyReducer = slice.reducer;
export { companyReducer };
