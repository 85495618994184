/* eslint-disable @typescript-eslint/no-use-before-define */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, AppDispatch } from '../store';
import { IMonitorData } from 'vision9-solar-shared';
import Axios from 'axios';
import { IMonitorDataState } from '../state/IMonitorDataState';
import HttpStatusCodes from 'http-status-codes';
import { config } from '../../config';

const MAX_HISTORY_LENGTH = 6 * 60 * 4;
const PREDICTION_MOVING_AVG = 5;

const initialState: IMonitorDataState = {
    fetching: false,
    initialized: false,
    data: [],
    history: {},
    details: [],
    predictions: {},
};

const getMonitorData = (): AppThunk => async (dispatch: AppDispatch): Promise<void> => {
    dispatch(getMonitorDataStart());

    const response = await Axios({
        baseURL: config.apiUrl,
        url: '/monitor-data',
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
    });

    if (response.status !== HttpStatusCodes.OK) {
        dispatch(
            setError(
                response.data.message || 'An unkown error occured retrieving the monitor data.',
            ),
        );
    } else {
        dispatch(getMonitorDataEnd(response.data));
    }
};

const getMonitorDetails = (monitorId: number, startDate?: Date, endDate?: Date): AppThunk => async (
    dispatch: AppDispatch,
): Promise<void> => {
    dispatch(getMonitorDetailsStart());

    const response = await Axios({
        baseURL: config.apiUrl,
        url: `/monitor-data/details`,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        data: {
            monitorId,
            startDate,
            endDate,
        },
    });

    if (response.status !== HttpStatusCodes.OK) {
        dispatch(
            setError(
                response.data.message ||
                    'An unkown error occured retrieving the monitor details data.',
            ),
        );
    } else {
        dispatch(getMonitorDetailsEnd(response.data));
    }
};

const getMonitorLastMonth = (monitorId: number): AppThunk => async (
    dispatch: AppDispatch,
): Promise<void> => {
    dispatch(getMonitorDetailsStart());

    const response = await Axios({
        baseURL: config.apiUrl,
        url: `/monitor-data/last-month/${monitorId}`,
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
    });

    if (response.status !== HttpStatusCodes.OK) {
        dispatch(
            setError(
                response.data.message ||
                    'An unkown error occured retrieving the monitor details for the last month.',
            ),
        );
    } else {
        dispatch(getMonitorDetailsEnd(response.data));
    }
};

const slice = createSlice({
    name: 'MONITOR-DATA',
    initialState,
    reducers: {
        setError: (state: IMonitorDataState, action: PayloadAction<string>): IMonitorDataState => {
            return {
                ...state,
                fetching: false,
                initialized: false,
                error: action.payload,
            };
        },
        getMonitorDataStart: (state: IMonitorDataState): IMonitorDataState => {
            return {
                ...state,
                fetching: true,
                initialized: false,
                error: undefined,
                data: [],
            };
        },
        getMonitorDataEnd: (
            state: IMonitorDataState,
            action: PayloadAction<IMonitorData[]>,
        ): IMonitorDataState => {
            return {
                ...state,
                fetching: false,
                initialized: true,
                error: undefined,
                data: action.payload,
                history: action.payload.reduce(
                    (previous, m) => ({
                        ...previous,
                        [m.monitorId]: [m.current, m.current],
                    }),
                    {},
                ),
            };
        },
        getMonitorDetailsStart: (state: IMonitorDataState): IMonitorDataState => {
            return {
                ...state,
                fetching: true,
                error: undefined,
                details: [],
            };
        },
        getMonitorDetailsEnd: (
            state: IMonitorDataState,
            action: PayloadAction<{ date: string; value: number; estimate: number }[]>,
        ): IMonitorDataState => {
            return {
                ...state,
                fetching: false,
                error: undefined,
                details: action.payload.map(d => ({
                    date: new Date(d.date),
                    value: d.value,
                    estimate: d.estimate,
                })),
            };
        },
        postMonitorData: (
            state: IMonitorDataState,
            action: PayloadAction<IMonitorData>,
        ): IMonitorDataState => {
            const history = state.history[action.payload.monitorId]
                ? [...state.history[action.payload.monitorId], action.payload.current]
                : [action.payload.current, action.payload.current];
            const predictions =
                action.payload.prediction && state.predictions[action.payload.monitorId]
                    ? [...state.predictions[action.payload.monitorId], action.payload.prediction]
                    : action.payload.prediction
                    ? [action.payload.prediction]
                    : [];
            return {
                ...state,
                data: [
                    ...state.data.filter(d => d.monitorId !== action.payload.monitorId),
                    action.payload,
                ],
                history: {
                    ...state.history,
                    [action.payload.monitorId]:
                        history.length > MAX_HISTORY_LENGTH ? history.slice(1) : history,
                },
                predictions: {
                    ...state.predictions,
                    [action.payload.monitorId]:
                        predictions.length > PREDICTION_MOVING_AVG
                            ? predictions.slice(1)
                            : predictions,
                },
            };
        },
        postMonitorDataBatch: (
            state: IMonitorDataState,
            action: PayloadAction<IMonitorData[]>,
        ): IMonitorDataState => {
            let newState = { ...state };
            for (const monitorData of action.payload) {
                const history = newState.history[monitorData.monitorId]
                    ? [...newState.history[monitorData.monitorId], monitorData.current]
                    : [monitorData.current, monitorData.current];
                const predictions =
                    monitorData.prediction && newState.predictions[monitorData.monitorId]
                        ? [...newState.predictions[monitorData.monitorId], monitorData.prediction]
                        : monitorData.prediction
                        ? [monitorData.prediction]
                        : [];
                newState = {
                    ...newState,
                    data: [
                        ...newState.data.filter(d => d.monitorId !== monitorData.monitorId),
                        monitorData,
                    ],
                    history: {
                        ...newState.history,
                        [monitorData.monitorId]:
                            history.length > MAX_HISTORY_LENGTH ? history.slice(1) : history,
                    },
                    predictions: {
                        ...newState.predictions,
                        [monitorData.monitorId]:
                            predictions.length > PREDICTION_MOVING_AVG
                                ? predictions.slice(1)
                                : predictions,
                    },
                };
            }
            return newState;
        },
    },
});

export { getMonitorData, getMonitorDetails, getMonitorLastMonth };

export const {
    setError,
    getMonitorDataStart,
    getMonitorDataEnd,
    getMonitorDetailsStart,
    getMonitorDetailsEnd,
    postMonitorData,
    postMonitorDataBatch,
} = slice.actions;

const monitorDataReducer = slice.reducer;
export { monitorDataReducer };
