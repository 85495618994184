import React, { useState } from 'react';

import { IonButton, IonIcon, IonButtons, IonAlert } from '@ionic/react';
import { exit } from 'ionicons/icons';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../store/store';
import { logout } from '../store/reducers/authReducer';
import { withRouter, RouteComponentProps, useHistory } from 'react-router';
import { Notifications } from './Notifications';

interface ILogoutButtonProps {
    readonly slot?: string;
}

type LogoutButtonPropTypes = ILogoutButtonProps & RouteComponentProps;

const LogoutButton: React.FC<LogoutButtonPropTypes> = (props: LogoutButtonPropTypes) => {
    const dispatch = useDispatch<AppDispatch>();
    const history = useHistory();
    const [logoutOpen, setLogoutOpen] = useState(false);

    return (
        <React.Fragment>
            <IonButtons slot={props.slot || 'end'}>
                <Notifications />
                <IonButton
                    onClick={(): void => {
                        setLogoutOpen(true);
                    }}
                    className="button-clear"
                >
                    <IonIcon icon={exit} />
                </IonButton>
            </IonButtons>
            <IonAlert
                isOpen={logoutOpen}
                onDidDismiss={(): void => {
                    setLogoutOpen(false);
                }}
                header={'Logout?'}
                message={
                    'Are you sure you would like to logout of the Vision9 Solar Monitoring system?'
                }
                buttons={[
                    'Cancel',
                    {
                        text: 'Ok',
                        handler: (): void => {
                            dispatch(logout()).then(() => {
                                setTimeout(() => history.push('/login'), 250);
                            });
                        },
                    },
                ]}
            />
        </React.Fragment>
    );
};

const logoutButton = withRouter(LogoutButton);
export { logoutButton as LogoutButton };
