import {
    IonButtons,
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonList,
    IonItem,
    IonSelect,
    IonSelectOption,
    IonModal,
    IonButton,
    IonLabel,
} from '@ionic/react';
import React, { useState, useEffect } from 'react';
import './AddCompany.scss';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../store';
import { getAllCompanies } from '../../store/reducers/companyReducer';
import { AppDispatch } from '../../store/store';
import { AuthRoles } from 'vision9-solar-shared';

export const AddCompanyDialog: React.FC<{
    isOpen: boolean;
    onClose: (companyId?: number) => void;
}> = ({ isOpen, onClose }: { isOpen: boolean; onClose: (companyId?: number) => void }) => {
    const dispatch = useDispatch<AppDispatch>();

    const allCompanies = useSelector((state: RootState) => state.company.allCompanies);
    const authorized = useSelector(
        (state: RootState) =>
            state.auth.authenticated && state.auth.user && state.auth.user.role === AuthRoles.Admin,
    );
    const userCompanies = useSelector((state: RootState) =>
        state.auth.user
            ? Object.keys(state.auth.user.companies).map(value => parseInt(value))
            : ([] as number[]),
    );

    const [isModalOpen, setModalOpen] = useState(false);
    const [companyId, setCompanyId] = useState(0);

    useEffect(
        () => {
            if (authorized) {
                dispatch(getAllCompanies());
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [authorized],
    );

    useEffect(() => {
        setModalOpen(isOpen);
    }, [isOpen]);

    return (
        <IonModal
            isOpen={isModalOpen}
            onDidDismiss={(): void => {
                if (isModalOpen === true) {
                    setModalOpen(false);
                    onClose();
                }
            }}
        >
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Add Company</IonTitle>
                    <IonLabel
                        className="toolbar-link"
                        slot="end"
                        onClick={(): void => {
                            setModalOpen(false);
                            onClose();
                        }}
                    >
                        Close
                    </IonLabel>
                </IonToolbar>
            </IonHeader>
            <IonContent className="content-dialog">
                <form
                    onSubmit={(e): void => {
                        e.preventDefault();
                        onClose(companyId);
                    }}
                >
                    <IonList>
                        <IonHeader>Please select a company to add.</IonHeader>
                        <IonItem>
                            <IonLabel>Company:</IonLabel>
                            <IonSelect
                                interface="popover"
                                onIonChange={(event): void => {
                                    setCompanyId(event.detail.value);
                                }}
                            >
                                {allCompanies
                                    .filter(c => !userCompanies.includes(c.companyId))
                                    .map(company => {
                                        return (
                                            <IonSelectOption
                                                key={company.companyId}
                                                value={company.companyId}
                                            >
                                                {company.companyName}
                                            </IonSelectOption>
                                        );
                                    })}
                            </IonSelect>
                        </IonItem>
                        <IonButtons>
                            <IonButton fill="solid" type="submit">
                                Add
                            </IonButton>
                            <IonButton fill="solid" type="button" onClick={(): void => onClose()}>
                                Cancel
                            </IonButton>
                        </IonButtons>
                    </IonList>
                </form>
            </IonContent>
        </IonModal>
    );
};
