import { configureStore, Action, AnyAction } from '@reduxjs/toolkit';
import thunk, { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { rootReducer, RootState } from '.';
import { refreshTokenMiddleware } from './middleware/refreshTokenMiddleware';
import { SAVED_STATE } from '../models/Constants';
import { setToken } from './reducers/authReducer';
import { IAuthUser } from 'vision9-solar-shared';
import { crossReducerMiddleware } from './middleware/crossReducerMiddleware';
import { authMiddleware } from './middleware/authMiddleware';

export const store = configureStore({
    reducer: rootReducer,
    middleware: [
        //...getDefaultMiddleware({ serializableCheck: false }),
        thunk,
        crossReducerMiddleware,
        authMiddleware,
        refreshTokenMiddleware,
    ],
});

// initialize if already authenticated
const token = localStorage.getItem(SAVED_STATE.TOKEN);
if (token) {
    const tokens = token.split('|');
    const parts = tokens[0].split('.');
    const user: IAuthUser = {
        ...JSON.parse(Buffer.from(parts[1], 'base64').toString('utf8')),
        token: tokens[0],
        refreshToken: tokens[1],
    };
    store.dispatch(setToken(user));
}

export type AppDispatch = ThunkDispatch<RootState, undefined, AnyAction>;

export type AppThunk = ThunkAction<
    Promise<void | boolean | string>,
    RootState,
    undefined,
    Action<string>
>;
