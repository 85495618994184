/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { MiddlewareAPI, Middleware } from 'redux';
import { AppDispatch } from '../store';
import { RootState } from '..';
import Axios from 'axios';
import { PayloadAction } from '@reduxjs/toolkit';
import { IAuthUser } from 'vision9-solar-shared';
import { getRefreshPromise } from './refreshTokenMiddleware';
import { authenticated } from '../reducers/authReducer';
import { config as clientConfig } from '../../config';

let authInterceptorHandle = 0;

export const authMiddleware: Middleware = (api: MiddlewareAPI<AppDispatch, RootState>) => (
    next: AppDispatch,
) => <A extends PayloadAction>(action: A): void => {
    switch (action.type) {
        case 'AUTH/setToken':
        case 'AUTH/registrationEnd': {
            const user = (action.payload as unknown) as IAuthUser;

            const token = user.token;
            try {
                Axios.interceptors.request.eject(authInterceptorHandle);
                // eslint-disable-next-line no-empty
            } catch {}

            authInterceptorHandle = Axios.interceptors.request.use(
                config => {
                    return new Promise((resolve, reject) => {
                        if (config.url && config.url.toLowerCase().startsWith('/auth/')) {
                            resolve(config);
                        } else if (api.getState().auth.refreshing) {
                            getRefreshPromise().then(
                                response => {
                                    const userResponse = response.data;
                                    if (
                                        userResponse &&
                                        userResponse.user &&
                                        userResponse.user.token
                                    ) {
                                        resolve({
                                            ...config,
                                            headers: {
                                                ...config.headers,
                                                Authorization: `Bearer ${userResponse.user.token}`,
                                            },
                                        });
                                    } else {
                                        reject('Failed after fresh promise.');
                                    }
                                },
                                error => {
                                    console.log(`An error occured refreshing your token: ${error}`);
                                    reject(error);
                                },
                            );
                        } else {
                            resolve({
                                ...config,
                                headers: {
                                    ...config.headers,
                                    Authorization: `Bearer ${token}`,
                                },
                                timeout: clientConfig.requestTimeout,
                            });
                        }
                    });
                },
                error => {
                    console.log('Axios interceptor error: ' + error);
                },
            );

            next(action);

            if (user.exp && (user.exp - 30) * 1000 > Date.now()) {
                api.dispatch(authenticated());
            }

            return;
        }

        default: {
            next(action);
            return;
        }
    }
};
